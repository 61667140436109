define('quoteImportModal',[
    'backbone', 'marionette', 'backboneradio', 'imageHelper', 'staticModels', 'currentCustomer', 'authentication'
],
function(Backbone, Marionette, BackboneRadio, imageHelper, staticModels, CurrentCustomer, Authentication) {
	
    var orderChannel = BackboneRadio.channel('orderChannel');
    var promptChannel = BackboneRadio.channel('promptChannel');
    var quoteChannel = BackboneRadio.channel('quoteChannel');

    var productRanges = new Array();
        
    var ProductsModel = Backbone.Model.extend({
    });
    var ProductsCollection = Backbone.Collection.extend({
        model: ProductsModel
    });
    
    var QuoteImportedModel = Backbone.Model.extend({
        parse: function(data) {
            if (data.resultMap === null) {
                data.resultMap = {
                    discontinuedNoSubstituteProducts: [],
                    invalidProducts: [],
                    activeProducts: []
                };
            }
            
            var errors = [];
            data.resultMap.rowsWithErrorsCount = 0;
            
            if ((data.errorMap !== undefined) && ((data.errorMap !== null))) {
                if (data.errorMap[0] === undefined) {
                    _.each(data.errorMap, function(value, key) {
                        var row = parseInt(key.substring(3));
                        errors.push({row: row, errors: value});
                        data.resultMap.rowsWithErrorsCount++;
                    });
                } else {
                    var reasons = {
                        "INVALID_FILE": "<%=name%> is not an Excel spreadsheet",
                        "EMPTY_FILE": "No valid lines found in spreadsheet <%=name%>",
                    };
                    var template = _.template(reasons[data.errorMap[0]]);
                    data.resultMap.loadError = template({filename: this.filename});
                }
            }
            data.resultMap.errors = errors;
            return data.resultMap;
        },
        load: function(file) {
            this.filename = file.name;
            this.url = CONFIG.rocs_url +  "/quote-service/quote/import/" + CurrentCustomer.code + '/2016-01-01';
            var formData = new FormData();
            formData.append('file', file);
            var options = {
                error: Authentication.checkErrors,
                type: 'POST',
                processData: false,
                contentType: false,
                data : formData
            };
            return Authentication.refreshAndFetch(this, options);
        },
        
        create: function(successCallback) {
            var activeProducts = this.get('activeProducts');
            var productCodes = _.pluck(activeProducts, 'productCode');
            var quantities = _.pluck(activeProducts, 'quantity');
            var orders = _.object(productCodes, quantities);


            Authentication.makeAjaxCall({
                cache : false,
                type : 'POST',
                data: JSON.stringify(orders),
                url : CONFIG.rocs_url + "/quote-service/order/create/products/" + CurrentCustomer.code + '/2016-01-01',
                contentType : 'application/json',
                success : function(orderQuote) {
                    successCallback(orderQuote);
                },
                error : Authentication.checkAjaxErrors
            });
        }
    });

    var SelectFileView = Marionette.ItemView.extend({
        getTemplate: function() {
            if (this._loading) {
                return 'templates/quotes/import/import-quote-loading.hbs';
            } else {
                return 'templates/quotes/import/import-quote-select.hbs';
            }
        },
        events: {
           'change #file-upload-button': 'fileSelected',
           'click #upload-file-button': 'doUpload',
           'click #download-example-file-button': 'downloadExampleFile',
           'click #checkbox' : 'updateProductRanges'
        },
        modelEvents: {
            'sync': 'synced'
        },
        templateHelpers: function() {
            var rangeTypes = staticModels.rangeTypes.toJSON();

            for (let index = 0; index < rangeTypes.length; index++) {
                productRanges.push(rangeTypes[index].id);
            }

            return {
                currencyCode: CurrentCustomer.currency,
                rangeTypes: rangeTypes,
                pricingPeriods: staticModels.pricingPeriods.toJSON()};
        },
        synced: function() {
            this.trigger("uploaded", this.model);
        },
        _readFile: function() {
            return $('#file-upload-button')[0].files[0];
        },
        doUpload: function() {
            var file = this._readFile();
            this.model.load(file);
            this._loading = true;
            this.render();
        },
        updateProductRanges: function(value) {
            if(value.currentTarget.checked === true) {
                productRanges.push(value.currentTarget.value);
            }
            if(value.currentTarget.checked === false) {
                productRanges = productRanges.filter(function(e) { return e !== value.currentTarget.value })
            }
        },
        downloadExampleFile: function() {

            var pricingPeriodId = this.$('input.pricing-period-input:checked').val();
            var self = this;

            var doSuccess = function(result) {

                self.$('#download-example-file-button').addClass('disabled');
                setTimeout(function () {
                    self.$('#download-example-file-button').removeClass('disabled');
                }, 5000);

                var url = CONFIG.rocs_url + "/quote-service/order/form/download";
                var form = $('<form method="POST" action="' + url + '">');

                form.append($('<input type="hidden" name="jwt" value="' + Authentication.getAccessToken() + '">'));
                form.append($('<input type="hidden" name="customerCode" value="' + CurrentCustomer.code + '">'));
                form.append($('<input type="hidden" name="pricingPeriodId" value="' + pricingPeriodId + '">'));
                form.append($('<input type="hidden" name="productRanges" value="' + productRanges + '">'));

                $('body').append(form);
                form.submit();
            };

            var doError = function(jqXHR, textStatus, errorThrown){
                alert("file download error.");
            };

            Authentication.makeAjaxCall({
                cache : false,
                type : 'POST',
                url : CONFIG.rocs_url + "/quote-service/order/form?customerCode=" + CurrentCustomer.code,
                contentType : 'application/json',
                success : doSuccess,
                error : doError
            });

        },
        showSelector: function() {
            this._loading = false;
            this.render();
        },
        fileSelected: function(event) {
            var file = this._readFile();
            this.$('.import_file_error').hide();
            this.model.unset('loadError');
            this.$('#file-search-ram-button').hide();
            this.$('#upload-details').show();
            this.$('#upload-file-details').html(file.name + ' &nbsp; &nbsp; ' + file.size + ' bytes');
        }
    });

    var QuoteView = Marionette.ItemView.extend({
        tagName: 'tbody',
        getTemplate: function() {
            if (this.model.get('title')) {
                return "templates/quotes/import/import-quote-row-section.hbs";
            } else if (this.model.get('replacedRow')) {
                return "templates/quotes/import/import-quote-row-replaced.hbs";
            } else if (this.model.get('errorOnRow') !== undefined) {
                return "templates/quotes/import/import-quote-row-error.hbs";
            } else {
                return "templates/quotes/import/import-quote-row.hbs";
            }
        },
        templateHelpers: function() {
            if (this.model.get('title')) {
                return {};
            }
            var data = {};
            data.canOrder = ! ((this.model.get('discontinued') === 'Y' || this.model.get('discontinued') === 'true') || (this.model.get('bannedCode')) || (this.model.get('excludeFromList') === 'Y'));

            if (this.model.get('promotional')) {
                var price = this.model.get('price');
                var nonPromotionalPrice = this.model.get('nonPromotionalPrice');
                var priceAfterProfileDiscount = this.model.get('priceAfterProfileDiscount');

                if(this.model.get('priceAfterProfileDiscount') === null)  {
                    this.model.set('was', nonPromotionalPrice);
                    data.percentSaving = ((1.0 - (price / nonPromotionalPrice)) * 100.0).toFixed(1);
                    this.model.set('bothDiscounts', false);
                }
                else{
                    this.model.set('was', priceAfterProfileDiscount);
                    data.percentSaving = ((1.0 - (price / priceAfterProfileDiscount)) * 100.0).toFixed(1);
                    this.model.set('bothDiscounts', true);
                }

            }

            return data;
        },
        onRender: function() {
        	this.$el.find('img.product-image').on('error', function(evt) { imageHelper.findAltImage(evt); });
            if(this.model.get('priceAfterProfileDiscount') === null)  {
                    this.model.set('bothDiscounts', false);
                }
                else{
                    this.model.set('bothDiscounts', true);
                }
        },
    });

    var ShowQuotesInTabView = Marionette.CollectionView.extend({
        tagName: 'table',
        childView: QuoteView,
        modelEvents: {
            'reset': 'render'
        }
    });

    var ShowUploadedView = Marionette.LayoutView.extend({
        template: "templates/quotes/import/import-quote-review-modal.hbs",
        templateHelpers: function() {
            var invalidProductsCount = this.model.get('invalidProducts').length + this.model.get('rowsWithErrorsCount');
            var notGoodCount = this.model.get('discontinuedNoSubstituteProducts').length + invalidProductsCount;
            var totalCount = this.model.get('activeProducts').length + notGoodCount;
            return {
                currencyCode: CurrentCustomer.currency,
                notGoodCount: notGoodCount,
                totalCount: totalCount,
                invalidProductsCount: invalidProductsCount
            };
        },
        regions: {
            tabbedQuotes: "#tabbed_quotes"
        },
        events: {
            "click .import-tab": "tabClicked",
            "click #upload_products": "upload"
        },
        onShow: function() {
            var validProducts = this._getProductsForTab('good');
            this.productsCollection = new ProductsCollection();
            this.productsCollection.add(validProducts);
            var showQuotesInTabView = new ShowQuotesInTabView({collection: this.productsCollection});
            this.getRegion('tabbedQuotes').show(showQuotesInTabView);
            if (validProducts.length === 0 ){
                this.$('#upload_products').prop('disabled', true);
                this.$('#upload_products').addClass('disabled');
            }
        },
        tabClicked: function(event) {
            $('#import-tabs .import-tab').removeClass('active_import-tab');
            var tab = $(event.target);
            var tabType = tab.attr('data-tab');
            tab.addClass('active_import-tab');
            
            var products = this._getProductsForTab(tabType);
            this.productsCollection.reset(products);
        },
        
        _getProductsForTab: function(tabType) {
            var mapping = {
                "good": "activeProducts",
                "discontinued": "discontinuedNoSubstituteProducts",
                "not_recognised": "invalidProducts"
            };
            var products = this.model.get(mapping[tabType]);           

            var fullProducts = [];
            if (tabType === 'not_recognised') {
                var errorMap = {
                    "900": "product code",
                    "901": "product description",
                    "902": "product quantity"
                };
                fullProducts = _.clone(products);
                if (this.model.get('errors').length > 0) {
                    fullProducts.push({title: 'File format errors'});
                }
                _.each(this.model.get('errors'), function(errorRow) {
                    var msgs = _.map(errorRow.errors, function(code) {return errorMap[code];} ).join(', ');
                    fullProducts.push({
                        errorOnRow: 'Error on row ' + errorRow.row,
                        msg: "Invalid " + msgs
                    });
                });
            }  else {
                var groupedProducts = _.groupBy(products, 'brand'); 
                var ranges = _.keys(groupedProducts);
                ranges.sort();
                _.each(ranges, function(range) {
                    fullProducts.push({title: range + ' products'});
                    fullProducts = fullProducts.concat(groupedProducts[range]);
                });
            }
            return fullProducts;
        },
        upload: function() {
            var ConfirmationModel = Backbone.Model.extend({
            });
            var success = function(orderQuote) {
                var confirmationModel = new ConfirmationModel({
                        totalCases: orderQuote.totalCases, 
                        totalPrice: orderQuote.totalPrice, 
                        nQuotes: orderQuote.quoteIds.length,
                        shipmentId: orderQuote.shipmentId,
                        quotes: orderQuote.quoteIds});
                promptChannel.trigger('show', {template: "templates/quotes/import/import-quote-confirm-modal.hbs", model: confirmationModel});
                quoteChannel.trigger("add:shipment", orderQuote);
            };
            this.trigger("creating:order");
            this.model.create(success);
        }
    });

    
    var QuoteImportModal = Marionette.LayoutView.extend({
        className: "modal fade",
        attributes: {
            "data-backdrop": "static"
        },
        regions: {
            content: '#import-modal'
        },       
        template: 'templates/quotes/import/import-quote-modal.hbs',
        onShow: function() {
            var self = this;
            var quoteImportedModel = new QuoteImportedModel();
            var selectFileView = new SelectFileView({model: quoteImportedModel});
            this.getRegion('content').show(selectFileView);
            
            this.listenTo(selectFileView, 'uploaded', function(model) {
                if (model.get('loadError')) {
                    selectFileView.showSelector();
                } else {
                    var showUploadedView = new ShowUploadedView({model: model});
                    self.getRegion('content').show(showUploadedView);
                    self.listenTo(showUploadedView, "creating:order", function() {
                        self.$el.modal('hide');
                    });
                }
            });
        }
    });

    return {
        create: function() {
            var quoteImportModal = new QuoteImportModal();
            return quoteImportModal;
        }
    };
});

