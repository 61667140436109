define('productSearchFilterCategory',[
    'backbone', 'marionette', 'backboneradio', 'productSearchStaticFilterModel'
],
function(Backbone, Marionette, BackboneRadio, productSearchStaticFilterModel) {
    
    var SearchCategoryModel = Backbone.Model.extend({
        initialize: function() {
            var properties = {
                mainGroups: productSearchStaticFilterModel.get('mainGroups'),
                rangeToMainGroupMap: productSearchStaticFilterModel.get('rangeToMainGroupMap'),
                mainGroupToSubGroupMap: productSearchStaticFilterModel.get('mainGroupToSubGroupMap'),
                subGroupToSubSubGroupMap: productSearchStaticFilterModel.get('subGroupToSubSubGroupMap'),
                subCategories: [],
                subSubCategories: [],
                selectedCategory: "-1",
                selectedSubCategory: "-1",
                selectedSubSubCategory: "-1"
            };
            this.set(properties);
        },

        selectCategory: function(categorySelected) {
            var group = _.find(this.get('mainGroups'),function(g) {
                return g.groupId === categorySelected
            });
            if (group === undefined) {
                console.log('ERROR: category ' + categorySelected + ' does not exist');
                return null;
            }

            var toSet = {
                'selectedCategory': categorySelected,
                'selectedCategoryDescription': group.groupDescription,
                'selectedSubCategory' : '-1',
                'selectedSubSubCategory': '-1'
            };
            if (categorySelected === "-1") {
                toSet.subCategories = [];
            } else{
                toSet.subCategories = this.get("mainGroupToSubGroupMap")[categorySelected];
            }
            toSet.subSubCategories = [];
            this.set(toSet);
            return {id: categorySelected, text: group.groupDescription};
        },

        selectSubCategory: function(categorySelected, subCategorySelected) {
            var subGroup = _.find(this.get('mainGroupToSubGroupMap')[categorySelected],function(g) {
                return g.groupId === subCategorySelected
            });
            if (subGroup === undefined) {
                console.log('ERROR: sub category ' + subCategorySelected + ' does not exist');
                return null;
            }

            var toSet = {
                'selectedSubCategory': subCategorySelected,
                'selectedSubCategoryDescription': subGroup.groupDescription,
                'selectedSubSubCategory': '-1'
            };
            if (subCategorySelected === "-1") {
                toSet.subSubCategories = [];
            } else {
                toSet.subSubCategories = this.get("subGroupToSubSubGroupMap")[categorySelected + "|" + subCategorySelected];
            }
            this.set(toSet);
            return {id: subCategorySelected, text: subGroup.groupDescription};
        },

        selectSubSubCategory: function(categorySelected, subCategorySelected, subSubCategorySelected) {
            var groupId = categorySelected + "|" + subCategorySelected;
            var subSubGroup = _.find(this.get('subGroupToSubSubGroupMap')[groupId],function(g) {
                return g.groupId ===  subSubCategorySelected
            });
            if (subSubGroup === undefined) {
                console.log('ERROR: sub sub category ' + subSubCategorySelected + ' does not exist');
                return null;
            }

            var toSet = {
                'selectedSubSubCategory': subSubCategorySelected,
                'selectedSubSubCategoryDescription': subSubGroup.groupDescription
            };
            this.set(toSet);
            return {id: subSubCategorySelected, text: subSubGroup.groupDescription};
        }
    });

    var SearchCategoryView = Marionette.ItemView.extend({
        template: "templates/products/search/sub-view/filter-category.hbs",
        events: {
            'change #search_filter_category': 'categoryFilterChanged' ,
            'change #search_filter_sub-category': 'subCategoryFilterChanged',
            'change #search_filter_sub-sub-category': 'subSubCategoryFilterChanged'
        },
        templateHelpers: function() {
            var obj = {};
            var mainGroups = this.model.get("mainGroups");
            var subCategories = this.model.get("subCategories");
            var subSubCategories = this.model.get("subSubCategories");
            obj.categoryList = _.clone(mainGroups);

            obj.categoryList.unshift({groupDescription: 'Select a Category..', groupId: -1});
            obj.subCategoryList = _.clone(subCategories);
            obj.subSubCategoryList = _.clone(subSubCategories);
            if (this.model.get('selectedCategory') !== '-1') {
                obj.subCategoryList.unshift({groupDescription: 'Select a SubCategory..', groupId: -1});
            }
            if(this.model.get('selectedSubCategory') !== "-1"){
                obj.subSubCategoryList.unshift({groupDescription: 'Select a Sub-SubCategory..', groupId: -1});
            }
            return obj;
        },
        categoryFilterChanged: function() {
            var selected = $('#search_filter_category').val();
            var details = this.model.selectCategory(selected);

            this.render();
            this.trigger("category:changed", details);
        },
        subCategoryFilterChanged: function() {
        	var categorySelected = $('#search_filter_category').val();
            var subCategorySelected = $('#search_filter_sub-category').val();
            var details = this.model.selectSubCategory(categorySelected, subCategorySelected);

            this.render();
            this.trigger("sub:category:changed", details);
        },
        subSubCategoryFilterChanged : function() {
            var categorySelected = $('#search_filter_category').val();
            var subCategorySelected = $('#search_filter_sub-category').val();
            var subSubCategorySelected = $('#search_filter_sub-sub-category').val();

            var details = this.model.selectSubSubCategory(categorySelected, subCategorySelected, subSubCategorySelected);

            this.render();
            this.trigger("sub:sub:category:changed", details);
        },
        reset: function(typeOfCategoryThatHasBeenRemoved) {
        	
        	var toSet = {
        		'selectedSubSubCategory': '-1'
        	};
        	if (typeOfCategoryThatHasBeenRemoved === 'category') {
        		toSet.selectedCategory = '-1';
        		toSet.selectedSubCategory = '-1';
        		toSet.subCategories = [];
        		toSet.subSubCategories = [];
        	} else if (typeOfCategoryThatHasBeenRemoved === 'sub-category') {
        		toSet.selectedSubCategory = '-1';
        		toSet.subSubCategories = [];
        	}
		   	this.model.set(toSet);
            this.render();
        },
        getCategoryId: function() {
            return this.model.get('selectedCategory');
        },
        getCategory: function() {
            var category = this.model.get('selectedCategory');
            if (category === "-1") {
                return undefined;
            }

            return this.model.get('selectedCategoryDescription');
        },
        getSubCategoryId: function() {
            return this.model.get('selectedSubCategory');
        },
        getSubCategory: function() {
            var subCategory = this.model.get('selectedSubCategory');
            if (subCategory === "-1") {
                return undefined;
            }
            return this.model.get('selectedSubCategoryDescription');
        },
        getSubSubCategoryId: function() {
            return this.model.get('selectedSubSubCategory');
        },
        getSubSubCategory: function() {
            var subSubCategory = this.model.get('selectedSubSubCategory');
            if (subSubCategory === "-1") {
                return undefined;
            }
            return this.model.get('selectedSubSubCategoryDescription');
        }
    });
    return {
        SearchCategoryModel: SearchCategoryModel,
        SearchCategoryView: SearchCategoryView
    };
});

