define('menuView',[
    'backbone', 'marionette', 'backboneradio', 'productHeaderView', 'authentication', 'quoteImportModal'
],
function(Backbone, Marionette, BackboneRadio, ProductHeaderView, Authentication, QuoteImportModal) {
    
    var quoteChannel = BackboneRadio.channel('quoteChannel');
    var productChannel = BackboneRadio.channel('productChannel');
    var customerChannel  = BackboneRadio.channel('customerChannel');
    var promptChannel  = BackboneRadio.channel('promptChannel');
    var orderChannel  = BackboneRadio.channel('orderChannel');

    var MenuItemModel = Backbone.Model.extend({
    });

    var MenuCollection = Backbone.Collection.extend({
        model: MenuItemModel
    });

    var actions = {
        'createOrder': function() {
            quoteChannel.trigger("quote:createquote");
        },
        'myShipments': function() {
            Backbone.history.navigate('');
            productChannel.trigger("remove:filters");
            customerChannel.trigger("customer:displayShipments");
        },
        'searchProducts': function() {
            productChannel.trigger("remove:filters");
            productChannel.trigger("product:displaysearch", {});
            // productChannel.trigger("remove:filters");
        },
        'orderHistory': function() {
            orderChannel.trigger("order:showhistory")
        },
        'showMetrics': function() {
            customerChannel.trigger("customer:showmetrics");
        },
        'exportProducts': function(view) {
            view.trigger('export:products');
        },
        'importProducts': function() {
            promptChannel.trigger('show:modal', QuoteImportModal.create());
        }
    };

    var MenuItemView  = Marionette.ItemView.extend({
        className: function() {
            return "menu-item-wrapper menu-item_" + this.model.get('action');
        },
        modelEvents: {
            'change:disabled': function() {
                this.render(); 
            }
        },
        templateHelpers: function() {
            var classes = '';
            if (this.model.get('disabled')) {
                classes += ' menu-item-disabled';
            }
            if (this.model.get('selected')) {
                classes += ' menu-item-selected';
            }
            return {
               classes: classes
            };
        },
        template: "templates/navbar/menu-item.hbs",
        events: {
            'click': 'clicked'
        },
        clicked: function(event) {
            if (! this.model.get('selected')) {
                if (this.model.get('disabled') !== true) {
                    var action = this.model.get('action');
                    actions[action](this);
                }
            }
        }
    });
    
    var MenuItemsView  = Marionette.CollectionView.extend({
        childView: MenuItemView,
        _modelsMap: {},
        totalHits: 0,
        initialize: function() {
            this.listenTo(productChannel, "searchcomplete", this.searchComplete);
        },
        searchComplete: function(options) {
            if (options) {
                this.searchString = options.searchString;
                if (this._modelsMap.exportProducts) {
                    this._modelsMap.exportProducts.set('disabled', options.numOfResults === 0);
                    this.filters = options.filters;
                    this.totalHits = options.totalHits;
                }
            }
        },
        
        onChildviewExportProducts: function(view) {
            var self = this;
            var doSuccess = function(result) {
                var exportURL = CONFIG.rocs_url + "/product-service/product/export/download";
                var form = $('<form method="POST" action="' + exportURL + '">');
                    form.append($('<input type="hidden" name="jwt" value="' + result + '">'));
                $('body').append(form);
                    form.submit();
            };

            var doError = function(jqXHR, textStatus, errorThrown){
                alert("product export error.");
            };

            Authentication.makeAjaxCall({
                 cache : false,
                 type : 'POST',
                 url : CONFIG.rocs_url + "/product-service/product/export/v2" + "?totalHits=" + self.totalHits,
                 contentType : 'application/json',
                 data : JSON.stringify(self.filters),
                 success : doSuccess,
                 error : doError
            });
        }
    });
    
    
    var NotificationSummaryModel = Backbone.Model.extend({
    });
    
    var NotificationSummaryView  = Marionette.ItemView.extend({
        template: "templates/navbar/notification.hbs",
        events: {
            'click .notifications-link': 'notificationToggle'
        },
        initialize: function() {
            var self = this;
            this.listenTo(quoteChannel, 'notification:count', function(count) {
                self.model.set('notificationCount', count);
                self.render();
                setTimeout(function() {
                    self.notificationToggle();
                }, 10);
            });
        },
        notificationToggle: function() {
            var icon = this.$('.notifications-link .glyphicon');
            var isClosed = icon.hasClass('glyphicon-menu-down');
            // Toggle
            if (isClosed) {
                icon.removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
                quoteChannel.trigger('show:notifications');
            } else {
                icon.removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
                quoteChannel.trigger('hide:notifications');
            }
        }
    });


    
    var MenuView = Marionette.LayoutView.extend({
        template: "templates/navbar/menu-bar.hbs",
        regions: {
            'menuBar' : '#menu-bar-panel',
            'rightMenuBar' : '#right-menu-bar-panel'
        },

        onShow: function() {
            var menuCollection = new MenuCollection();
            var models = [];
            
            var menus = [
                {name: 'Home', icon: 'glyphicon-plane', action: 'myShipments'},
               {name: 'Browse product range', icon: 'glyphicon-search', action: 'searchProducts'},
                {name: 'Create new shipment', icon: 'glyphicon-duplicate', action: 'createOrder'}
            ];
            if (Authentication.isAdminOrCSA()){
                menus.push({name: 'Show activities', icon: 'glyphicon glyphicon-cog', action: 'showMetrics'});
            }
            if (this.options.selectedMenu === 'searchProducts' && (Authentication.isAdminOrCSA() || Authentication.hasExportPermission())) {
                menus.push({name: 'Export', icon: 'glyphicon-export', action: 'exportProducts', disabled: true});
            }
            if (this.options.selectedMenu === 'myShipments' && (Authentication.isAdminOrCSA() || Authentication.hasExportPermission())) {
                menus.push({name: 'Import', icon: 'glyphicon-import', action: 'importProducts'});
            }
            menus.push({name: 'Order History', icon: 'glyphicon-search', action: 'orderHistory'});
            
            var selectedMenu = this.options.selectedMenu;
            var modelsMap = {};
            $.each(menus, function() {
                var data = _.clone(this);
                data.selected = data.action === selectedMenu;
                var model = new MenuItemModel(data);
                modelsMap[data.action] = model;
                models.push(model);
            });
            
            if (this.options.selectedMenu === 'myShipments') {
                var notificationSummaryModel = new NotificationSummaryModel();
                this.notificationSummaryView = new NotificationSummaryView({model: notificationSummaryModel});
                this.getRegion('rightMenuBar').show(this.notificationSummaryView);
            }
            
            menuCollection.reset(models);
            
            var menuItemsView = new MenuItemsView({collection: menuCollection});
            menuItemsView._modelsMap = modelsMap;
            this.getRegion('menuBar').show(menuItemsView);
        }
    });
    
    return MenuView;
});
