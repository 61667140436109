define('basket',['backbone', 'marionette'],
    function (Backbone, Marionette) {
        var Basket = Marionette.ItemView.extend({
            template: "templates/basket/basket.hbs",
            cidPrefix: 'BasketView_',
            className: function () {
                var classes = "basket-tab";
                if (this.model.get('percent') === 100) {
                    classes += ' basket-submitted';
                }
                return classes;
            },
            events: {
                'click .basket-info': 'infoClicked',
                'click': 'clicked',
                'click .basket-edit': 'basketEdit',

            },

            templateHelpers: function () {
                var quoteStatus = this.model.get('quoteStatus');
                var orderStatus = this.model.get('orderStatus');
                var creditNote = this.model.get('creditNote');
                return {
                    isOpen: quoteStatus === 'OPEN' || quoteStatus === 'REOPENED',
                    isExpired: quoteStatus === 'EXPIRED',
                    percent: Math.floor(this.getPercent() * 100),
                    isSubmitted: quoteStatus === 'SUBMITTED',
                    isReadyForConfirmation: quoteStatus === 'READY_FOR_CONFIRMATION',
                    isConfirmed: quoteStatus === 'CONFIRMED',
                    isOrderNotInvoiced: orderStatus === 'NOT_INVOICED',
                    isOrderInvoiced: orderStatus === 'INVOICED' && !creditNote,
                    isCreditNote: creditNote
                };
            },

            onRender: function () {
                var percent = this.getPercent();
                var offset = -270 * (1 - percent);
                this.$el.css('background-position', offset + 'px 0px');

                if (this.model.get('selected')) {
                    this.$el.addClass('basket-selected');
                } else {
                    this.$el.removeClass('basket-selected');
                }
            },

            getPercent: function () {
                var minimumOrderQuantity = this.model.get('minimumOrderQuantity');
                var basket = this.model.get('basket');
                var percent = 0;
                if (minimumOrderQuantity) {
                    if (minimumOrderQuantity.minimumQuantity) {
                        percent = basket.basketTotalCases / minimumOrderQuantity.minimumQuantity;
                    } else if (minimumOrderQuantity.minimumValue) {
                        percent = basket.basketTotalPrice / minimumOrderQuantity.minimumValue;
                    }
                }
                // Limit to
                if (percent > 1.0) {
                    percent = 1.0;
                }
                return percent;
            },
            clicked: function () {
                if (!this.model.get('selected')) {
                    this.trigger('basket:selected');
                    this.render();
                }
                return false;
            },
            infoClicked: function () {
                this.trigger('basket:details:modal');
                return false;
            },
            basketEdit: function () {
                this.trigger('basket:edit');
                return false;
            }
        });
        return Basket;
    });
