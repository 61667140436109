define('scrollBehaviour',[
        'backbone', 'marionette', 'backboneradio'
    ],
    function (Backbone, Marionette, BackboneRadio) {

        var ScrollBehaviour = Backbone.Marionette.Behavior.extend({
            scrollPosition: 0,
            defaults: {
                channel: BackboneRadio.channel('productChannel')
            },

            onAttach: function () {
                var channel = this.getOption('channel');
                var self = this;
                /* When scrolled near end of results, trigger search. */
                this.view.$('.scroll_and_load').on('scroll', function (e) {
                    var leftToScroll = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight;
                    self.scrollPosition = e.currentTarget.scrollTop;
                    if (self._isLoading !== true) {
                        if (leftToScroll < 800 && e.currentTarget.scrollTop !== 0) {
                            if (self._pagination.numOfResults < self._pagination.totalHits - 1) {
                                self._isLoading = true;
                                channel.trigger("nextpage");
                            }
                        }
                    }
                });


                this.listenTo(channel, "searchcomplete", function (options) {
                    self._isLoading = false;
                    self._pagination = options;
                    self.view.$('.scroll_and_load').scrollTop(self.scrollPosition);
                });
            },

            onDestroy: function () {
                this.view.$('.scroll_and_load').off('scroll');
            }
        });

        return ScrollBehaviour;

    });

