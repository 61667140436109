define('currentCustomer',[
    'backbone', 'marionette', 'authentication'
],
function(Backbone, Marionette, Authentication) {

    var currencies = {
        'GBP': '&pound;',
        'USD': '$',
        'EUR': '&euro;'
    };

	var CurrentCustomer = {
            name: "no customer selected",
            code: "-1",
            currency: "GBP",
            currencySymbol: '&pound;',
            updatingQuotes: false,
            customer: {},

            load: function(customerCode) {
                this.code = customerCode;
                var self = this;
                var promise = Authentication.createPromise();
                Authentication.makeAjaxCall({
                    cache: false,
                    type: 'GET',
                    url: CONFIG.rocs_url + "/customer-service/customer/get/" + customerCode,
                    contentType: 'application/json'
                }).success(function(result) {
                    CurrentCustomer.attributes = result;
                    self._loaded(result);
                    promise.setSuccess(result);
                }).error(function(result) {
                    Authentication.checkAjaxErrors(error);
                    promise.setError(result);
                });

                return promise.promise;
            },

            _loaded: function (result) {
                if (result.code && result.name) {
                    this.name = result.name;
                    this.code = result.code;
                    this.currency = result.currency;
                    this.territory = result.territory;
                    var currencyLookup = currencies[this.currency];
                    if (currencyLookup !== undefined) {
                        this.currencySymbol = currencyLookup;
                    } else {
                        this.currencySymbol = '?';
                    }
                } else {
                    alert("Authorisation error, account set up not complete. Please contact your EAA to correct.\n\n(Error Code EM1)");
                    Authentication.removeAccessToken();
                    Authentication.logout();
                }
            }
	};

	return CurrentCustomer;
	
});
