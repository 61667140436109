define('basketModel',[
    'backbone', 'marionette',  'authentication'
],
function(Backbone, Marionette,  Authentication) {
   
    var BasketModel = Backbone.Model.extend({
        cidPrefix: 'BasketModel_',
        load:  function(shipmentId) {
            this.url = CONFIG.rocs_url + '/quote-service/order/get/' + shipmentId;
            var options = {
                cache : false,
            };
            Authentication.refreshAndFetch(this, options);
        },

        parse: function(data) {
            data.quotes.sort(function(a, b) {
                return a.quoteId.localeCompare(b.quoteId);
            });
            return data;
        },
        
        changeQuantity: function(op, url) {
            return Authentication.makeAjaxCall({
                cache : false,
                type : op,
                url : CONFIG.rocs_url +  url,
                data : {}
            });
        },

        setProduct: function(quoteId, productCode, quantity) {
            var url = "/quote-service/quote/set/" + quoteId + "/" + productCode +"/"  + quantity;
            return this.changeQuantity('POST', url);
        },
        deleteProduct: function(quoteId, productCode) {
            var url = "/quote-service/quote/delete/" + quoteId + "/" + productCode;
            return this.changeQuantity('DELETE', url);
        }
    });
    return BasketModel;
});


