define('routing',[
        'backbone', 'marionette', 'backboneradio'
    ],
    function(Backbone, Marionette, BackboneRadio) {

        var productChannel = BackboneRadio.channel('productChannel');
        var customerChannel = BackboneRadio.channel('customerChannel');
        var urlChannel = BackboneRadio.channel('urlChannel');

        var specifiedValue = function(cat) {
            return (cat !== undefined) && (cat !== '-1');
        };

        var RocsController = Marionette.Controller.extend({
            defaultRoute: function() {
                customerChannel.trigger("customer:displayShipments");
            },
            categorySearch: function(category, subCategory, subSubCategory){
                var params = (new URL(document.location)).searchParams;
                var filters = {
                    category: category,
                    subCategory: subCategory,
                    subSubCategory: subSubCategory,
                    range: params.get("range") ? params.get("range").split(',') : [],
                    search: params.get("search") ? decodeURIComponent(params.get("search")): '',
                    brands: params.get("brands") ? params.get("brands").split(',').map(decodeURIComponent): [],
                    tier1Filters: params.get("productFilters") ? params.get("productFilters").split(',') : [],
                    tier1attributeFilters: params.get("attributeFilters") ? params.get("attributeFilters").split(',') : [],
                };
                productChannel.trigger("product:displaysearch", filters);
                // Remove for normal history navigation
                Backbone.history.navigate('', {replace: true})
            },
            initialize: function() {
                /* Uncomment to enable History API.
                // Update the URL, allowing bookmarking and history navigation.
                this.listenTo(urlChannel, "search", function(filters) {
                    var categories = (filters.selectedCategoryId !== null)
                            ?[filters.selectedCategoryId, filters.selectedSubCategoryId ,
                                filters.selectedSubSubCategoryId]
                            : [];
                    var path = ['browse'].concat(categories.filter(specifiedValue)).join('/');

                    var queries = [];
                    if (filters.ranges.length > 0) {
                        queries.push('range=' + filters.ranges.join(','));
                    }
                    if (filters.searchText.length > 0) {
                        queries.push('search=' + encodeURIComponent(filters.searchText));
                    }

                    if (filters.brands.length > 0) {
                        queries.push('brands=' + filters.brands.map(function(brand) {
                                return encodeURIComponent(brand);
                            }).join(','));
                    }

                    if (queries.length > 0) {
                        path = path + '?' + queries.join('&');
                    }

                    Backbone.history.navigate(path);
                }); */
            }
        });

        var Router = Marionette.AppRouter.extend({
            controller: new RocsController(),
            appRoutes: {
                "browse(/:category)(/:subCategory)(/:subSubCategory)": "categorySearch",
                "": "defaultRoute"
            }
        });
        return Router;
    });
