define('quoteDetailsView',[
    'backbone', 'marionette'
],
function(Backbone, Marionette) {

	var QuoteDetailsView = Marionette.ItemView.extend({
		template: 'templates/quotes/quote-details-view.hbs'
	});
	
	return QuoteDetailsView;
});
