define('basketOverView',[ 'marionette', 'backboneradio'],
function(Marionette, BackboneRadio) {
    
    var BasketOverView = Marionette.ItemView.extend({
         template: "templates/basket/basket-over-view.hbs",
         events: {
             "mouseenter #ttPrice" : "showPopover",
             "mouseleave #ttPrice" : "hidePopover"
         },

        templateHelpers: function() {
            var data = {};
            data.isQuote = this.model.get('orderId') == null;

            return data;
        },
         showPopover: function(){
             $("#ttPrice").popover("show");
         },
         hidePopover: function(){
             $("#ttPrice").popover("hide");
         }
    });
    
    return BasketOverView;
});

