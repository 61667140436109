define('customerListView',[
    'backbone', 'marionette', 'backboneradio', 'currentCustomer', 'authentication' 
],
function(Backbone, Marionette, BackboneRadio, CurrentCustomer, Authentication) {
	
    var customerChannel = BackboneRadio.channel('customerChannel');
    
	var Customer = Backbone.Model.extend({
	});

	var CustomerList = Backbone.Collection.extend({
		model: Customer
	});
	
	var customerCollection = new CustomerList();
	
        var currencies = {
            'GBP': '&pound;',
            'USD': '$',
            'EUR': '&euro;'
        };
    
	var CustomerItemView = Marionette.ItemView.extend({
		
		tagName: "tr",
		className: "mouse-hand",
		template: "templates/customers/list/customer-list-item.hbs",
		events: {
                    "click": "customerSelected"
		},

		customerSelected: function() {
			CurrentCustomer.attributes = this.model.attributes
			CurrentCustomer.name = this.model.escape("name");
	        CurrentCustomer.code = this.model.escape("code");
	        CurrentCustomer.currency = this.model.escape("currency");
			CurrentCustomer.territory = this.model.escape("territory");
			CurrentCustomer.ambientProfile = this.model.escape("ambientProfile");
	        var currencyLookup = currencies[CurrentCustomer.currency];
	        if (currencyLookup!== undefined) {
	            CurrentCustomer.currencySymbol = currencyLookup;
	        }  else {
	            CurrentCustomer.currencySymbol = '?';
			}
            customerChannel.trigger("customer:selected");
	        customerChannel.trigger("customer:displayShipments");
	        $('#customer-search-modal').modal('hide');
	        $('#loading-data-modal').modal('show');
		}
	});
	
	var EmptyCollectionView = Marionette.ItemView.extend({
		tagName: "tr",
		template: "templates/customers/list/customer-list-empty.hbs",
	});
	
	var CustomerListView = Marionette.CompositeView.extend({
        searched: false,
        initialize: function() {
            this.listenTo(customerChannel, "customer:search", this.loadCustomers);
        },
		collection: customerCollection,
		tagName: "table",
		className: "table table-hover",
		template: "templates/customers/list/customer-list.hbs",
		childView: CustomerItemView,
		childViewContainer: "tbody",
		emptyView: EmptyCollectionView,
		        
            isEmpty: function() {
                return this.searched;
            },

            loadCustomers: function(searchString) {
                this.collection.url = CONFIG.rocs_url + "/customer-service/customer/search?searchString=" + searchString;
                this.searched = true;
                Authentication.refreshAndFetch(this.collection, {
                    cache : false,
                    error: Authentication.checkErrors
                });
            }

	});
	
	return CustomerListView;
	
});

