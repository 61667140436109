define('basketView',[
    'backbone', 'marionette', 'backboneradio', 'basketContentsView', 'basketModel', 'basketOverView', 'authentication', 
    'quoteSubHeaderView', 'format', 'deleteProductModal', 'quoteRepriceModal', 'quoteReopenModal', 'currentCustomer', 'staticModels'
],
function(Backbone, Marionette, BackboneRadio, BasketContentsView, BasketModel, BasketOverView, Authentication,
        QuoteSubHeaderView, format, DeleteProductModal, QuoteRepriceModal, QuoteReopenModal, CurrentCustomer, StaticModels) {

    var basketChannel = BackboneRadio.channel('basketChannel');
    var quoteChannel = BackboneRadio.channel('quoteChannel');
    var alertChannel = BackboneRadio.channel('alertChannel');
    var customerChannel = BackboneRadio.channel('customerChannel');
    var headerChannel = BackboneRadio.channel('headerChannel');
    var promptChannel = BackboneRadio.channel('promptChannel');
    var orderChannel = BackboneRadio.channel('orderChannel');
    var productChannel = BackboneRadio.channel('productChannel');
    
    var BasketHeaderView = Marionette.ItemView.extend({     
        template: 'templates/basket/basket-header-view.hbs',
        cidPrefix: 'BasketHeaderView_',
        events: {
            'click #basket_reprice-quote': 'repriceQuote',
          'click #basket_add-more'      : 'addMoreProducts',
          'click #basket_request-quote' : 'requestQuote',
            'click #basket_reopen-quote': 'reopenQuote',
            'click #basket_confirm-quote' : 'confirmQuote'
        },

        repriceQuote: function() {
            quoteChannel.trigger('quote:reprice');
        },

        reopenQuote: function() {
            quoteChannel.trigger('quote:reopen');
        },
        
        requestQuote: function() {
        	$('#basket_request-quote').prop('disabled', true);
        	function checkQuoteUpdatingIsComplete() {
       		    if(CurrentCustomer.updatingQuotes) {
       		        window.setTimeout(checkQuoteUpdatingIsComplete, 100); 
       		    } else {
       		    	$('#basket_request-quote').prop('disabled', false);
       		    	quoteChannel.trigger('quote:requestquote');
       		    }
       		};
       		checkQuoteUpdatingIsComplete();
        },
        confirmQuote: function() {
            quoteChannel.trigger('quote:confirm');
        },
       
        addMoreProducts: function(event) {
        	$('#basket_add-more').prop('disabled', true);
        	function checkQuoteUpdatingIsComplete() {
       		    if(CurrentCustomer.updatingQuotes) {
       		        window.setTimeout(checkQuoteUpdatingIsComplete, 100); 
       		    } else {
                    $('#basket_add-more').prop('disabled', true);
                    productChannel.trigger("remove:filters");
       		    	quoteChannel.trigger('quote:addproducts');
       		    }
       		};
       		checkQuoteUpdatingIsComplete();
        },

        templateHelpers: function() {
            var data = {};
            var quotes = this.model.get('quotes');
            var orders = this.model.get('orders');
            if (quotes) {
                data.noOfBaskets = quotes.length - orders.length;
                data.noOfOrders = orders.length;
            }
            return data;
        },

        showButtons: function(basketIndex) {
            var quotes = this.model.get('quotes');
            var totalCases = this.model.get('totalCases');
            var hasOpenWithCases = false;
            if (totalCases > 0) {
                $.each(quotes, function(index, quote){
                    if (quote.quoteStatus === 'OPEN' || quote.quoteStatus === 'REOPENED'){
                        if (quote.basket.basketTotalCases > 0){
                            hasOpenWithCases = true;
                            return false;
                        }
                    }
                });
            }

            this.$('#basket_request-quote').prop('disabled', ! hasOpenWithCases);
            var quote = quotes[basketIndex];
            
            var isReadyForConfirmation = (quote.quoteStatus === 'READY_FOR_CONFIRMATION');
            var isOpen = (quote.quoteStatus === 'OPEN' || quote.quoteStatus === 'REOPENED');
            var isExpired = (quote.quoteStatus === 'EXPIRED');

            this.showHide('#basket_reprice-quote', isExpired);
            this.showHide('#basket_add-more', isOpen);
            this.showHide('#basket_confirm-quote', isReadyForConfirmation);
            this.showHide('#basket_reopen-quote', isReadyForConfirmation);
            this.showHide('#basket_request-quote', isOpen && (quote.basket.basketTotalCases > 0));
        },
        
        showHide: function(selector, show) {
            if (show) {
                $(selector).show();
            } else {
                $(selector).hide();
            }
        },
        
        showValues: function(values, basketIndex) { 
            this.model.set(values);
            this.render();
            $('#basket_details').show();
            this.showButtons(basketIndex);
        }
    });

    
    var BasketLayoutView = Marionette.LayoutView.extend({
        modelEvents: {
            "sync": "modelLoaded"
        },
        cidPrefix: 'BasketLayoutView_',
        initialize: function(options){
            if (options !== undefined) {
                this.basketIndex = options.quoteIndex;
                this.quoteId = options.quoteId;

                if(options.orderId) {
                    this.basketIndex = options.orderIndex;
                    this.quoteId = options.orderId;
                }
            }
            this.listenTo(quoteChannel, 'quote:addproducts', this.displayQuote);
            this.listenTo(quoteChannel, 'quote:requestquote', this._askToRequest);
            this.listenTo(quoteChannel, 'quote:confirm', this._askToConfirm);
            this.listenTo(quoteChannel, 'quote:reprice', this._repriceQuote);
            this.listenTo(quoteChannel, 'quote:reopen', this._reopenQuote);
        },
        
        displayQuote: function() {
            quoteChannel.trigger('quote:view:quote', this.model.clone(), this.model.get('quotes')[this.basketIndex].quoteId, this.basketIndex);  
        },
        className: 'container-height',
        template: "templates/basket/basket-layout-view.hbs",
        
        regions: {
           basketOverview: "#basket_overview_region",
           basketContents: "#basket_contents_region"
        },
        
        _updateProductList: true,
        
        modelLoaded: function() {
            var quotes = this.model.get('quotes');
            /*
             * Use the basketIndex that was passed into this view, if it wasn't specified
             * then use the quoteId to discover it, if this wasn't specified then default to basketIndex of 0.
             */
            if (this.basketIndex === undefined) {
                if (this.quoteId === undefined) {
                    this.basketIndex = 0;
                } else {
                    for(this.basketIndex = 0; this.basketIndex < quotes.length; this.basketIndex++) {
                        if (quotes[this.basketIndex].quoteId === this.quoteId) {
                            break;
                        }
                    }
                }
            }

            this._updateHeaders(); 
            this._showBasket();
        },
        
        _showBasket: function() {
            var i;
            var quote = this.model.get('quotes')[this.basketIndex];
            StaticModels.selectedQuote = quote;
            var productLines = quote.basket.productLines;
            
            var basketModels = [];
            var restrictedBasketModels = [];
            for(i in productLines) {
                var disabled = "";
                var visible = 'display: inline';
                var notActive = "";
                if (quote.quoteStatus === 'SUBMITTED' || quote.quoteStatus === 'READY_FOR_CONFIRMATION' || quote.quoteStatus === 'CONFIRMED' || quote.quoteStatus === 'EXPIRED' ||
                    quote.orderId != null){
                    disabled = 'disabled';
                    visible = 'display: none';
                    notActive = 'disabled not_active';
                }

                var basketModel = _.pick(productLines[i],
                    'innerBarcode', 'longDescription', 'productCode', 'price', 'caseSize', 'totalPrice',
                    'disabled', 'visible', 'addedTime', 'multiplicationFactor', 'catchweight', 'quantity', 
                    'priceMarked', 'promotional', 'nonPromotionalPrice','priceAfterProfileDiscount', 'creditLine');
                
                basketModel.disabled = disabled;
                basketModel.visible = visible;
                basketModel.notActive = notActive;
                
                var restricted = _.find(productLines[i].attributes, function(attribute) {
                    return attribute.restrictedForCustomer;
                }) !== undefined;    
                    
                    
                if (restricted) {
                    restrictedBasketModels.push(basketModel);
                } else {
                    basketModels.push(basketModel);
                }
            }
            
            restrictedBasketModels = _.sortBy(restrictedBasketModels, 'addedTime');
            basketModels = _.sortBy(basketModels, 'addedTime'); 
            if (restrictedBasketModels.length > 0) {
                var models = [{titleBar: "Restricted Products"}].concat(restrictedBasketModels);
                basketModels = models.concat([{titleBar: "Unrestricted Products", showGap: true}], basketModels);
            }
            
            /* Set to false when quantity being changed, 
             * as would redraw and lose the animation.*/
            if (this._updateProductList) {
                this.basketContentsView.collection.reset(basketModels);
            }

            this.basketOverView.model.clear();
            this.basketOverView.model.set(quote);
            this.basketOverView.render();
            
            this.basketHeaderView.showValues(this.model.toJSON(), this.basketIndex);
        },
        
        _updateHeaders: function() {
            /* Doesn't appear to use following
            var quotes = this.model.get("quotes");
            var Basket = Backbone.Model.extend({
            });
            var baskets = [];
            for(var i = 0; i < quotes.length; i++) {
               baskets.push(new Basket(quotes[i]));
            }*/

            this.quoteSubHeaderView.updateFromOrder(this.model, this.basketIndex);
            this.basketHeaderView.showValues(this.model.toJSON(), this.basketIndex); 
        },

        // Update the new product count.
        _updateProduct: function(count, rowModel) {
            rowModel.set('quantity', count);
        },

        _askToRequest: function() {
            quoteChannel.trigger('quote:askrequest', this.model.get("shipmentId"));
        },
        _askToConfirm: function() {
            quoteChannel.trigger('quote:ask:confirm', this.model.get("shipmentId"));
        },

        _repriceQuote: function() {
            var QuoteModel = Backbone.Model.extend({});
            var quoteModel = new QuoteModel(this.model.get('quotes')[this.basketIndex]);
            var currentPricingPeriod  = StaticModels.pricingPeriods.first();
            var modal = new QuoteRepriceModal({model: quoteModel, currentPricingPeriod: currentPricingPeriod});
            promptChannel.trigger('show:modal', modal);
        },

        _reopenQuote: function() {
            var QuoteModel = Backbone.Model.extend({});
            var quoteModel = new QuoteModel(this.model.get('quotes')[this.basketIndex]);
            var modal = new QuoteReopenModal({model: quoteModel});
            promptChannel.trigger('show:modal', modal);
        },
        
        requestQuote: function() {
            var quoteId = this.model.get('quoteId');
            var customerReference = this.model.get('customerReference');
            Authentication.makeAjaxCall({
                cache : false,
                type : 'POST',
                url : CONFIG.rocs_url + "/quote-service/quote/request/" + quoteId,
                contentType : 'application/json',
                success : function(result) {
                    customerChannel.trigger("customer:displayShipments");
                    alertChannel.trigger('ok', 'Quote ' + customerReference + ' requested (' + quoteId + ')');
                },
                error : Authentication.checkAjaxErrors
            });
        },

        onShow: function() {
            headerChannel.trigger("hide:menu");
            var self = this;
            var BasketCollection = Backbone.Collection.extend({   // reuse other
                _loaded: false
            });

            var basketCollection = new BasketCollection(); 
            this.basketContentsView = new BasketContentsView({collection: basketCollection});

            var BasketOverviewModel = Backbone.Model.extend({
            });
            this.basketOverView = new BasketOverView({model: new BasketOverviewModel()});
            
            this.getRegion('basketContents').show(this.basketContentsView);
            this.getRegion('basketOverview').show(this.basketOverView);

            var BasketHeaderModel = Backbone.Model.extend({
            });
            this.basketHeaderView = new BasketHeaderView({model: new BasketHeaderModel()});
            this.basketHeaderView.quoteId = this.model.get('quoteId');
            headerChannel.trigger('show', this.basketHeaderView);
            
            var Baskets = Backbone.Collection.extend({
            });
            var baskets = new Baskets();
            
            this.quoteSubHeaderView = new QuoteSubHeaderView({collection: baskets, justBaskets: true, quoteId: this.quoteId, quoteIndex: this.basketIndex });
            headerChannel.trigger('show-sub', this.quoteSubHeaderView);         
           
            this.listenTo(this.quoteSubHeaderView, "basket:selected", function(index) {
                self.basketIndex = index; 
                self._updateProductList = true;
                self._showBasket();
            });
           
            this.listenTo(basketChannel, "product:set", function(view, amount, original) {
                if (amount === 0) {
                    this._askToDelete(view, basketCollection, original);
                } else {
                    this._updateServerCount(view, amount);
                }
            });
          
            this.listenTo(basketChannel, "product:delete", function(view, original) {
                self._askToDelete(view, basketCollection, original);
            });

            this.listenTo(orderChannel, "reload:synchronously", function() {
                this._updateProductList = true;
                this.model.reloadSynchronously();
            });

            this.listenTo(orderChannel, "reload", function() {
                this._updateProductList = true;
                this.model.reload();
            });

            this.listenTo(basketChannel, "product:remove:okay", function(view, basketModel){
                self._deleteProduct(view, basketModel);
                
            });
        },
        
        _updateServerCount: function(view, amount) {    //<--- Reflecting quantity change on back-end
            var self = this;
            view.showQuantityUpdating();
            this._updateProductList = false;
            var quoteId = this.model.get('quotes')[this.basketIndex].quoteId;
            var productId = view.model.get('productCode');
            var item = _.where(this.model.get('quotes')[this.basketIndex].basket.productLines, {'productCode': productId})[0];
            var cases = item.caseSize * amount;
            var original = item.quantity;
            item.quantity = amount;
            view.model.locked=true;
                
            this.model.setProduct(quoteId, view.model.get('productCode'), amount).success(function(count) { 
                view.showQuantityUpdated();
                $("#itemAmount-" + view.model.get('productCode')).val(count);
                view.model.set('quantity', count);     

                self.model.get('quotes')[self.basketIndex].basket.basketTotalCases += (amount - original);
                self.model.get('quotes')[self.basketIndex].basket.basketTotalPrice +=(amount - original) * item.price;
                self.model.attributes.totalCases += (amount - original);
                self.model.attributes.totalPrice += (amount - original) * item.price;

                var productModel = self.model.get('quotes')[self.basketIndex].basket.productLines.find(function(model) {
                    return model.productCode === view.model.get('productCode');
                });

                var originalVolume = Math.round(productModel.volume * original * 1e4) / 1e4;
                var originalGrossWeight = Math.round(productModel.grossWeight * original * 1e4) / 1e4;

                var newVolume = Math.round(productModel.volume * amount * 1e4) / 1e4;
                var newGrossWeight = Math.round(productModel.grossWeight * amount * 1e4) / 1e4;

                var newBasketTotalVolume = self.model.get('quotes')[self.basketIndex].basket.basketTotalVolume + (newVolume - originalVolume);
                var newBasketTotalWeight = self.model.get('quotes')[self.basketIndex].basket.basketTotalGrossWeight + (newGrossWeight - originalGrossWeight);

                self.model.get('quotes')[self.basketIndex].basket.basketTotalVolume = Math.round(newBasketTotalVolume * 1e4) / 1e4;
                self.model.get('quotes')[self.basketIndex].basket.basketTotalGrossWeight = Math.round(newBasketTotalWeight * 1e4) / 1e4;

            // rerender  the overview and header
                self.basketOverView.render();
                self.basketHeaderView.showValues(self.model.toJSON(), self.basketIndex); 
                self.updatePercentBar(self.model.get('quotes')[self.basketIndex]);

                view.animateQuantityChange(amount);
                view.model.locked=false;
            }).error(function(err) {
                view.model.locked=false;
                view.showQuantityUpdated();
                Authentication.checkAjaxErrors(err);
            });
        },
        updatePercentBar: function(quote){
            var current  = $(".basket-tab.basket-selected");
            var statusText = $("#status-" + quote.quoteId);
            var priceCases = $("#price_cases-" + quote.quoteId);
            var percent = this.getPercent(quote);
            if(percent < 0){
                percent = 0;
            }
            statusText.text("Minimum order: " + Math.max(0, Math.floor((percent * 100))) +"% achieved");
            var symbol = priceCases.text().charAt(1); 
            if(!isNaN(symbol)){
                symbol = priceCases.text().charAt(0);
            }
            priceCases.text(symbol + this.model.get('quotes')[this.basketIndex].basket.basketTotalPrice.toFixed(2) + " | " + this.model.get('quotes')[this.basketIndex].basket.basketTotalCases + " cases");
            var offset =  -270 * (1 - percent);
            current.css('background-position', offset +'px 0px') ;
            current.append("<div class='basket-edit'><span class='glyphicon glyphicon-pencil' aria-hidden='true'></span></div>");
            current.append("<div class='basket-info'><span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span></div>");
        },
        getPercent: function(quote) {
            var minimumOrderQuantity = quote.minimumOrderQuantity;
            var basket = quote.basket;
            var percent = 0;
            if (minimumOrderQuantity) {
                if (minimumOrderQuantity.minimumQuantity) {
                    percent = basket.basketTotalCases / minimumOrderQuantity.minimumQuantity;
                } else if (minimumOrderQuantity.minimumValue) {
                    percent = basket.basketTotalPrice / minimumOrderQuantity.minimumValue;
                }
            }
            // Limit to 
            if (percent > 1.0) {
                percent = 1.0;
            }
            return percent;
        },
        
        // Display a "are you sure" modal.       
        _askToDelete: function(view, basketModel, original) {
            var callback = function() {
                basketChannel.trigger("product:remove:okay", view, basketModel);
            };
            var modal = new DeleteProductModal({callback: callback, original: original, view: view});
            promptChannel.trigger('show:modal', modal, original);
        },
        
        _deleteProduct: function(view, basketModel) {
            var productId = view.model.attributes.productCode;
            var item = _.where(this.model.get('quotes')[this.basketIndex].basket.productLines, {'productCode': productId})[0];
            this.model.get('quotes')[this.basketIndex].basket.basketTotalCases -= item.quantity;
            item.quantity = 0;
            this.updatePercentBar(this.model.get('quotes')[this.basketIndex]);
            var rowModel = view.model;
            var productCode = rowModel.get('productCode');
            var basketName = this.basketName;
            var self = this;
            var rowCells = view.$('div.cell');
            var completed = false;
            
            rowCells.animate({height: 0, paddingTop: 0, paddingBottom: 0}, 500, function() {
                if (! completed) { // Called for every column, so ensure delete is only done once.
                    completed = true;
                    var quoteId = self.model.get('quotes')[self.basketIndex].quoteId;
                    self.model.deleteProduct(quoteId, productCode).success(function(count) {
                        basketModel.remove(rowModel);     
                        orderChannel.trigger("reload");
                        alertChannel.trigger('ok', 'Removed product ' + rowModel.get('productCode'));
                    }).error(Authentication.checkErrors);
                }
            });
        }
    });
    
    return BasketLayoutView;

});
