define('i18n',[],
function() {
   
    var i18n = {
        exceptions: {
            en: {
                '201': 'Database problem',
                '601': 'Invalid Quote Id',
                '602': 'Product not on quote',
                '603': 'The Quote cannot be modified',
                '604': 'No prices can be found',
                '701': 'Cannot find product',
                '702': 'Product not available',
                '703': 'No saved products found',
                '901': 'FTP communications problem',
                '801': 'There is no order line history for this account'
            }
        }
    };
    
    
    return i18n;
});
    	


