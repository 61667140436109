define('quoteRequestView',[
        'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer'
    ],
    function (Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer) {

        var quoteChannel = BackboneRadio.channel('quoteChannel');
        var issuesChannel = BackboneRadio.channel('issuesChannel');
        var customerChannel = BackboneRadio.channel('customerChannel');
        var promptChannel = BackboneRadio.channel('promptChannel');
        var basketChannel = BackboneRadio.channel('basketChannel');

        var BasketList = Backbone.Collection.extend({});

        var basketList = new BasketList();

        var QuoteRequestView = Marionette.LayoutView.extend({
            template: 'templates/quotes/quote-submit-view.hbs',
            regions: {
                basketList: "#quote-item-list-region",
                basketListConfirm: "#basket-item-list-region-confirm"
            },
            collection: basketList,
            _mappedCollection: {},
            _restrictedProducts: false,

            initialize: function () {
                this.listenTo(issuesChannel, 'quote:updated', this.resolveIssues);
                var modelObj = {
                    title: "Choose quotes to submit for Shipment",
                    confirm: "Submit request for quotes",
                    confirm_n: "Submit request for %1 quote%2",
                    shipmentId: this.model.get('shipmentId'),
                    modalState: "OPEN"
                };
                var quoteMatcher = function (quote) {
                    return (quote.quoteStatus === 'OPEN' || quote.quoteStatus === 'REOPENED' || quote.quoteStatus === 'CONFIRM');
                };
                this.displayModal(modelObj, quoteMatcher, "OPEN");
            },

            onRender: function () {

            },

            resolveIssues: function (quoteId, quote) {
                var self = this;
                this.collection.each(function (model, idx, list) {
                    if (model.get('quoteId') === quoteId) {
                        var belowMoq;
                        if (quote.minimumOrderQuantity.minimumQuantity > 0) {
                            belowMoq = (quote.basket.basketTotalCases < quote.minimumOrderQuantity.minimumQuantity);
                        } else {
                            belowMoq = (quote.basket.basketTotalPrice < quote.minimumOrderQuantity.minimumValue);
                        }
                        quote.belowMoq = belowMoq;
                        self.collection.remove(self.collection.at(idx));
                        if (quote.basket.productLines.length > 0) {
                            self.collection.add(new Backbone.Model(quote), {at: idx})
                        }
                    }
                });
            },

            displayModal: function (modelObj, quoteMatcher, state) {
                quoteIds = [];
                this.model.set(modelObj);
                this.render();
                this._displayCount();
                this._displayRestricted();
                this.$('#basket-request-confirm').prop('disabled', true);
                this.$('#basket-request-confirm-confirm').prop('disabled', true);
                this.$('#basket-items-banned').hide();
                this.retrieveOrder(this.model.get('shipmentId'), quoteMatcher, state);
            },

            events: {
                'click #basket-request-confirm': 'confirm',
                'click #basket-request-confirm-confirm': 'confirm',
                'click #basket-request-cancel': 'cancel',
                'click #select-all': 'selectAll',
                'click #select-all-confirm': 'selectAll',
                'click #agree_submit_banned_products': 'agreeSubmitBanned',
                'mouseenter #ttConfirmModal': 'showPopover',
                'mouseleave #ttConfirmModal': 'hidePopover',
            },

            showPopover: function () {
                $('#ttConfirmModal').popover('show');
            },
            hidePopover: function () {
                $('#ttConfirmModal').popover('hide');
            },

            selectAll: function () {
                var self = this;
                $.each(this.collection.models, function (index, model) {
                    var quoteId = model.get("quoteId");
                    if (quoteIds.indexOf(quoteId) === -1 && (model.get("state") === undefined || model.get("terms") === 1)) {
                        var sId = "#selectBtn-" + quoteId;
                        if ($(sId).hasClass('disabled'))
                            return;
                        quoteIds.push(quoteId);
                        self.$(sId + ' .select-span').addClass('glyphicon glyphicon-ok');
                        self.$(sId + ' .select-span-text').text(' Selected');
                    }
                });
                this._displayCount();
                this._displayRestricted();
            },

            agreeSubmitBanned: function (event) {
                var checkbox = $(event.target);
                var checkboxChecked = checkbox.prop('checked');
                this.$('#basket-request-confirm').prop('disabled', !checkboxChecked);
            },

            _displayCount: function () {
                var text;
                if (quoteIds.length > 0) {
                    text = this.model.get('confirm_n').replace("%1", quoteIds.length);
                    text = text.replace("%2", quoteIds.length > 1 ? "s" : "");
                } else {
                    text = this.model.get('confirm');
                }
                this.$('#basket-request-confirm').text(text);
                this.$('#basket-request-confirm-confirm').text(text);
            },

            _displayRestricted: function () {
                var self = this;
                /* Decide on showing the restricted quotes section.*/
                var quotesRestricted = [];
                _.each(quoteIds, function (quoteId) {
                    var quote = self._mappedCollection[quoteId];
                    var restricted = _.find(quote.basket.productLines, function (productLine) {
                        var restrictedQuoteProduct = _.find(productLine.attributes, function (attribute) {
                            return attribute.restrictedForCustomer === true;
                        });
                        return restrictedQuoteProduct !== undefined;
                    }) !== undefined;
                    if (restricted) {
                        quotesRestricted.push(quoteId);
                        self._restrictedProducts = true;
                    }
                });
                if (quotesRestricted.length > 0) {
                    this.$('#agree_submit_banned_products').prop('checked', false);
                    this.$('#basket-items-banned').show(200);
                    this.$('#banned_quotes').text(quotesRestricted.join(', '));
                } else {
                    this.$('#basket-items-banned').hide(200);
                    this.$('#basket-items-banned-confirm').hide(200);
                }
                var confirmingQuote = (self.model.get('modalState') == 'READY_FOR_CONFIRMATION');
                this.$('#basket-request-confirm').prop('disabled', (quoteIds.length === 0) || (quotesRestricted.length > 0 && !confirmingQuote));
                this.$('#basket-request-confirm-confirm').prop('disabled', (quoteIds.length === 0) || (quotesRestricted.length > 0 && !confirmingQuote));
            },

            cancel: function () {
                basketChannel.trigger('showBasket', this.model.get("shipmentId"), this.quoteId);
            },
            confirm: function () {
                var self = this;
                // prevent double clicking
                this.$('#basket-request-confirm').prop('disabled', true);
                this.$('#basket-request-confirm-confirm').prop('disabled', true);
                var mapQuoteIds = {};
                _.each(quoteIds, function (id) {
                    mapQuoteIds[id] = id;
                });

                var ConfirmationModel = Backbone.Model.extend({});
                var totalCases = 0;
                var totalPrice = 0.0;
                var quotes = [];
                $.each(this.collection.models, function (index, model) {
                    if (mapQuoteIds[model.get('quoteId')]) {
                        var basket = model.get('basket');
                        totalCases += basket.basketTotalCases;
                        totalPrice += basket.basketTotalPrice;
                        quotes.push(model.get('quoteId'));
                    }
                });
                var confirmationModel = new ConfirmationModel({
                    totalCases: totalCases, totalPrice: totalPrice,
                    nQuotes: quoteIds.length, shipmentId: this.model.get('shipmentId'), quotes: quotes.join(', ')
                });

                /* hide the modal and wait till it is all gone before posting the confirm,
                   this is so the confirmation modal won't be displayed while the previous modal is being closed.
                   */

                var doSuccess = function (result) {
                    promptChannel.trigger('show', {
                        template: "templates/quotes/quote-confirm-modal.hbs",
                        model: confirmationModel
                    });
                    // orderChannel.trigger("reload");
                    customerChannel.trigger("customer:displayShipments");
                };

                var doError = function () {
                    Authentication.checkAjaxErrors();
                    this.$('#basket-request-confirm').prop('disabled', false);
                };

                $('#basket-request-confirm').prop('disabled', true);
                Authentication.makeAjaxCall({
                    cache: false,
                    type: 'POST',
                    url: CONFIG.rocs_url + "/quote-service/order/quotes/confirm?includeRestrictedProducts=" + self._restrictedProducts,
                    contentType: 'application/json',
                    data: JSON.stringify(quoteIds),
                    success: doSuccess,
                    error: doError
                });
            },
            retrieveOrder: function (shipmentId, quoteMatcher, state) {
                var self = this;
                self.shipmentId = shipmentId;
                var doSuccess = function (order) {
                    var quotesList = [];
                    var quotesInOrder = order.quotes;
                    if (quotesInOrder) {
                        $.each(quotesInOrder, function (index, quote) {
                            var totalCases = quote.basket.basketTotalCases;
                            var totalPrice = quote.basket.basketTotalPrice;
                            if (quoteMatcher(quote)) {
                                if (totalCases > 0 || totalPrice > 0) {
                                    var belowMoq;
                                    if (quote.minimumOrderQuantity.minimumQuantity > 0) {
                                        belowMoq = (totalCases < quote.minimumOrderQuantity.minimumQuantity);
                                    } else {
                                        belowMoq = (totalPrice < quote.minimumOrderQuantity.minimumValue);
                                    }

                                    var collectionValue = {
                                        shipmentId: self.shipmentId,
                                        deliveryDate: quote.deliveryDate,
                                        effectiveUntil: quote.effectiveUntil,
                                        quoteId: quote.quoteId,
                                        customerReference: quote.customerReference,
                                        basket: quote.basket,
                                        belowMoq: belowMoq,
                                        issues: quote.issues
                                    };

                                    self.quoteId = quote.quoteId;
                                    quotesList.push(collectionValue);
                                    self._mappedCollection[quote.quoteId] = collectionValue;
                                }
                            }
                        });
                    }

                    quotesList.sort(function (a, b) {
                        return a.quoteId.localeCompare(b.quoteId);
                    });

                    self.collection.reset(quotesList);

                    var quotesText = " quotes";
                    if (self.collection.length == 1) {
                        quotesText = " quote";
                    }
                    $('#numberOfBasket').text(self.collection.length + quotesText);
                    $('#numberOfBasketConfirm').text(self.collection.length + quotesText);

                    var basketListView = new BasketListView({collection: self.collection});
                    self.listenTo(basketListView, 'childview:quote:selected', function () {
                        self._displayCount();
                        self._displayRestricted();
                    });

                    if (state === "OPEN") {
                        self.getRegion('basketList').show(basketListView);
                    } else {
                        for (var j = 0; j < self.collection.models.length; j++) {
                            self.collection.models[j].set("state", state);
                        }
                        self.getRegion('basketListConfirm').show(basketListView);
                    }
                    self._displayCount();
                    self._displayRestricted();
                };

                Authentication.makeAjaxCall({
                    cache: false,
                    type: 'GET',
                    url: CONFIG.rocs_url + "/quote-service/order/get/" + shipmentId,
                    contentType: 'application/json',
                    success: doSuccess,
                    error: Authentication.checkAjaxErrors
                });
            },
        });

        var BasketItemView = Marionette.ItemView.extend({
            template: "templates/controls/basket-request-item.hbs",
            events: {
                'click .select': 'select',
                'click .termsBox': 'acceptTerms',
                'click .basket-request-cancel': 'cancel',
                'click .issues-text': 'showIssuesModal'
            },
            templateHelpers: function () {
                var data = {};
                data.hasIssues = this.model.get('issues') > 0;
                data.issuesProductString = this.model.get('issues') > 1 ? 'products' : 'product';
                return data;
            },
            select: function (event) {
                if ($(event.currentTarget).hasClass('disabled'))
                    return;
                this.updateButton(this.model.get("quoteId"), this.model.get("terms"));
            },
            acceptTerms: function (e) {
                var checkBox = $(e.target);
                var sId = "#selectBtn-" + this.model.get("quoteId");
                var selectBtn = this.$el.find(sId);
                if (checkBox.is(':checked')) {
                    selectBtn.removeClass("disabled");
                    this.model.set("terms", 1);
                } else {
                    selectBtn.addClass("disabled");
                    this.model.set("terms", 0);
                    this.updateButton(this.model.get("quoteId"), this.model.get("terms"));
                }
            },

            onRender: function () {
                var sId = "#selectBtn-" + this.model.get("quoteId");
                var selectBtn = this.$el.find(sId);
                if (this.model.get('issues') > 0) {
                    selectBtn.addClass("disabled");
                }
                if (this.model.get("state") === "READY_FOR_CONFIRMATION") {
                    selectBtn.addClass("disabled");
                } else {
                    var termsCheckbox = this.$el.find('.quote_submit_terms');
                    termsCheckbox.hide();
                }
            },

            updateButton: function (selected, terms) {
                var index = quoteIds.indexOf(selected);
                if (terms === undefined || terms === 1) {
                    if (index !== -1) {
                        quoteIds.splice(index, 1);
                        this.$el.find('.select-span').removeClass('glyphicon glyphicon-ok');
                        this.$el.find('.select-span-text').text('Select');
                    } else {
                        quoteIds.push(selected);
                        this.$el.find('.select-span').addClass('glyphicon glyphicon-ok');
                        this.$el.find('.select-span-text').text(' Selected');
                    }
                } else {
                    if (index !== -1) {
                        quoteIds.splice(index, 1);
                    }
                    this.$el.find('.select-span').removeClass('glyphicon glyphicon-ok');
                    this.$el.find('.select-span-text').text('Select');
                }

                this.trigger('quote:selected');
            },

            showIssuesModal: function (event) {
                quoteChannel.trigger('quote:get:issues', this.model.get('quoteId'), this.model.get('issues'), this.model);
            }
        });

        var BasketListView = Marionette.CollectionView.extend({
            childView: BasketItemView
        });

        return QuoteRequestView;
    });
