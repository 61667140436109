define('navbar',[
    'backbone', 'marionette', 'backboneradio', 'currentCustomer', 'i18n', 'menuView', 'authentication'
],
function(Backbone, Marionette, BackboneRadio, CurrentCustomer, i18n, MenuView, Authentication) {
    
    var customerChannel = BackboneRadio.channel('customerChannel');
    var productChannel = BackboneRadio.channel('productChannel');
    var alertChannel = BackboneRadio.channel('alertChannel');
    var headerChannel = BackboneRadio.channel('headerChannel');
    
    var NavbarView = Marionette.LayoutView.extend({
        template: "templates/navbar/navbar.hbs",
        className: 'header-fixed',
        regions: {
            'headerRow': '#header_row',
            'subHeaderRow': '#sub_header_row',
            'menuHeaderRow': '#menu-header'
        },
        
        messages: [],
        
        _setMsgTimer: function() {
            var self = this;
            if (this.msgTimer) {
                clearTimeout(this.msgTimer);
                delete this.msgTimer;
            }
            this.msgTimer = setTimeout(function() {
                    self.messageIsDisplayed = false;
                    $('#header-msg').animate({top: "-76px"}, 500);
                }, 3000);
        },
        showMessage: function(message, highlightClass) {
            if (this.messageIsDisplayed) {
                this._setMsgTimer(); 
            } else {
                this.messages = [];
                this.messageIsDisplayed = true;
                $('#header-msg').animate({top: "15px"}, 500);
                this._setMsgTimer();
            }

            this.messages.push('<div class="msg-row">'+message+'</div>');
            if (this.messages.length > 3) {
                this.messages.shift();
            }
            $('#header-msg-content').html(this.messages);

        },
        
        _showError: function(message) {
                var msg;
                if (message.responseJSON && message.responseJSON.errorCode) {
                    var errorCode = message.responseJSON.errorCode;
                    msg = i18n.exceptions.en[errorCode];
                    if (msg === undefined) {
                        msg = 'Error Code ' + errorCode + ', message ' + message.responseJSON.message;
                    }
                } else if (message.status) {
                    msg = 'Error + ' + message.status + ' ' + message.statusText;
                } else{
                    msg = 'An error occurred';
                }
                this.showMessage(msg, 'alert-error');           
        },
        
        events: {
            "click #logout-button"          : "logout",
            "click #home-button"      : "displayLandingPage",
            "click #customer-change-link"   : "displayCustomerSearch"
        },

        logout: function() {
            Authentication.logout();
        },

        displayCustomerSearch: function(){
            customerChannel.trigger("customer:displaysearch");
        },
        
        displayLandingPage: function() {
            productChannel.trigger("remove:filters");
            customerChannel.trigger("customer:displayShipments");
        },
        
        initialize: function() {
            var self = this;
            this.listenTo(customerChannel, "customer:displayShipments", function() {
                $('#login-greeting').text("Hello,");
                $('#login-name').text(CurrentCustomer.name.replace(/&amp;/g, '&').replace(/&#x27;/g, '\'') + " | ");
            });
            this.listenTo(alertChannel, "error", function(message) {
                self._showError(message);
            });
            this.listenTo(alertChannel, "ok", function(message) {
                self.showMessage(message, 'alert-ok');
            });
            this.listenTo(alertChannel, "validation", function(message) {
                self.showMessage(message, 'alert-error');
            });
            this.listenTo(headerChannel, 'show', function(view) {
                this.$('#lower-header').show();
                self.currentView = view;
                self.getRegion('headerRow').show(view);
                self._hideSubRow();
            });
            this.listenTo(headerChannel, 'empty', function() {
                this.$('#lower-header').hide();
                self.getRegion('headerRow').reset();
                self._hideSubRow();
            });
            this.listenTo(headerChannel, 'show-sub', function(view) {
                self.getRegion('subHeaderRow').show(view);
            });
            
            this.listenTo(headerChannel, 'show:menu', function(selectedMenu) {
                $('#home-button').hide();
                var menuView = new MenuView({selectedMenu: selectedMenu});
                self.getRegion('menuHeaderRow').show(menuView);
            });
            this.listenTo(headerChannel, 'hide:menu', function() {
                $('#home-button').show();
                self.getRegion('menuHeaderRow').reset();
            });
        },
        
        _hideSubRow: function() {
            if (this.getRegion('subHeaderRow').$el) {
                this.getRegion('subHeaderRow').$el.hide();
            }
            this.getRegion('subHeaderRow').reset();
        }

    });
	
    return NavbarView;
});
