define('rocs-app',[
        'backbone', 'marionette', 'backboneradio', 'jquery', 'jQueryUI', 'bootstrap', 'authentication', 'productSearchLayoutView', 'navbar',
        'controlsView', 'quoteListView', 'quoteDetailsView', 'basketView', 'templating', 'quoteBuildLayoutView',
        'productSearchStaticFilterModel', 'staticModels', 'ordersModel', 'metricLayoutView', 'orderHistoryLayoutView', 'quoteRequestView',
        'keycloak', 'routing', 'currentCustomer'
    ],
    function(Backbone, Marionette, BackboneRadio, JQuery, jQueryUI, Bootstrap, Authentication, ProductSearchLayoutView, Navbar,
             ControlsView, QuoteListView, QuoteDetailsView, BasketView, templating, QuoteBuildLayoutView,
             productSearchStaticFilterModel, staticModels, OrdersModel, MetricLayoutView, OrderHistoryLayoutView, QuoteRequestView,
             keycloak, Routing, CurrentCustomer) {

        var productChannel = BackboneRadio.channel('productChannel');
        var quoteChannel = BackboneRadio.channel('quoteChannel');
        var customerChannel = BackboneRadio.channel('customerChannel');
        var basketChannel = BackboneRadio.channel('basketChannel');
        var promptChannel = BackboneRadio.channel('promptChannel');
        var orderChannel = BackboneRadio.channel('orderChannel');
        var headerChannel = BackboneRadio.channel('headerChannel');

        var historyLoaded = false;

        var ModalView = Marionette.ItemView.extend({
            className: "modal fade",
            isOkayed: false,
            events: {
                'hidden.bs.modal': 'modalHidden',
                'click button.modal-btn-cancel': 'cancelClicked',
                'click button.modal-btn-okay': 'okayClicked'
            },
            cancelClicked: function() {
                this.$el.modal('hide');
            },
            okayClicked: function() {
                this.isOkayed = true;
                this.$el.modal('hide');
            },
            modalHidden: function() {
                if (this.options.callback) {
                    this.options.callback(this.isOkayed);
                }
            }
        });

        var router = new Routing();

        var RocsApp = new Marionette.Application();
        RocsApp.on("before:start", function() {
            templating();
            var RegionContainer = Marionette.LayoutView.extend({
                el: "#app-container",
                regions: {
                    navbar: "#navbar-region",
                    controls: "#controls-region",
                    rocsApp: "#rocsapp-region",
                    'modalPrompt': '#modal-region'
                }
            });

            RocsApp.regions = new RegionContainer();
        });


        var loadStatic = function() {
            productSearchStaticFilterModel.load().success(function() {
                if (! historyLoaded) {
                    historyLoaded = false;
                    Backbone.history.start({pushState: true});
                }
            });
        };

        var loadCustomerAndThenStatic = function() {
            CurrentCustomer.load(keycloak.userInfo.customerCode).success(loadStatic);
        };


        RocsApp.on("start", function() {
            var self = this;
            var initialiseWebPage = function(){
                RocsApp.regions.navbar.show(new Navbar());
                RocsApp.regions.controls.show(new ControlsView());

                self.listenTo(productChannel, "product:displaysearch", function(filters) {
                    RocsApp.regions.rocsApp.show(new ProductSearchLayoutView(filters));
                });

                self.listenTo(customerChannel, "customer:showmetrics", function() {
                    RocsApp.regions.rocsApp.show(new MetricLayoutView());
                });

                self.listenTo(quoteChannel, "quote:view:quote", function(orderModel, quoteId, quoteIndex) {
                    var quoteBuildLayoutView = new QuoteBuildLayoutView({model: orderModel, quoteId: quoteId, quoteIndex: quoteIndex});
                    RocsApp.regions.rocsApp.show(quoteBuildLayoutView);
                });

                self.listenTo(quoteChannel, "quote:details", function(quote) {
                    RocsApp.regions.rocsApp.show(new QuoteDetailsView({model: quote}));
                });

                self.listenTo(quoteChannel, "quote:askrequest", function (shipmentId) {
                    headerChannel.trigger('empty');
                    RocsApp.regions.rocsApp.show(new QuoteRequestView({model: new Backbone.Model({shipmentId: shipmentId})}));
                });


                self.listenTo(customerChannel, 'customer:selected', loadStatic);

                self.listenTo(customerChannel, 'customer:displayShipments', function(){
                    RocsApp.regions.rocsApp.show(new QuoteListView());
                });

                self.listenTo(orderChannel, "order:showhistory", function() {
                    RocsApp.regions.rocsApp.show(new OrderHistoryLayoutView());
                });

                self.listenTo(basketChannel, 'showBasket:order', function(shipmentId, orderId, orderIndex) {
                    var model = new OrdersModel();
                    model.load(shipmentId);
                    var options = {model: model};
                    if (orderId !== undefined) {
                        options.orderId = orderId;
                        options.orderIndex = orderIndex;
                    }
                    var view = new BasketView(options);
                    RocsApp.regions.rocsApp.show(view);
                });

                self.listenTo(basketChannel, 'showBasket', function(shipmentId, quoteId, quoteIndex) {
                    var model = new OrdersModel();
                    model.load(shipmentId);
                    var options = {model: model};
                    if (quoteId !== undefined) {
                        options.quoteId = quoteId;
                        options.quoteIndex = quoteIndex;
                    }
                    var view = new BasketView(options);
                    RocsApp.regions.rocsApp.show(view);
                });


                var displayModal = function(view) {
                    var countDown = 10;
                    var display = function() {
                        countDown--;
                        if ($(document.body).hasClass('modal-open') && (countDown > 0)) {
                            setTimeout(function() {
                                display();
                            }, 100);
                        } else {
                            RocsApp.regions.modalPrompt.show(view);
                            view.$el.modal('show');
                        }
                    };
                    display();
                };

                self.listenTo(promptChannel, 'show', function(options) {
                    displayModal(new ModalView(options));
                });

                self.listenTo(promptChannel, 'show:modal', function(view) {
                    displayModal(view);
                });
            };


            var loadUserProfile = function(){
                keycloak.loadUserInfo().success(function(){
                    initialiseWebPage();
                    staticModels.load();
                    var authorities = keycloak.userInfo.authorities;
                    if (authorities.includes("ROLE_CSA") || authorities.includes("ROLE_ADMIN")){
                        $('#customer-change-link').css('display', 'block');
                        $('#login-greeting').text("Welcome,");
                        $('#login-name').text(keycloak.userInfo.first_name + " " + keycloak.userInfo.last_name);

                        customerChannel.trigger("customer:displaysearch");
                    } else if (authorities.includes("ROLE_CUSTOMER")) {
                        $('#my-account-span').css('display', 'block');
                        $('#loading-data-modal').modal('show');

                        loadCustomerAndThenStatic();

                    } else {
                        alert('Authorization error, not enough privilege to proceed. Contact your EAA.');
                        Authentication.logout();
                    }

                }).error(function(result){
                    console.log('Error authenticating user, status=' + result);
                    alert('Authorisation problem', textStatus, errorThrown);
                    Authentication.logout();
                });
            };

            if (Backbone.history){
                if (!keycloak.authenticated){
                    keycloak.login();
                }
                var accessToken = keycloak.token;
                if (accessToken){
                    loadUserProfile();
                }
            }

        });

        return RocsApp;
    });

