define('productSearchFilters',[
    'backbone', 'marionette', 'backboneradio', 'productSearchFilterCategory', 'productsSearchTags',
    'productSearchStaticFilterModel', 'staticModels', 'currentCustomer', 'format', 'authentication'
],
function(Backbone, Marionette, BackboneRadio, productSearchFilterCategory, productsSearchTags,
         productSearchStaticFilterModel, staticModels, CurrentCustomer, format, Authentication) {

    var productChannel = BackboneRadio.channel('productChannel');
  //  var urlChannel = BackboneRadio.channel('urlChannel');

    // product filters param
    var tier1Filters = [];
    var tier1attributeFilters = [];
    var selectedTab = '';

    var readCheckboxes = function(id) {
            var filter = [];
            $(id + ' input:checked').each(function() {
                filter.push($(this).val());
            });
            return filter;
        };
    
    var toggleMenu = function(toggle, area, delay) {
        var toUp = $(toggle).hasClass('glyphicon-menu-down');
        if (toUp) {
            $(toggle).removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
            $(area).slideDown(delay);
        } else {
            $(toggle).removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
            $(area).slideUp(delay);
        }
        return toUp; // return true if visible
    };
    
    var RangeAndBrandsView = Marionette.ItemView.extend({
        template: "templates/products/search/sub-view/filter-range_brands.hbs",
        templateHelpers: function() {
            var alphabet = [];
            var brandNames = this.model.get("brandNames");
            for(var j = 0; j < brandNames.length; j++) {
                var firstLetter = brandNames[j].charAt(0);
                if ((firstLetter >= '0') && (firstLetter <= '9')) {
                    firstLetter = '0-9';
                } else {
                    firstLetter = firstLetter.toUpperCase();
                }

                if (alphabet.indexOf(firstLetter) === -1){
                    alphabet.push(firstLetter);
                }
            }
            
            var brands = [];
            var lastLetter = '';
            for(var i = 0; i < brandNames.length; i++) {
                var brandName = brandNames[i];
                var letter = brandName.charAt(0).toUpperCase();
                if ((letter >= '0') && (letter<= '9')) {
                    letter = '0-9';
                }
                if (letter !== lastLetter) {
                    brands.push({name: brandName, letter: letter, newSection: true});
                    lastLetter = letter;
                } else {
                    brands.push({name: brandName, letter: letter});
                }
            }
            var topBrandsTabClass = '';
            var searchBrandsTabClass = '';
            var filterBrandClass = '';
            var filterBrandTopNclass = '';
            var brandSearchClass = '';
            if (this.isShowingSearch) {
                searchBrandsTabClass = 'active_tab';
                filterBrandTopNclass = 'hide_brands';
                if (this.model.get('topBrands').length === 0) {
                    topBrandsTabClass = 'invisible';
                }
            } else {
                topBrandsTabClass = 'active_tab';
                filterBrandClass = 'hide_brands';
                brandSearchClass = 'hide_brands';
            }
    
            
            var rangesWhiteMap = {};
            _.each(this.model.get('ranges'), function(range) {
                rangesWhiteMap[range] = true;
            });
            
            
            return  {
                rangeSpecified: this.getOption('rangeSpecified'),
                alphabet: alphabet,
                brands: brands,
                searchBrandsTabClass: searchBrandsTabClass,
                topBrandsTabClass: topBrandsTabClass,
                filterBrandTopNclass: filterBrandTopNclass,
                filterBrandClass: filterBrandClass,
                brandSearchClass: brandSearchClass,
                rangesWhiteMap: rangesWhiteMap
            };
        },
        events: {
            'click .alphabet_selection a': 'letterClick',
            'click #brand_search_button': 'brandSearch',
            'keyup #brand_search_text': 'keyup',
            'click #brand_top_brands': 'showTopBrands',
            'click #brand_A-Z_search': 'showAzSearch',
            'click #range_menu__up_down': 'showOrHideRange',
            'click #brands_menu__up_down': 'showOrHideBrands',
            'click #advanced_menu__up_down': 'showOrHideAdvanced'
        },
        isShowingSearch: true,
        letterClick: function(event) {
            var letter = $(event.target).text().trim();
            var section = this.$('#filter_brand_' + letter);
            this.$('#filter_brand_search').animate({scrollTop: section[0].offsetTop-2}, 400);
            return false;
        },
        keyup: function(event) {
            if (event.keyCode === 13) {
                this.brandSearch();
            }
            return false;
        },

        initialize: function() {
            this._rangeToSelect = (this.options.range) ? this.options.range : [];
            this._brandsToSelect = (this.options.brands) ? this.options.brands : [];
        },

        _rangeToSelect: [],
        _brandsToSelect: [],
        
        _rangeExpanded: false,
        _brandsExpanded: false,
        _advancedExpanded: false,
        
        showOrHideRange: function() {
            this._rangeExpanded = toggleMenu('#range_menu__up_down', '#filter_range', 400);
        },
        showOrHideBrands: function() {
            this._brandsExpanded = toggleMenu('#brands_menu__up_down', '#brands_panel', 400);
        },
        showOrHideAdvanced: function() {
            this._advancedExpanded = toggleMenu('#advanced_menu__up_down', '#filter_advanced', 800);
        },
        
        onRender: function() {
            var i;
            if (this._rangeExpanded) {
                toggleMenu('#range_menu__up_down', '#filter_range');
            }
            if (this._brandsExpanded) {
                toggleMenu('#brands_menu__up_down', '#brands_panel');
            }
            if (this._advancedExpanded) {
                toggleMenu('#advanced_menu__up_down', '#filter_advanced');
            }

            // Set ranges for first render.
            var rangePanel = this.$('#filter_range');
            for(i = 0; i < this._rangeToSelect.length; i++) {
                var rangeName = this._rangeToSelect[i];
                $('input[name="filter_range"][value="' + rangeName + '"]', rangePanel).prop('checked', true);
            }
            this._rangeToSelect = [];

            // Set brands for first render.
            var brandsPanel = this.$('#brands_panel');
            for(i = 0; i < this._brandsToSelect.length; i++) {
                var brandName = this._brandsToSelect[i];
                $('input[name="filter_brand"][value="' + brandName + '"]', brandsPanel).prop('checked', true);
            }
            this._brandsToSelect = [];
        },
        
        brandSearch: function() {
            var text = this.$('#brand_search_text').val().trim().toUpperCase();
            if (text.length > 0) {
                var brandNames = this.model.get("brandNames");
                for(var i = 0; i < brandNames.length; i++) {
                    if (brandNames[i].toUpperCase().indexOf(text) === 0) {
                        var checkbox = this.$('#filter_brand_search input.is_checkbox')[i];
                        this.$('#filter_brand_search').animate({scrollTop: checkbox.offsetTop-2}, 400);
                        return false;
                    }
                }
            }
            return false;
        },
        showTopBrands: function() {
            this.isShowingSearch = false;
            this.switchTabs('#brand_top_brands', '#brand_A-Z_search');
            $('#brand_search').fadeOut(400);
            $('#filter_brand_search').hide();
            $('#filter_brand_top_n').show();
        },
        showAzSearch: function() {
            this.isShowingSearch = true;
            this.switchTabs('#brand_A-Z_search', '#brand_top_brands');
            $('#brand_search').fadeIn(400);
            $('#filter_brand_search').show();
            $('#filter_brand_top_n').hide();
        },
        switchTabs: function(selectedTab, deSelectedTab) {
            $(selectedTab).addClass('active_tab');
            $(deSelectedTab).removeClass('active_tab');
        },
        
        readBrands: function() {
            return readCheckboxes('#filter_brand');
        }
    });

    var SearchFilterModel = Backbone.Model.extend({
        initialize: function() {
            var ranges = productSearchStaticFilterModel.get('ranges');
            var groupedRanges = productSearchStaticFilterModel.get('groupedRanges');
            var brandNames = productSearchStaticFilterModel.get('brandNames');
            var rangeToMainGroupMap = productSearchStaticFilterModel.get('rangeToMainGroupMap');
            var topBrands = productSearchStaticFilterModel.get('topBrands');
            var properties = {
                 ranges: ranges,
                 groupedRanges: groupedRanges,
                 brandNames: brandNames,
                 fullRanges: ranges,
                 fullBrandNames: brandNames,
                 rangeToMainGroupMap: rangeToMainGroupMap,
                 groupedRangesMap: productSearchStaticFilterModel.get('groupedRangesMap'),
                 topBrands: topBrands,
                 rangesWithGroups: productSearchStaticFilterModel.get('rangesWithGroups'),
                 justGroupedRangesMap: productSearchStaticFilterModel.get('justGroupedRangesMap')

            };
            this.set(properties);
        }
    });

    var  maxSliderIndex = 15;
    var  maxSliderDays = 1000;

    var SearchFilterView = Marionette.LayoutView.extend({
            cidPrefix: 'SearchFilterView_',
            className: 'search-filter-container',
            template: "templates/products/search/search-filter-view.hbs",
            regions: {
                'filterTags' : '#search-filter-tag-region',
                'category' :'#search-filter-category-region',
                'range_brands':'#range_brands-region'
            },
            events: {
                'click .js-products-search': 'searchForProducts',
                 'keypress #product-input-search': 'checkForEnter',
                 'click #search_filter_reset': 'resetFiltersAndSearch',
                 'click #search_filter_tier_1': 'updateTier1FiltersAndSearch',
                 'change input.is_checkbox[name="search_filter_tier_2"]': 'updateTier1FiltersAndSearch',
                 'change input.is_checkbox[name="filter_range"]': 'rangeCheckboxChanged',
                 'change input.is_checkbox[name="filter_brand"]': 'brandsCheckboxChanged',
                 'change input.is_parent_checkbox': 'selectGroupedRange',
                 'click .expand_checkbox_group': 'expandGroup',
                 'click #shelf_life__up_down': 'showOrHideShelfLife',
                 'mouseenter #ttShelfLifeContainer' :'showPopover',
                 'mouseleave #ttShelfLifeContainer' :'hidePopover'
            },

            _restoreFilters: false,

            showPopover: function() {
                $('#ttShelfLife').popover('show'); 
               
            },
            hidePopover: function() {
                $('#ttShelfLife').popover('hide');
            },

            initialize: function() {
                this.listenTo(productChannel, "product:search:start", this.searchStarting);
                this.listenTo(productChannel, "searchcomplete", this.searchComplete);
                this.listenTo(productChannel, "product:do:search", this.searchForProducts);
                this.listenTo(productChannel, "search:filter:updates", this.filterUpdates);
                this.listenTo(productChannel, "remove:filters", this.resetFilters);
                this.listenTo(productChannel, "show:items", this.showSearchTitle);

                var rangeSpecified = this.model.get('rangeName') !== undefined;
                var productSearchFilterCategoryModel = new productSearchFilterCategory.SearchCategoryModel();
                if (rangeSpecified) {
                    productSearchFilterCategoryModel.set('rangeName', this.model.get('rangeName'));
                    productSearchFilterCategoryModel.set('mainGroups', productSearchFilterCategoryModel.get('rangeToMainGroupMap')[this.model.get('rangeName')]);
                }

                this.tagCollection = new productsSearchTags.TagCollection();
                this._selectRequiredCategories(productSearchFilterCategoryModel);

                // Do a search if filters specified
                if (this.options.range) {
                    this._restoreFilters = (this.options.range.length > 0) || this.options.category ||
                        (this.options.search.length > 0) || (this.options.brands.length > 0)
                        || (this.options.tier1Filters.length > 0) || (this.options.tier1attributeFilters.length > 0);

                        tier1Filters = this.options.tier1Filters;
                        tier1attributeFilters = this.options.tier1attributeFilters;
                }

                this.productSearchFilterCategoryView = new productSearchFilterCategory.SearchCategoryView({model: productSearchFilterCategoryModel});
                this.rangeAndBrandsView = new RangeAndBrandsView(
                            {model: this.model,
                             rangeSpecified: rangeSpecified,
                             range: this.options.range,
                             brands: this.options.brands});
            },

            _selectRequiredCategories: function(productSearchFilterCategoryModel) {
                if (! this.options.category) {
                    return;
                }
                var categoryId = this.options.category + '';
                var categoryDetails = productSearchFilterCategoryModel.selectCategory(categoryId);
                if (categoryDetails === null) {
                    return;
                }

                this.tagCollection.changeCategory("category", categoryDetails);

                if (this.options.subCategory) {
                    var subCategoryDetails = productSearchFilterCategoryModel.selectSubCategory(categoryId, this.options.subCategory);
                    if (subCategoryDetails === null) {
                        return;
                    }
                    this.tagCollection.changeCategory("category", subCategoryDetails);
                }

                if (this.options.subSubCategory) {
                    var subSubCategoryDetails = productSearchFilterCategoryModel.selectSubSubCategory(
                        categoryId, this.options.subCategory, this.options.subSubCategory);
                    if (subSubCategoryDetails === null) {
                        return;
                    }
                    this.tagCollection.changeCategory("category", subSubCategoryDetails);
                }
            },
            
            _sliderMap: {
                0: 0,
                1: 10,
                2: 20,
                3: 30,
                4: 40,
                5: 60,
                6: 80,
                7: 100,
                8: 130,
                9: 160,
                10: 200,
                11: 250,
                12: 300,
                13: 400,
                14: 500,
                15: maxSliderDays
            },
            _maxSliderMapIndex: 15,

            reInitialize: function() {
                var rangeSpecified = this.model.get('rangeName') !== undefined;
                var productSearchFilterCategoryModel = new productSearchFilterCategory.SearchCategoryModel();
                if (rangeSpecified) {
                    productSearchFilterCategoryModel.set('rangeName', this.model.get('rangeName'));
                    productSearchFilterCategoryModel.set('mainGroups', productSearchFilterCategoryModel.get('rangeToMainGroupMap')[this.model.get('rangeName')]);
                }

                this.tagCollection = new productsSearchTags.TagCollection();
                this._selectRequiredCategories(productSearchFilterCategoryModel);

                // Do a search if filters specified
                if (this.options.range) {
                    this._restoreFilters = (this.options.range.length > 0) || this.options.category ||
                        (this.options.search.length > 0) || (this.options.brands.length > 0);
                }
                tier1Filters = [];
                tier1attributeFilters = [];

                this.productSearchFilterCategoryView = new productSearchFilterCategory.SearchCategoryView({model: productSearchFilterCategoryModel});
                this.rangeAndBrandsView = new RangeAndBrandsView(
                            {model: this.model,
                            rangeSpecified: rangeSpecified,
                            range: this.options.range,
                            brands: this.options.brands});
            },
            
            onRender: function() {

                try {
                    this.getRegion('category').show(this.productSearchFilterCategoryView);
                } catch (error) {
                    this.reInitialize();
                }

                this._setupSlider();

                this.tagView = new productsSearchTags.TagCollectionView({collection: this.tagCollection});
                this._handleTagRemoval(this.tagView);
                this.getRegion('filterTags').show(this.tagView);

                this._setupCategoryChanges();

                this.getRegion('category').show(this.productSearchFilterCategoryView);

                this._showSliderValues([0, maxSliderIndex]);
                
                // This bit is slow, let the screen update first
                var self = this;
                setTimeout(function() {
                    self.getRegion('range_brands').show(self.rangeAndBrandsView);
                    if (self._restoreFilters) {
                        $("#product-input-search").val(self.options.search);
                        self._restoreFilters = false;
                    }
                    self.searchForProducts();
                }, 1);

            },

            _productFilterChange: function() {
                this.listenTo(this.productSearchFilterCategoryView, "filter:changed", function(productFilter) {
                    this.searchForProducts();
                 });
            },

            _categoryChange: function(category, cat) {
                this.tagCollection.changeCategory(category, cat);
                this.searchForProducts();
            },
            _setupCategoryChanges: function() {
                var self = this;
                this.listenTo(this.productSearchFilterCategoryView, "category:changed", function(cat) {
                   self._categoryChange("category", cat);
                });
                this.listenTo(this.productSearchFilterCategoryView, "sub:category:changed", function(cat) {
                    self._categoryChange("sub-category", cat);
                });
                this.listenTo(this.productSearchFilterCategoryView, "sub:sub:category:changed", function(cat) {
                    self._categoryChange("sub-sub-category", cat);
                });
            },
            
            _handleTagRemoval: function(tagView) {
                var self = this;
                this.listenTo(tagView, "tag:removed", function(details) {

                    if (details.typeOfTag === "attributeTag") {
                        var index = tier1attributeFilters.indexOf(details.idOfType);
                        if (index !== -1) {
                            tier1attributeFilters.splice(index, 1);
                            this.tagCollection.removeTag(details.typeOfTag, details.idOfType);
                            $("." + format.cleanupAttributeTag(details.idOfType)).removeClass('activated').prop('checked', false);
                            $("." + format.cleanupAttributeTag(details.idOfType) + " .img_default").show()
                            $("." + format.cleanupAttributeTag(details.idOfType) + " .img_activated").hide()
                        }
                    } else if (details.typeOfTag === "productFilter") {
                        var index = tier1Filters.indexOf(details.idOfType);
                        if (index !== -1) {
                            tier1Filters.splice(index, 1);
                            this.tagCollection.removeTag(details.typeOfTag, details.idOfType);
                            $("." + format.cleanupAttributeTag(details.idOfType)).removeClass('activated').prop('checked', false);
                            $("." + format.cleanupAttributeTag(details.idOfType) + " .img_default").show()
                            $("." + format.cleanupAttributeTag(details.idOfType) + " .img_activated").hide()
                        }
                    } else if (details.typeOfTag === "shelfLife") {
                        self.resetSlider();
                    } else if (details.typeOfTag === "category") { 
                        var isCategoryTagRemoval = details.idOfType === 'category';
                        var isSubCategoryTagRemoval = details.idOfType === 'sub-category';
                        self.productSearchFilterCategoryView.reset(details.idOfType);
                        
                        if (isCategoryTagRemoval) {
                        	self.tagCollection.removeTag("category", "category");
                        	self.tagCollection.removeTag("category", "sub-category");
                        	self.tagCollection.removeTag("category", "sub-sub-category");
                        } else if (isSubCategoryTagRemoval) {
	                    	self.tagCollection.removeTag("category", "sub-category");
	                    	self.tagCollection.removeTag("category", "sub-sub-category");
                        } else {
	                    	self.tagCollection.removeTag("category", "sub-sub-category");
                        }
                    } else {
                        var checkbox = self.$('input[type="checkbox"]').filter('[name="' + details.typeOfTag +'"]').filter('[value="' + details.idOfType +'"]');
                        checkbox.prop('checked', false);
                        
                        var justGroupedRangesMap = this.model.get('justGroupedRangesMap');
                        if (justGroupedRangesMap[details.idOfType]){
                            _.each(justGroupedRangesMap[details.idOfType], function(name) {
                                self.$('input[type="checkbox"]').filter('[name="' + details.typeOfTag +'"]').filter('[value="' + name +'"]').prop('checked', false);
                            });
                        }
                    }
                    self.searchForProducts();
                });
            },
           _storeShelfLife: function(startIndex, endIndex) {
               this.model.set('selectedShelfLifeRangeIndex', [startIndex, endIndex]);
               this.model.set('selectedShelfLifeRange', [this._sliderMap[startIndex], this._sliderMap[endIndex]]);
           },
           _setupSlider: function() {
                var self = this;
                this._storeShelfLife(0, this._maxSliderMapIndex);
                 this.$('#filter_shelfLife_slider').slider({
                     range: true,
                     min: 0, 
                     max: maxSliderIndex,
                     step: 1,
                     values: [0, maxSliderIndex],
                     change: function(event, slider) {
                         self._storeShelfLife(slider.values[0], slider.values[1]);
                         var isMin = slider.values[0]  === 0;
                         var isMax = slider.values[1] === maxSliderIndex;
                         if (isMin && isMax) {
                             self.tagCollection.removeTag("shelfLife", "range");
                         } else {
                             var name = self._sliderMap[slider.values[0]] + "-" + (isMax ? ' +' : self._sliderMap[slider.values[1]]);
                             self.tagCollection.changeTag("shelfLife", name + " Shelf life", "range");
                         }
                         if (self._allowLoad) {
                             self.searchForProducts();
                         }
                     },
                     slide: function(event, slider) {
                         self._showSliderValues(slider.values);
                     }
                 });
            },
            
            showOrHideShelfLife: function() {
                toggleMenu('#shelf_life__up_down', '#shelf_life_panel', 400);
            },
            
            _allowLoad: true, 
            tagCollection: null,
           
           showSearchTitle: function(basket) {
               var titles = {
                    items: 'Search all',
                    wish: 'Search favourites',
                    previous: 'Search previous',
                    quote: 'Search this quote',
                    order: 'Search this order'
               };
               this.$('#search_filter_title').text(titles[basket]);
               selectedTab = basket;
           },
           
           resetSlider: function() {
                this._allowLoad = false;  // two events occur, don't want two loads
                $( "#filter_shelfLife_slider" ).slider( "option", "values", [ 0, maxSliderIndex] );
                this._allowLoad = true;
                this._showSliderValues([ 0, maxSliderIndex]);
           },
           
           resetFiltersAndSearch: function() { 
                $("#product-input-search").val('');
                this.resetFilters();
                this.searchForProducts();
                return false;
           },

           updateTier1FiltersAndSearch: function(ev) {
                var filter = $(ev.currentTarget).val().split(",")[0];
                var filterType = $(ev.currentTarget).val().split(",")[1];

                if (filterType === 'attributeTag' || !filterType) {
                    filterType = 'attributeTag'
                    var index = tier1attributeFilters.indexOf(filter);
                    if (index !== -1) {
                        $("." + format.cleanupAttributeTag(filter)).removeClass('activated').prop('checked', false);
                        $("." + format.cleanupAttributeTag(filter) + " .img_default").show()
                        $("." + format.cleanupAttributeTag(filter) + " .img_activated").hide()
                        tier1attributeFilters.splice(index, 1);
                        this.tagCollection.removeTag(filterType, filter);
                    } else {
                        $("." + format.cleanupAttributeTag(filter)).addClass('activated').prop('checked', true);
                        $("." + format.cleanupAttributeTag(filter) + " .img_default").hide()
                        $("." + format.cleanupAttributeTag(filter) + " .img_activated").show()
                        tier1attributeFilters.push(filter);
                        this.tagCollection.addTag(filterType, filter, filter);
                    }
                    this.options.tier1attributeFilters = tier1attributeFilters;
                }

                if (filterType === 'productFilter') {
                    var index = tier1Filters.indexOf(filter);
                    if (index !== -1) {
                        $("." + format.cleanupAttributeTag(filter)).removeClass('activated').prop('checked', false);
                        $("." + format.cleanupAttributeTag(filter) + " .img_default").show()
                        $("." + format.cleanupAttributeTag(filter) + " .img_activated").hide()
                        tier1Filters.splice(index, 1);
                        this.tagCollection.removeTag(filterType, filter);
                    } else {
                        $("." + format.cleanupAttributeTag(filter)).addClass('activated').prop('checked', true);
                        $("." + format.cleanupAttributeTag(filter) + " .img_default").hide()
                        $("." + format.cleanupAttributeTag(filter) + " .img_activated").show()
                        tier1Filters.push(filter);
                        this.tagCollection.addTag(filterType, filter, filter);
                    }
                    this.options.productFilters = tier1Filters;
                }

                this.searchForProducts();
            },

            resetFilters: function() {

                for (let index = 0; index < tier1Filters.length; index++) {
                    $("." + tier1Filters[index]).removeClass('activated');
                    $("." + tier1Filters[index] + " .img_default").show()
                    $("." + tier1Filters[index] + " .img_activated").hide()
                }
                tier1Filters = [];

                for (let index = 0; index < tier1attributeFilters.length; index++) {
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index])).removeClass('activated').prop('checked', false);
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index]) + " .img_default").show()
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index]) + " .img_activated").hide()
                    
                }
                tier1attributeFilters = [];
                
                this.tagCollection.reset();
                this.$('input[type="checkbox"]').prop('checked', false);
                this.productSearchFilterCategoryView.reset('category');
                
                this.model.set({
                    ranges: this.model.get('fullRanges'),
                    brandNames: this.model.get('fullBrandNames')
                });
                this.rangeAndBrandsView.render();
                this.resetSlider();
                Backbone.history.navigate('browse');
                return false;
            },
            
            _showSliderValues: function(slider) {
                this.$('#filter_shelfLife_min').text(this._sliderMap[slider[0]] + " days");
                var max = (slider[1] === maxSliderIndex) ? "+" : (this._sliderMap[slider[1]] + " days");
                this.$('#filter_shelfLife_max').text(max);
            },
            
            expandGroup: function(event) {
                var expander = $(event.target);
                var group = expander.next().attr('data-group');
                
                if (expander.text() === '+') {
                    expander.text('-');
                    this.$('.group_' + group).slideDown(400);
                } else {
                    expander.text('+');
                    this.$('.group_' + group).slideUp(400);
                }
            },

            selectGroupedRange: function(event) {
                var groupCheckbox = $(event.target);
                var group = groupCheckbox.attr('data-group');
                var checkboxes = this.$('.group_' + group + ' input');

                if (groupCheckbox.prop('checked')) {       
                    /* set all the grouped checkboxes. */
                    checkboxes.each(function() {
                        $(this).prop('checked', true);
                    }); 
                } else {
                    /* clear all the grouped checkboxes. */
                    checkboxes.each(function() {
                        $(this).prop('checked', false);
                    }); 
                }
                
                this.showTagsFromSelectedRanges();
                this.searchForProducts();
            },
            
            
            rangeCheckboxChanged: function(event) {
                var checkbox = $(event.target);
                var panel = this.$('#filter_range');
                var parent = CONFIG.range_groupings[checkbox.val()];
                if (checkbox.prop('checked')) {    
                    if (parent) {
                        var allSet = true;
                        _.each(this.model.get('justGroupedRangesMap')[parent], function(name) {
                            allSet = allSet && $('input[name="filter_range"][value="' + name + '"]', panel).prop('checked');
                        });
                        if (allSet) {
                            $('input[name="filter_range"][value="' + parent + '"]', panel).prop('checked', true);
                        }
                    }
                    
                } else {
                    this.tagCollection.removeTag('filter_range',  checkbox.val());
                    /* If has a parent then clear it. */
                    if (parent) {
                        $('input[name="filter_range"][value="' + parent + '"]', panel).prop('checked', false);
                    }
                }
                this.showTagsFromSelectedRanges();
                this.searchForProducts();
            },
            
            showTagsFromSelectedRanges: function() {
                var self = this;
                var panel = this.$('#filter_range');
                
                var groupsCount = {};
                var groupsMembers = {};
                var justGroupedRangesMap = this.model.get('justGroupedRangesMap');
                var groupedRangesMap = this.model.get('groupedRangesMap');
                _.each(justGroupedRangesMap, function(list, group) {
                    groupsCount[group] = 0;
                    groupsMembers[group] = [];
                });

                this.tagCollection.removeTagsOfType('filter_range');
                var tagList = [];
                $('input[name="filter_range"]:checked', panel).each(function() {
                    var name = $(this).val();
                    var parent = CONFIG.range_groupings[name];
                    if (parent) {
                        groupsCount[parent]++;
                        groupsMembers[parent].push(name);
                    } else {
                        tagList.push(name);
                    }
                });

                /* If all child members selected then just add parent. */
                _.each(groupsCount, function(list, name) {
                    if (groupsCount[name] === groupedRangesMap[name].length) { 
                        tagList.push(name);
                    } else {
                        _.each(groupsMembers[name], function(name) {
                            tagList.push(name);
                        });
                    }
                });
                
                /* Add all the ranges as tags.
                 */
                _.each(tagList, function(name) {
                    self.tagCollection.addTag('filter_range', staticModels.rangeTypesMap[name], name);
                });
            },
            
            brandsCheckboxChanged: function(event) {
                var checkbox = $(event.target);
                if (checkbox.prop('checked')) {
                    this.tagCollection.addTag('filter_brand', checkbox.val(), checkbox.val());
                } else {
                    this.tagCollection.removeTag('filter_brand',  checkbox.val());
                }
                this.searchForProducts();
            },

            /* Not all UI selections are noted when they occur.
             * This method stores all the settings into the model, so when the view re-renders
             * it will be displayed as expected.
             */
            _filterChanged: function() {
                var fixedBasket = this.model.get('rangeName') !== undefined;   

                /* Grab groups of ranges, whether expanded. */
                var expandedRangeGroups = [];
                $('input.is_parent_checkbox[name="filter_range"]').each(function() {
                    var name = $(this).attr('data-group');
                    var expanded = $(this).prev().text() === '-';
                    if (expanded) {
                        expandedRangeGroups.push(name);
                    }
                });
                
                // Set in the model the current filter state
                var selectors = {
                    selectedRange: fixedBasket ? [this.model.get('rangeName')] : readCheckboxes('#filter_range'),
                    selectedCategory: this.productSearchFilterCategoryView.getCategory(),
                    selectedCategoryId: this.productSearchFilterCategoryView.getCategoryId(),
                    selectedSubCategory: this.productSearchFilterCategoryView.getSubCategory(),
                    selectedSubCategoryId: this.productSearchFilterCategoryView.getSubCategoryId(),
                    selectedSubSubCategory: this.productSearchFilterCategoryView.getSubSubCategory(),
                    selectedSubSubCategoryId: this.productSearchFilterCategoryView.getSubSubCategoryId(),
                    selectedBrands: this.rangeAndBrandsView.readBrands(),
                    expandedRangeGroups : expandedRangeGroups
                };
                // shelf life is set when being changed.
                
                this.model.set(selectors);
            },
            
            searchForProducts: function(event) {
                this._filterChanged();
                var filters = this.model.pick(['selectedShelfLifeRange', 'selectedCategory', 'selectedCategoryId', 'selectedSubCategory', 'selectedSubCategoryId', 'selectedSubSubCategory', 'selectedSubSubCategoryId', 'selectedBrands']);
                
                /* Copy selectedRange, expanding range groups into the contained groups.*/
                var ranges = this.model.get('selectedRange');
                var groupedRangesMap = this.model.get('groupedRangesMap');
                filters.selectedRange = [];
                _.each(ranges, function(name) {
                    if (! _.isArray(groupedRangesMap[name])) {
                        filters.selectedRange.push(name);
                    }
                });

                filters.productFilters = tier1Filters;
                filters.attributeFilters = tier1attributeFilters;

                // Don't bother with shelf life if for full range.
                if ((filters.selectedShelfLifeRange[0] === 0) && (filters.selectedShelfLifeRange[1] === maxSliderDays)) {
                    delete filters.selectedShelfLifeRange;
                }

                var searchText = $("#product-input-search").val();
                productChannel.trigger("product:search", searchText, filters);

          /* Uncomment to enable History API.
                var urlData = this.model.pick(['selectedCategoryId', 'selectedSubCategoryId', 'selectedSubSubCategoryId']);
                urlData.searchText = searchText;
                urlData.ranges = ranges;
                urlData.brands = filters.selectedBrands;
                urlChannel.trigger('search', urlData); */
            },
            checkForEnter: function(event) {
                if (event.which === 13) {
                        this.searchForProducts();
                        $('#product-input-search').select();
                }
            },
            searchStarting: function() {
                this.$el.stop().animate({opacity: 0.5, backgroundColor: '#c0c0c0'}, 400);
            },
            searchComplete: function() {
                this.$el.stop().animate({opacity: 1.0, backgroundColor: '#ffffff'}, 400);
            },
            /* When the search has been completed, restore the UI state. */

            checkForFilter: function(filterString){
                for (let index = 0; index < FILTERSCONFIG.tierOneFilters.length; index++) {
                    const filter = FILTERSCONFIG.tierOneFilters[index];
                    if(filter.productFilter === filterString) {
                        return true;
                    }
                }
                return false;
            },

            filterUpdates: function(filters) {
                var self = this;

                /* If the server has passed back a reduced list of brands then use them, otherwise
                 * use the full list.
                 */

                if (selectedTab === 'wish' && this.checkForFilter('FAVOURITE')) {
                    var filter = 'FAVOURITE'
                    var filterType = 'productFilter'
                    var index = tier1Filters.indexOf(filter);
                    if (index == -1) {
                        $("." + format.cleanupAttributeTag(filter)).addClass('activated').prop('checked', true);
                        $("." + format.cleanupAttributeTag(filter) + " .img_default").hide()
                        $("." + format.cleanupAttributeTag(filter) + " .img_activated").show()
                        tier1Filters.push(filter);
                        this.tagCollection.addTag(filterType, filter, filter);
                    }
                }

                if ((filters.brandNames === null) || (filters.brandNames === undefined)) {
                    filters.brandNames = this.model.get('fullBrandNames');
                }
                if ((filters.ranges === null) || (filters.ranges === undefined) || (filters.ranges.length === 0)) {
                    filters.ranges = this.model.get('fullRanges');
                    filters.rangesWithGroups = this.model.get('rangesWithGroups');
                } else {
                    filters.rangesWithGroups = _.clone(filters.ranges);
                }
                
                this.model.set(filters);
                this.rangeAndBrandsView.render();
                this.tagCollection.reset();
                
                /* Tags and dropdowns for category and sub-category. 
                 */
                var selectedCategory = this.model.get('selectedCategory');
                if ((selectedCategory !== null) && (selectedCategory !== undefined)) {
                    self.tagCollection.addTag("category", selectedCategory, "category");
                }
                var selectedSubCategory = this.model.get('selectedSubCategory');
                if ((selectedSubCategory !== null) && (selectedSubCategory !== undefined)) {
                    self.tagCollection.addTag("category", selectedSubCategory, "sub-category");
                }
                var selectedSubSubCategory = this.model.get('selectedSubSubCategory');
                if ((selectedSubSubCategory !== null) && (selectedSubSubCategory !== undefined)) {
                    self.tagCollection.addTag("category", selectedSubSubCategory, "sub-sub-category");
                }

                /* reset tier1Filters
                 */
                for (let index = 0; index < tier1Filters.length; index++) {
                    $("." + format.cleanupAttributeTag(tier1Filters[index])).addClass('activated');
                    $("." + format.cleanupAttributeTag(tier1Filters[index]) + " .img_default").hide()
                    $("." + format.cleanupAttributeTag(tier1Filters[index]) + " .img_activated").show()
                    this.tagCollection.addTag("productFilter", tier1Filters[index], tier1Filters[index]);  
                }

                for (let index = 0; index < tier1attributeFilters.length; index++) {
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index])).addClass('activated').prop('checked', true);
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index]) + " .img_default").hide()
                    $("." + format.cleanupAttributeTag(tier1attributeFilters[index]) + " .img_activated").show()
                    this.tagCollection.addTag("attributeTag", tier1attributeFilters[index], tier1attributeFilters[index]);
                }

                /* Tags and checkboxes for brands. 
                 */
                this._checkAndTag('selectedBrands', 'filter_brand', filters.brandNames, true);
                
                /* 'rangeName' is set if searching within a range (ie AMBIENT).
                   The range filter only exists if 'rangeName' is undefined.
                 */
                if (this.model.get('rangeName') === undefined) {
                    this._checkAndTag('selectedRange', 'filter_range', filters.rangesWithGroups, false, staticModels.rangeTypesMap);
                    this.showTagsFromSelectedRanges();
                }
                
                /* Expand any open range groups. */
                _.each(this.model.get('expandedRangeGroups'), function(group) {
                    var input = $('input.is_parent_checkbox[name="filter_range"][data-group="' + group + '"');
                    input.prev().text('-');
                    self.$('.group_' + group).slideDown();
                });                    
                
                /* Restore the slider. */
                var selectedShelfLifeRangeIndex = this.model.get('selectedShelfLifeRangeIndex');
                this._allowLoad = false;
                this.$('#filter_shelfLife_slider').slider("values", [selectedShelfLifeRangeIndex[0], selectedShelfLifeRangeIndex[1]]);
                this._allowLoad = true;
            },
            
            /* Items (checkboxes) were selected before the AJAX call, new items have been returned.
             * Ensure that any of the returned items that were previously selected are re-selected and a tag item created.
             * */
            _checkAndTag: function(modelName, typeName, entries, doTag, nameMapper) {
                var self = this;
                var listToCheck = this.model.get(modelName);
                if (listToCheck.length === 0) {
                    return;
                }
                var panel = this.$('#' + typeName);
                if ((entries !== null) && (entries !== undefined)) {
                    _.each(listToCheck, function(name) {
                        $('input[name="' + typeName + '"][value="' + name + '"]', panel).prop('checked', true);
                        if (doTag) {
                            var uiName = name;
                            if (nameMapper) {
                                uiName = nameMapper[uiName];
                            }
                            self.tagCollection.addTag(typeName, uiName, name);
                        }
                    });
                }
            }
            /**/
    });

    return {
        SearchFilterView: SearchFilterView,
        SearchFilterModel: SearchFilterModel
    };

});

