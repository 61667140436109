define('templating',[
    'backbone', 'marionette', 'templates', 'format', 'handlebars', 'staticModels'
],
function(Backbone, Marionette, templates, format, handlebars, staticModels) {

    'use strict';

    var rendering = function() {
        /* Replace the renderer to use our handlebars templates */
        Marionette.Renderer.render = function(template, data){
            if (!templates[template]) {
                if (console) {
                    console.log("Missing template " + template);
                }
                throw 'Template "' + template + '" not found!';
            }
            return templates[template](data);
        };

        /* 
         * Helper functions for use in templates.
         */
        handlebars.registerHelper('fixedDecimal', function(value, decimalPlaces) {
            return format.fixedDecimal(value, decimalPlaces);
        });

        handlebars.registerHelper('defaultZero', function(value) {
            return ((value === undefined) || (value === null)) ? '0' : value;
        });
        
        handlebars.registerHelper('epochToDate', function(epoch) {
            var epochDate = new Date(epoch);
            return epochDate.getDate() + "/" + (epochDate.getMonth() + 1) + "/" + epochDate.getFullYear();
        });
        
        handlebars.registerHelper('date', function(date) {
            return format.isoToUkDate(date);
        });
        
        /* Handles 2016-06-07 13:21:01, displaying as 13:21:01 07/06/2016
         */
        handlebars.registerHelper('datetime', function(date) {
            if ((date === undefined) || (date === null) || (date.length !== 19)) {
                return '';
            }
            return date.substr(11, 8) + ' ' + format.isoToUkDate(date.substr(0, 10));
        });
        
        // date is in format "2016-04-19"
        handlebars.registerHelper('daysRemaining', function(date) {
            if ((date === null) || (date === undefined)) {
                return "";
            }
            var when = new Date(date);
            var now = new Date();
            now = new Date(now.getFullYear(), now.getMonth(), now.getDate());     
            var milliDiff = when.getTime() - now.getTime();
            var days = Math.round(milliDiff / (1000 * 60 * 60 *24)); 
            return days;
        });

        
        handlebars.registerHelper('pricingPeriod', function(id) {
            return id.substring(id.lastIndexOf('-') + 1);
        });
        
        handlebars.registerHelper('currency', function(value) {
            return new handlebars.SafeString(format.currency(value));
        });
        
        handlebars.registerHelper('vas', function(value) {
            var vasStyle = "";
            if (value.indexOf("*") === 0) vasStyle = "vas";
            return vasStyle;
        });
        
        handlebars.registerHelper('camelCaseValue', function(value) {
            var rangeString = "";
            var rangeWordsArray = value.split("_");
            for (var i = 0; i < rangeWordsArray.length; i++) {
                var lowercaseWord = rangeWordsArray[i].toLowerCase();
                var capitalisedWord = lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
                rangeString += " " + capitalisedWord;
            }
            return rangeString.trim();   
        });
        
        handlebars.registerHelper('fixedCurrency', function(value) {
            return new handlebars.SafeString(format.fixedCurrency(value));
        });

        handlebars.registerHelper('cleanupAttributeTag', function(value) {
            return new handlebars.SafeString(format.cleanupAttributeTag(value));
        });

        handlebars.registerHelper("ifCond",function(v1,operator,v2,options) {
            switch (operator)
            {
                case "==":
                    return (v1==v2)?options.fn(this):options.inverse(this);
        
                case "!=":
                    return (v1!=v2)?options.fn(this):options.inverse(this);
        
                case "===":
                    return (v1===v2)?options.fn(this):options.inverse(this);
        
                case "!==":
                    return (v1!==v2)?options.fn(this):options.inverse(this);
        
                case "&&":
                    return (v1&&v2)?options.fn(this):options.inverse(this);
        
                case "||":
                    return (v1||v2)?options.fn(this):options.inverse(this);
        
                case "<":
                    return (v1<v2)?options.fn(this):options.inverse(this);
        
                case "<=":
                    return (v1<=v2)?options.fn(this):options.inverse(this);
        
                case ">":
                    return (v1>v2)?options.fn(this):options.inverse(this);
        
                case ">=":
                 return (v1>=v2)?options.fn(this):options.inverse(this);
        
                default:
                    return eval(""+v1+operator+v2)?options.fn(this):options.inverse(this);
            }
        });
        
        /* Generate a select dropdown.*/
        handlebars.registerHelper('selectMenu', function(id, list, classes, selectedId) {
            var html = '<select id="' + id + '" class="' + classes + '">';
            if ((list !== undefined) && (list !== null)) {
                $.each(list, function() {
                   var selected = (this.groupId === selectedId) ? ' selected="selected"': '';
                   html += '<option ' + selected + 'value="' + this.groupId + '">' + this.groupDescription + '</option>';
                });
            }
            return new handlebars.SafeString(html + '</select>');
        });
        
        handlebars.registerHelper('sectioned_checkboxes', function(name, list) {
            var html = '';
            var id, idPrefix;
            if ((list !== undefined) && (list !== null)) {
                idPrefix = 'id="' + name + '_';
                $.each(list, function() {
                    id = (this.newSection) ? idPrefix + this.letter + '"' : '';
                    html += '<div><input ' + id + ' type="checkbox" class="is_checkbox" name="' + name + '" value="' + this.name + '">' + this.name + '</div>';
                });
            }
            return new handlebars.SafeString(html);
        });
        
        handlebars.registerHelper('checkboxes', function(name, list) {
            var html = '';
            if ((list !== undefined) && (list !== null)) {
                $.each(list, function() {
                    html += '<div><input type="checkbox" class="is_checkbox" name="' + name + '" value="' + this + '">' + this + '</div>';
                });
            }
            return new handlebars.SafeString(html);
        });

        handlebars.registerHelper('showShelfLife', function(shelfLife) {
            var html = '';

            if (shelfLife !== 0) {
                html += '<td class="products_search_col7"><div class="cell">' + shelfLife + ' day(s)</div></td>';
            } else {
               html += '<td class="products_search_col7"><div class="cell">' +
                            '<button class="btn btn-default btn-sm btn-popover" data-tooltip-type="shelf-life" data-trigger="focus" title="Info" data-container="body" data-placement="left" data-toggle="tooltip"' +
                            'data-content="Available upon request. Please speak to your EAA for more information.">' +
                                '<span class="glyphicon glyphicon-info-sign"></span>' +
                            '</button>' +
                        '</div></td>';
            }

            return new handlebars.SafeString(html);
        });
        
        handlebars.registerHelper('cloudfrontDomain', function() {
            return CONFIG.cloudFrontDomain; 
        });        
        
        function camelCaseEnum(enumValue) {
            var rangeString = "";
            var rangeWordsArray = enumValue.split("_");
            for (var i = 0; i < rangeWordsArray.length; i++) {
                var lowercaseWord = rangeWordsArray[i].toLowerCase();
                var capitalisedWord = lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
                rangeString += " " + capitalisedWord;
            }
            return rangeString.trim();  
        }
        
   /*     handlebars.registerHelper('camelCaseCheckboxes', function(name, list) {
            var html = '';
            if ((list !== undefined) && (list !== null)) {
                $.each(list, function() {
                    var rangeString = camelCaseEnum(this);   
                    html += '<div><input type="checkbox" class="is_checkbox" name="' + name + '" value="' + this + '">' + rangeString + '</div>';
                });
            }
            return new handlebars.SafeString(html);
        });*/
        
        handlebars.registerHelper('showRsp', function(rsp) {
            var html = '';
            if (rsp > 0) {
                if (rsp < 1) {
                    html += ' ' + (rsp * 100).toFixed(0) + 'p';
                } else {
                    html += ' £' + rsp.toFixed(2);
                }
            }
            return new handlebars.SafeString(html);
        });
        
        handlebars.registerHelper('range', function(range) {
            return staticModels.rangeTypesMap[range];
        });
        
        handlebars.registerHelper('ranges', function(list, rangesWhiteMap) {
            var html = '';
            var groupTemplateStart = _.template(
               '<div>' +
                 '<div class="expand_checkbox_group">+</div>' +
                 '<input type="checkbox" class="is_parent_checkbox" data-group="<%=key%>" name="filter_range" value="<%=key%>">' +
                 '<%=rangeString%>' +
               '</div>' +
               '<div class="nested_checkbox <%=classGroup%>">');
               
            var nestedCheckTemplate = _.template(
                '<div>' +
                  '<input type="checkbox" class="is_checkbox" name="filter_range" value="<%=value%>"><%=subRangeString%>' +
                '</div>');
            var checkTemplate = _.template(
                '<div>' +
                  '<div class="no_expand_checkbox_group"></div>' +
                  '<input type="checkbox" class="is_checkbox" name="filter_range" value="<%=value%>"><%=rangeString%>' +
                '</div>'
            );
            
            if ((list !== undefined) && (list !== null)) {
                $.each(list, function() {
                    var rangeString = staticModels.rangeTypesMap[this.key];
                    if (_.isArray(this.value)) {
                        var classGroup = 'group_' + this.key;

                        var subHtml = '';
                        $.each(this.value, function() {
                            if (rangesWhiteMap[this]) {
                                var subRangeString = staticModels.rangeTypesMap[this];
                                subHtml += nestedCheckTemplate({value: this, subRangeString: subRangeString});
                            }
                        });
                        if (subHtml !== '') {
                            html += groupTemplateStart({key: this.key, rangeString: rangeString, classGroup: classGroup});
                            html += subHtml + '</div>';
                        }
                    } else {
                        if (rangesWhiteMap[this.value]) {
                            html += checkTemplate({value: this.value, rangeString: rangeString});
                        }
                    }
                });
            }
            return new handlebars.SafeString(html);
        });

        handlebars.registerHelper('shouldDisplayOpenSection', function(hasOpenQuotes, status, options) {

            if ((hasOpenQuotes && status === 'OPEN') || (!hasOpenQuotes && status === 'EXPIRED')
                || (!hasOpenQuotes && status === 'REOPENED')) {
                return options.fn(this);
            } else {
                return options.inverse(this);
            }
        });

        handlebars.registerHelper('isOrderInvoiced', function(status, options) {

            if (status === 'INVOICED') {
                return options.fn(this);
            } else {
                return options.inverse(this);
            }
        });
    };
    return rendering;
});

