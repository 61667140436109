define('ordersModel',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer'
],
function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer) {

    var customerChannel = BackboneRadio.channel('customerChannel');
    var OrdersModel = Backbone.Model.extend({
        cidPrefix: 'OrdersModel_',
        createNew: function(orderDetails) {
            this.url = CONFIG.rocs_url + "/quote-service/order/create";
            var options = {
                type : 'POST',
                contentType : 'application/json',
                dataType: 'json',
                data: JSON.stringify(orderDetails)
            };
            return Authentication.refreshAndFetch(this, options);
        },
        
        reload: function(a) {
            return this.load(this.get('shipmentId'), true);
        },

        reloadSynchronously: function(a) {
            return this.load(this.get('shipmentId'), false);
        },
        
        // Ensure quotes are ordered
        parse: function(data) {
            for (let index = 0; index < data.orders.length; index++) {
                data.orders[index].quoteId = data.orders[index].orderId;
            }

            data.quotes = data.quotes.concat(data.orders);

            data.quotes.sort(function(a, b) {
                return a.quoteId.localeCompare(b.quoteId);
            });

            return data;
        },
        
        load:  function(shipmentId, async) {
            async = async == null ? true : async;
            this.url = CONFIG.rocs_url + '/quote-service/order/get/' + shipmentId;
            var options = {
                cache : false,
                error: this.getOrderErrorHandler,
                async: async
            };
            Authentication.refreshAndFetch(this, options);
        },

        getOrderErrorHandler: function(query, data){
            if (data.status === 404 && data.responseJSON.errorCode === "802") {
                customerChannel.trigger("customer:displayShipments");
            }
        },
        changeQuantity: function(op, url) {
            return Authentication.makeAjaxCall({
                cache : false,
                type : op,
                url : CONFIG.rocs_url +  url,
                data : {},
                success : function() {
                    CurrentCustomer.updatingQuotes = false;
                }
            });
        },

        decrementProduct: function(quoteId, productCode, quantity) {
           var url = "/quote-service/quote/subtract/" + quoteId + "/" + productCode +"/"  + quantity;
           return this.changeQuantity('POST', url);
        },
        incrementProduct: function(quoteId, productCode, quantity) {
            var url = "/quote-service/quote/add/" + quoteId + "/" + productCode +"/"  + quantity;
            return this.changeQuantity('POST', url);
        },
        setProduct: function(quoteId, productCode, quantity) {
            var url = "/quote-service/quote/set/" + quoteId + "/" + productCode +"/"  + quantity;
            return this.changeQuantity('POST', url);
        },
        deleteProduct: function(quoteId, productCode) {
            var url = "/quote-service/quote/delete/" + quoteId + "/" + productCode;
            return this.changeQuantity('DELETE', url);
        },
    });
    return OrdersModel;
});

