define('authentication',['backboneradio', 'keycloak'], 
function (BackboneRadio, keycloak) {
    var alertChannel = BackboneRadio.channel('alertChannel');
            
    var authentication = {

        getAccessToken: function() {
            return keycloak.token;
        },
        removeAccessToken: function() {
            keycloak.logout();
        },

        isAdminOrCSA: function(){
            var authorities = keycloak.userInfo.authorities;
            return authorities.includes("ROLE_CSA") || authorities.includes("ROLE_ADMIN");
        },

        hasExportPermission: function(){
            return keycloak.userInfo.exportProducts;
        },
        canOrderBannedProducts: function(){
            return keycloak.userInfo.orderBannedProducts;
        },
        canViewProductDetail: function(){
            return keycloak.userInfo.productDetail;
        },
        canViewPromotionalPricing: function(){
            return keycloak.userInfo.promotion;
        },
        getAttributes: function(){
            return keycloak.userInfo;
        },        
        authenticate: function() {
            keycloak.login();
        },
        refreshAndFetch: function(obj, opts){
            var self = this;
            var promise = this.createPromise();
            keycloak.updateToken(30).success(function() {
                opts.beforeSend = self.sendAuthentication;
                obj.fetch(opts).success(promise.setSuccess).error(promise.setError);
            }).error(function() {
                alert('Your Session has expired');
                keycloak.logout();
                keycloak.login();
            });
            return promise.promise;
        },
        makeAjaxCall : function(opts){
            var self = this;
            var promise = this.createPromise();
            keycloak.updateToken(30).success(function() {
                opts.beforeSend = self.sendAuthentication;
                $.ajax(opts).success(promise.setSuccess).error(promise.setError);
            }).error(function() {
                alert('Your Session has expired');
                keycloak.logout();
                keycloak.login();
            });
            return promise.promise;
        },
        sendAuthentication: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + authentication.getAccessToken());
    	},

    	logout: function(){
            keycloak.logout();
    	},
    	
        /* Invoke from the options hash in a backbone call. */
    	checkErrors: function(data, query) {
            if ((query.status === 0 && query.statusText !== "abort") || (query.status === 401)) {
                alert('session expired, please sign in again.');
                authentication.removeAccessToken();
                authentication.authenticate();
            } else {
                alertChannel.trigger('error', query);
            }
        },
        /* Invoke from a jQuery AJAX call. */
        checkAjaxErrors: function(query) {
            authentication.checkErrors({}, query);
        },

        // createPromise function taken from Keycloak
        createPromise : function() {
            var p = {
                setSuccess: function(result) {
                    p.success = true;
                    p.result = result;
                    if (p.successCallback) {
                        p.successCallback(result);
                    }
                },

                setError: function(result) {
                    p.error = true;
                    p.result = result;
                    if (p.errorCallback) {
                        p.errorCallback(result);
                    }
                },

                promise: {
                    success: function(callback) {
                        if (p.success) {
                            callback(p.result);
                        } else if (!p.error) {
                            p.successCallback = callback;
                        }
                        return p.promise;
                    },
                    error: function(callback) {
                        if (p.error) {
                            callback(p.result);
                        } else if (!p.success) {
                            p.errorCallback = callback;
                        }
                        return p.promise;
                    },
                    getResponse: function() {
                        return p.result;
                    }
                }
            }
            return p;
        },
        // Return a promise that is successful when all the promises passed in are successful
        awaitPromises: function(promises) {
            var promisesRemaining = promises.length;
            var promise = this.createPromise();
            var promiseCompleted = function() {
                promisesRemaining--;
                if (promisesRemaining === 0) {
                    promise.setSuccess({});
                }
            };
            var promiseError = function(error) {
                promise.setError(error);
            };

            var i;
            for(i = 0; i < promises.length; i++) {
                promises[i].success(promiseCompleted).error(promiseError);
            }

            return promise.promise;
        }
     };

    return authentication;
});

