define('quoteListView',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer', 'format', 'notificationView', 'quoteDetailsModal', 'keycloak'
],
function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer, format, NotificationView, QuoteDetailsModal, keycloak) {
	
    var quoteChannel = BackboneRadio.channel('quoteChannel');
    var headerChannel = BackboneRadio.channel('headerChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');
    var promptChannel = BackboneRadio.channel('promptChannel');
	var orderChannel = BackboneRadio.channel('orderChannel');
        
    var Order = Backbone.Model.extend({
        parse: function(response, options) {
            if (response.quotes === null) {
                response.quotes = [];
            }
            
            response.quotes.sort(function(a, b) {
                return a.quoteId.localeCompare(b.quoteId);
            });
            
            for( var i = 0; i < response.quotes.length; i++) {
                var quote = response.quotes[i];
                quote.isOpen = quote.quoteStatus === 'OPEN' || quote.quoteStatus === 'REOPENED';
                quote.isExpired = quote.quoteStatus === 'EXPIRED';
                quote.isSubmitted = quote.quoteStatus === 'SUBMITTED';
                quote.isConfirmed = quote.quoteStatus === 'CONFIRMED';
                quote.canDeleteQuote = (quote.quoteStatus === 'OPEN' || quote.isExpired) && !quote.synced;
                quote.isSubmittedOrConfirmed = quote.isSubmitted || quote.isConfirmed;
                quote.isReadyForConfirmation = quote.quoteStatus === 'READY_FOR_CONFIRMATION';
            }
            return response;
        }
    });
    
    var OrderList = Backbone.Collection.extend({
        model: Order,
        comparator: function(order) {
            var shipmentId = order.get("shipmentId");
            var lastHyphen = shipmentId.lastIndexOf('-');

            return parseInt(shipmentId.substring(lastHyphen+1, shipmentId.length));
        },
        load: function() {
            this.url = CONFIG.rocs_url + "/quote-service/order/get/customer/" + CurrentCustomer.code;
            Authentication.refreshAndFetch(this, {
                cache : false,
                contentType : 'application/json',
                error : Authentication.checkErrors
            }); 
        },
        addShipment: function(shipment) { // Order is synonym for shipment.
            var order = new Order();
            order.set(order.parse(shipment));
            this.add(order);
        }
    });
	
    var orderCollection = new OrderList();
    
    var OrderItemView = Marionette.ItemView.extend({
        className: 'shipment-row',
        modelEvents: {
            'change': '_redrawExpanded'
        },
        events: {
            "click .quote-details-button": "toggleQuoteDetails",
            "click .shipment-details-link": "shipmentSelected",
            "click .shipment-details-quote": "quoteSelected",
            "click .review_submit_button": "quoteSelected",
            "click .review_button": "quoteSelected",
            "click .order_review_button": "orderSelected",
            "click .review_accept_button": "quoteSelected",
            "click .remove-link": "deleteQuote",
            "mouseenter #ttMyShipRef" : "showPopoverMyShipRef",
            "mouseleave #ttMyShipRef" : "hidePopoverMyShipRef" ,
            "mouseenter #ttMyShipments" : "showPopoverMyShipments",
            "mouseleave #ttMyShipments" : "hidePopoverMyShipments" 
        },
        template: "templates/quotes/quote-list-item.hbs",
        templateHelpers: function() {
            var msgs = [];
            var documentStatuses = {
                OPEN: [],
                REOPENED: [],
                SUBMITTED: [],
                READY_FOR_CONFIRMATION: [],
                CONFIRMED: [],
                ORDERS: [],
                EXPIRED: []
            };
            var quotes = this.model.get('quotes');
            for( var i = 0; i < quotes.length; i++) {
                var quote = quotes[i];
                documentStatuses[quote.quoteStatus].push(quote);
            }

            var orders = this.model.get('orders');
            if (orders) {
                for (let index = 0; index < orders.length; index++) {
                    documentStatuses.ORDERS.push(orders[index]);
                }
            }

            this._countMsg(msgs, documentStatuses.OPEN, " open");
            this._countMsg(msgs, documentStatuses.SUBMITTED, " submitted");
            this._countMsg(msgs, documentStatuses.READY_FOR_CONFIRMATION, " ready for confirmation");
            this._countMsg(msgs, documentStatuses.CONFIRMED, " confirmed");
            this._countMsg(msgs, documentStatuses.ORDERS, " orders");

            var pluralText = quotes.length == 1 ? "" : "s";
            var data = {
				countQuotesMessage: quotes.length + ' quote' + pluralText,
				orderCountMessage: orders.length + ' order' + (orders.length == 1 ? "" : "s"),
				totalOpen: documentStatuses.OPEN.length + documentStatuses.EXPIRED.length + documentStatuses.REOPENED.length,
				totalSubmitted: documentStatuses.SUBMITTED.length,
				totalToConfirm: documentStatuses.READY_FOR_CONFIRMATION.length,
				totalConfirmed: documentStatuses.CONFIRMED.length,
				totalOrders: documentStatuses.ORDERS.length,
                hasOpenQuotes: documentStatuses.OPEN.length > 0,
                hasOrders: documentStatuses.ORDERS.length > 0,
                quoteSections: [],
                ordersSections: []
            };
            
            this._pushSection(data.quoteSections, documentStatuses.OPEN, 'OPEN');
            this._pushSection(data.quoteSections, documentStatuses.EXPIRED, 'EXPIRED');
            this._pushSection(data.quoteSections, documentStatuses.REOPENED, 'REOPENED');
            this._pushSection(data.quoteSections, documentStatuses.SUBMITTED, 'SUBMITTED');
            this._pushSection(data.quoteSections, documentStatuses.READY_FOR_CONFIRMATION, 'FORCONFIRMATION');
            this._pushSection(data.quoteSections, documentStatuses.CONFIRMED, 'CONFIRMED');
            this._pushSection(data.ordersSections, documentStatuses.ORDERS, 'ORDERED');

            return data;
        },
        
        _redrawExpanded: function() {
            var scrollTop = this.$el.closest('.scroller').scrollTop();
            this.render();
            this.$('.quote-details-panel').show();
            this.$('.quote-details-button .glyphicon').removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
            this.$el.closest('.scroller').scrollTop(scrollTop);
        },        
        _pushSection: function(documentSections, documents, status) {
            if (documents.length > 0) {
                documentSections.push({
                    status: status,
                    isOpen: status === 'OPEN',
                    isReopened: status === 'REOPENED',
                    isExpired: status === 'EXPIRED',
                    isSubmitted: status === 'SUBMITTED',
                    isReadyToConfirm: status === 'FORCONFIRMATION',
                    isConfirmed: status === 'CONFIRMED',
                    isOrdered: status === 'ORDERED',
                    totalDocuments: documents.length,
                    totalQuotes: documents.length,
                    quotes: documents,
                    orders: documents
                });
            }
        },
        showPopoverMyShipments: function() {
			$('#ttMyShipments').popover('show');
		},
		hidePopoverMyShipments: function() {
			$('#ttMyShipments').popover('hide');
		},
		showPopoverMyShipRef: function() {
			this.$('#ttMyShipRef').popover('show');
		},
		hidePopoverMyShipRef: function() {
			this.$('#ttMyShipRef').popover('hide');
		},
        
        _countMsg: function(msgs, array, msg) {
            if (array.length > 0) {
                msgs.push(array.length + msg);
                return true;
            }
            return false;
        },
        showPopover: function() {
			$('#ttConfirmModal').popover('show');
		},
		hidePopover: function() {
			$('#ttConfirmModal').popover('hide');
		},
        
        toggleQuoteDetails: function() {
            var self = this;
            var doShow = this.$('.quote-details-panel:visible').length === 0;
            if (doShow) {
                this.$('.quote-details-panel').slideDown(300, function() {
                        self.$('.quote-details-button .glyphicon').removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
                    });
            } else {
                this.$('.quote-details-panel').slideUp(300, function() {
                        self.$('.quote-details-button .glyphicon').removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
                    });
            }
            return false;
        },
        shipmentSelected: function() {
            basketChannel.trigger('showBasket', this.model.get("shipmentId"));
            return false;
        },

        orderSelected: function(event) {
            var orderId = $(event.target).attr('data-order-id');
            basketChannel.trigger('showBasket:order', this.model.get("shipmentId"), orderId);
            return false;
        },

        quoteSelected: function(event) {
            var quoteId = $(event.target).attr('data-quote-id');
            basketChannel.trigger('showBasket', this.model.get("shipmentId"), quoteId);
            return false;
        },
        deleteQuote: function(event) {
            var quoteId = this.$(event.currentTarget).attr('data-quote-id');
            var quotes = this.model.get('quotes');
            var quote = _.find(quotes, function(elem) { return elem.quoteId === quoteId; } );
            var QuoteModel = Backbone.Model.extend({});
            var quoteModel = new QuoteModel(quote);
            var modal = new QuoteDetailsModal({model: quoteModel, forDelete: true});
            promptChannel.trigger('show:modal', modal);
        }
    });
    
    var EmptyQuotesView = Marionette.ItemView.extend({
        template: "templates/quotes/no-quotes.hbs",
    });

    var QuoteListView = Marionette.CollectionView.extend({
        collection: orderCollection,
        childView: OrderItemView,
        collectionEvents: {
            'sync': 'collectionLoaded'
        },
        emptyView: EmptyQuotesView,
        isEmpty: function(collection) {
            return (this.collection.loaded && this.collection.length === 0);
        },
        onShow: function() {
       
            this.collection.loaded = false;
            this.collection.load();
            headerChannel.trigger('empty');
            headerChannel.trigger('show:menu', 'myShipments');
        },
        collectionLoaded: function() {
            this.collection.loaded = true;
            this.render();
            this.$el.closest('.scroller').scrollTop(0);
            quoteChannel.trigger('shipments:loaded', this.collection);
        },
        initialize: function() {
            var self = this;
            this.listenTo(quoteChannel, "add:shipment", function(shipment) {
                self.collection.addShipment(shipment);
            });
            this.listenTo(orderChannel, "reload", function() {
                self.collection.load();
            });
            this.listenTo(orderChannel, "reload:delete", function(quoteId, shipmentId) {
                /* Remove the quote, if this is the only quote in the
                   order then remove the order.*/
                var order = self.collection.findWhere({shipmentId: shipmentId});
                var quotes = order.get('quotes');
                if (quotes.length === 1) {
                    self.collection.remove(order);
                } else {
                    var deletedQuoteIndex = _.findIndex(quotes, function(elem) { return elem.quoteId === quoteId; } );
                    var deletedQuote = quotes[deletedQuoteIndex];
                    quotes.splice(deletedQuoteIndex, 1);
                    var totalCases = order.get('totalCases') - deletedQuote.basket.basketTotalCases;
                    var totalPrice = order.get('totalPrice') - deletedQuote.basket.basketTotalPrice;
                    
                    /* Note: 'updated' is used to ensure that the model change event is fired 
                       even if the totalCases and totalPrice haven't changed.
                     */
                    order.set({'totalCases': totalCases, 'totalPrice': totalPrice, 'updated' : Date.now()});
                }
            });
        }
    });

    var QuoteLayout = Marionette.LayoutView.extend({
        tagName: "div",
        className: "container-height ",
        template: "templates/quotes/quote-list.hbs",
        regions: {
            notificationsRegion: '#notifications_region',
            quoteListRegion: '#quote_lists_region'
        },
        events: {
            "mouseenter #ttMyShipments" : "showPopoverMyShipments",
            "mouseleave #ttMyShipments" : "hidePopoverMyShipments" ,
        },
        showPopoverMyShipments: function() {
			this.$('#ttMyShipments').popover('show');
		},
		hidePopoverMyShipments: function() {
			this.$('#ttMyShipments').popover('hide');
		},

        onShow: function() {
			var view = this;
			var textDiv = $("#quote_name");
			textDiv.append("<b><h2> Welcome, " + keycloak.userInfo.first_name +"</h2></b>");
			var quoteListView = new QuoteListView();
			var notificationView = NotificationView.create();
			view.getRegion('quoteListRegion').show(quoteListView);
			view.getRegion('notificationsRegion').show(notificationView);
        }
    });
   return QuoteLayout;
   });
