define('orderHistoryLayoutView',['backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer', 'scrollBehaviour'],

function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer, ScrollBehaviour) {

    var headerChannel = BackboneRadio.channel('headerChannel');
    var ordersChannel = BackboneRadio.channel('orderChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');

    var OrderHistoryList = Backbone.Collection.extend({
        parse: function(data) {
            this.totalHits = data.totalHits;

            return data.orders;
        }
    });

    var orderHistoryList = new OrderHistoryList();

    var OrderHistoryEmptyItemView = Marionette.ItemView.extend({
        tagName: "tr",
        className: "order-history-empty-row",
        template: "templates/orders/order-history-no-orders.hbs",

        events: {
            "click": "orderSelected"
        }
    });

    var OrderHistoryItemView = Marionette.ItemView.extend({
        tagName: "tr",
        className: "order-history-row",
        template: "templates/orders/order-history-order.hbs",

        events: {
            "click": "orderSelected"
        },

        orderSelected: function() {

            var orderId = this.model.get('orderId');
            basketChannel.trigger('showBasket:order', this.model.get('shipmentId'), orderId);
            return false;
        }
    });

    var OrderHistoryListView = Marionette.CompositeView.extend({

        collection: orderHistoryList,
        tagName: 'table',
        className: 'table table-hover',
        template: "templates/orders/order-history-list.hbs",
        childView: OrderHistoryItemView,
        emptyView: OrderHistoryEmptyItemView,
        childViewContainer: "tbody",

        initialize: function() {

            this.pageNumber = 1;
            this.collection.reset();

            this.loadOrderHistory();
            this.listenTo(ordersChannel, "nextpage", this.nextPage);
        },

        nextPage: function() {
            this.pageNumber += 1;
            this.loadOrderHistory();
        },

        loadOrderHistory: function() {

            var self = this;
            this.currentScrollTop = this.$el.closest('.scroller').scrollTop();

            var requestData = {
                noOfResults: 10,
                pageNumber: this.pageNumber,
                customerCode: CurrentCustomer.code
            };

            this.collection.url = CONFIG.rocs_url + "/orders-service/orders/v1/orders/search";

            Authentication.refreshAndFetch(this.collection, {
                cache : false,
                type: 'POST',
                remove: false,
                add: true,
                data: JSON.stringify({customerCode:CurrentCustomer.code,noOfResults:50,pageNumber:this.pageNumber}),
                contentType: "application/json",
                error: function(collection, query) {
                    ordersChannel.trigger("searchcomplete", {numOfResults: 0, searchString: self.searchString, totalHits: self.collection.totalHits});
                    Authentication.checkErrors(collection, query);
                },
                success: function(results) {
                    var options = {numOfResults: results.length, searchString: self.searchString, filters: requestData, totalHits: self.collection.totalHits};
                    ordersChannel.trigger("searchcomplete", options);
                }
            });
        }
    });

    var OrderHistoryLayoutView = Marionette.LayoutView.extend({

        template: "templates/orders/order-history-layout-view.hbs",
        className: 'container-height',
        regions: {
            orderHistoryListRegion: "#order-history-list-region"
        },

        behaviors: {
            ScrollBehaviour: {
                behaviorClass: ScrollBehaviour,
                channel: BackboneRadio.channel('orderChannel')
            }
        },


        events: {
            "mouseenter #ttOrderHistory" : "showPopover",
            "mouseleave #ttOrderHistory" : "hidePopover"
        },

        showPopover: function() {
            $('#ttOrderHistory').popover('show');
        },
        hidePopover: function() {
            $('#ttOrderHistory').popover('hide');
        },

        onBeforeShow: function() {
            var self = this;
            headerChannel.trigger('show:menu', 'orderHistory');
            self.getRegion('orderHistoryListRegion').show(new OrderHistoryListView());
        }
    });

    return OrderHistoryLayoutView;
});
