define('productSearchLayoutView',[
    'backbone', 'marionette', 'backboneradio', 'productSearchFilters', 'productListView', 'productDetailsView',
    'productSearchView', 'productSearchHeadingView', 'scrollBehaviour', 'authentication'
],
function(Backbone, Marionette, BackboneRadio, productSearchFilters, ProductListView, ProductDetailsView, 
            ProductSearchView, ProductSearchHeadingView, ScrollBehaviour, Authentication) {

    var productChannel = BackboneRadio.channel('productChannel');
    var headerChannel = BackboneRadio.channel('headerChannel');

    var ProductSearchLayoutView = Marionette.LayoutView.extend({
        cidPrefix: 'ProductSearchLayoutView_',
        initialize: function() {
            this.listenTo(productChannel, "product:details", function(selectedProduct) {
                var productDetailsView = new ProductDetailsView({
                    model: selectedProduct
                });
                this.getRegion('productDetailsModal').show(productDetailsView);
                $('#product-details-modal').modal('show');
            });
        },
        
        behaviors: {
            ScrollBehaviour: {
                behaviorClass: ScrollBehaviour
            }
        },
        
        className: 'container-height',
        template: "templates/products/product-search-layout.hbs",
        
        regions: {
            productFilter:  "#product-filter-region",
            productSearchHeading: "#product-search-heading-region",
            productSearchResults: "#product-search-results-region",
            productDetailsModal: "#product-details-modal-region"
        },

        onBeforeShow: function() {
            headerChannel.trigger('show:menu', 'searchProducts');

            this.getRegion('productSearchResults').show(new ProductListView({quoteBuilding: false}));
            this.getRegion('productSearchHeading').show(new ProductSearchHeadingView({mode: 'products'}));  
            var productSearchFilterModel = new productSearchFilters.SearchFilterModel({tierOneFilters: FILTERSCONFIG.tierOneFilters, 
                tierTwoFilters: FILTERSCONFIG.tierTwoFilters, 
                canViewPromotionalPricing: Authentication.canViewPromotionalPricing()});
            var viewParam = {model: productSearchFilterModel};
            viewParam = Object.assign(viewParam, this.options);
            this.getRegion('productFilter').show(new productSearchFilters.SearchFilterView(viewParam));
        }
        
    });
    
	return ProductSearchLayoutView;

});
