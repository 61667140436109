
define('basketContentsView',[ 'marionette', 'backboneradio', 'authentication', 'format', 'imageHelper', 'currentCustomer'],
function(Marionette, BackboneRadio, Authentication, format, imageHelper, CurrentCustomer) {
 
    var alertChannel = BackboneRadio.channel('alertChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');
    var quoteChannel = BackboneRadio.channel('quoteChannel');

    var ProductRowView = Marionette.ItemView.extend({
        tagName: 'tbody',
        getTemplate: function() {
            if (this.model.get('titleBar')) {
                return 'templates/basket/basket-contents-header.hbs';
            } else {
                return 'templates/basket/basket-contents.hbs';
            }
        },
        modelEvents: {
            'change': 'render'
        },
        className: function() {
            if (this.model.get('restrictedForCustomer')) {
                return 'on_banned';
            }
            if (this.model.get('promotional')) {
                return 'on_promotion';
            }
            return '';
        },
        events: {
            'blur .quantity': 'quantityBoxBlurred',
        	'click .increase': 'increase',
            'click .decrease': 'decrease',
            'keydown .quantity': 'enterQuantity',
            'click .remove-link': 'deleteProduct'
        },

        templateHelpers: function() {
            var data = {};
            if(this.model.get('productCode') != undefined) {
                data.notActive = this.model.get('productCode').substring(0, 1) === "*" ? "disabled not_active" : this.model.get('notActive');
            }

            return data;
        },

        onBeforeRender: function () {
            var priceAfterProfileDiscount = this.model.get('priceAfterProfileDiscount');
            var nonPromotionalPrice = this.model.get('nonPromotionalPrice');
            if (priceAfterProfileDiscount === null || priceAfterProfileDiscount === undefined) {
                this.model.set('was', nonPromotionalPrice);
            }
            else {
                this.model.set('was', priceAfterProfileDiscount);
            }

        },
        onRender: function() {
        	this.$el.find('img.product-image').on('error', function(evt) { imageHelper.findAltImage(evt); });

        },
        onAttach: function(){
            this.model.attributes.originalQuantity = this.model.attributes.quantity;
        },
        getValue: function() {
            var value = parseInt(this.$('input.quantity').val(), 10);
            return value;
        },
        quantityBoxBlurred: function(event) {
        	CurrentCustomer.updatingQuotes = true
        	this._changeQuantity(this.getValue(), this.model.get("quantity"));
            return false;
        },

        increase: function() {
            // prevent an update while another is in flight
            if (this.model.locked){
                return;
            }
            var newQuantity = this.getValue();
            if (isNaN(newQuantity)) {
                alertChannel.trigger('validation', 'value is invalid');
            }
            var currentQuantity = this.model.get('quantity');
            if (newQuantity === currentQuantity){
                newQuantity += 1;
            }
            basketChannel.trigger("product:set", this, newQuantity, currentQuantity);
        },
        decrease: function() {
            // prevent an update while another is in flight
            if (this.model.locked){
                return;
            }
            var newQuantity = this.getValue();

            if (isNaN(newQuantity)) {
                alertChannel.trigger('validation', 'value is invalid');
            }
            var currentQuantity = this.model.get('quantity');
            if (newQuantity === currentQuantity){
                newQuantity -= 1;
            }
            basketChannel.trigger("product:set", this, newQuantity, currentQuantity);
        },
        enterQuantity: function(event) {
            if (event.keyCode === 13 || event.keyCode === 9 ) {
                this.trigger('next:row', event.shiftKey);
            }
        },
        showQuantityUpdating: function() {
           this.$('.quantity').addClass('updating');
        },
        showQuantityUpdated: function() {
           this.$('.quantity').removeClass('updating');
        },
        _changeQuantity: function(newQuantity, currentQuantity) {
            if (isNaN(newQuantity) || (newQuantity < 0)) {
                alertChannel.trigger('validation', 'value is invalid');
            } else {
                if (newQuantity !== currentQuantity) {
                    basketChannel.trigger("product:set", this, newQuantity, currentQuantity);
                }
                return true;
            }
            return false;
        },
        deleteProduct: function() {
            basketChannel.trigger("product:delete", this, this.model.get('originalQuantity'));
        },
        selectInput: function() {
            this.$('.quantity').focus().select();
        },
        animateQuantityChange: function(quantity) {
            this.$('.basket_count').text(quantity);
            var self = this;
            var animateOut = function() {
                    self.$('.basket_count_slider').animate({height: '0px', paddingTop: '0px'}, 300);
                };
            var waitABit = function() {
                setTimeout(animateOut, 1000);
            };
            this.$('.basket_count_slider').animate({height: '54px', paddingTop: '6px'}, 300, waitABit);
        },
        serializeData: function(){
           var data = this.model.toJSON();
           if (data.titleBar === undefined) {
               var multiplicationFactor = 1;
               if ((data.multiplicationFactor !== null) && (data.multiplicationFactor !== undefined)) multiplicationFactor = data.multiplicationFactor;
               data.totalPrice = format.fixedDecimal(parseFloat(data.price) * parseInt(data.quantity) * multiplicationFactor, 2);
           }
           if (this.model.get('promotional')) {
                var price = this.model.get('price');
                var nonPromotionalPrice = this.model.get('nonPromotionalPrice');
                var priceAfterProfileDiscount = this.model.get('priceAfterProfileDiscount');

                if(this.model.get('priceAfterProfileDiscount') === null)  {
                    this.model.set('was', nonPromotionalPrice);
                    data.percentSaving = ((1.0 - (price / nonPromotionalPrice)) * 100.0).toFixed(1);
                }
                else{
                    this.model.set('was', priceAfterProfileDiscount);
                    data.percentSaving = ((1.0 - (price / priceAfterProfileDiscount)) * 100.0).toFixed(1);
                }

            }
           return data;
        }
    });

    var EmptyCollectionView = Marionette.ItemView.extend({
        tagName: 'tr',
		template: "templates/basket/basket-contents-empty.hbs",
        events: {
            'click #add_products_link': 'addProducts'
        },
        addProducts: function() {
            quoteChannel.trigger('quote:addproducts');
            return false;
        }
	});
    
    var BasketContentsView = Marionette.CollectionView.extend({
        tagName: 'table',
        className: 'form-inline table',
        childView: ProductRowView,
      /*  viewComparator: 'addedTime',*/
        emptyView: EmptyCollectionView,
        isEmpty: function(collection) {
            return (this.collection && this.collection.length === 0);
        },
        collectionEvents: {
            'reset': 'loaded'
        },
        onChildviewNextRow: function(view, backwards) {
            var newRowIndex = view._index + (backwards?-1:1);
            if (newRowIndex < 0){
                newRowIndex = this.children.length - 1;
            }
            if (newRowIndex >= this.children.length){
                newRowIndex = 0 ;
            }
            var model = this.collection.at(newRowIndex);
            var newView =  this.children.findByModel(model);
            newView.selectInput();
        },
        loaded: function() {
            this.collection._loaded = true;
            this.render();
        }
    });
    
    return BasketContentsView;
});



