define('staticModels',[
    'backbone', 'marionette', 'backboneradio', 'authentication'
],
function(Backbone, Marionette, BackboneRadio, Authentication) {
    
    var rangeTypesList = [];
    var rangeTypesMap = {};
    
    /* Sets rangeTypesList, rangeTypesMap. */
    function processRangeTypes() {
        var enums = _.keys(CONFIG.ranges).sort();
        _.each(enums, function(enumKey) {
            rangeTypesList.push({id: enumKey, value: CONFIG.ranges[enumKey]});
        });
        _.extend(rangeTypesMap, CONFIG.ranges, CONFIG.range_groups);
    }
    
    var RangeType = Backbone.Model.extend({
    });

    var RangeTypes = Backbone.Collection.extend({
        model : RangeType,
        load: function() {
            processRangeTypes();
            this.set(rangeTypesList);
        }
    });

    var PricingPeriod = Backbone.Model.extend({
    });
    var PricingPeriods = Backbone.Collection.extend({
        model : PricingPeriod,
        
        load: function() {
            this.url = CONFIG.rocs_url + "/quote-service/quote/pricing-periods/current";
            Authentication.refreshAndFetch(this, {
                cache : false,
                contentType : 'application/json',
                error : Authentication.checkErrors
            });
        }
    });

    var rangeTypes = new RangeTypes();
    var pricingPeriods = new PricingPeriods();
    
    var data = {
        rangeTypes: rangeTypes,
        rangeTypesList: rangeTypesList,
        rangeTypesMap: rangeTypesMap,
        pricingPeriods: pricingPeriods,
        load: function() {
            rangeTypes.load();
            pricingPeriods.load();
        }
    };
    
    return data;
});

