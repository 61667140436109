define('quoteSubHeaderView',['backbone', 'marionette', 'backboneradio', 'staticModels', 'currentCustomer', 'authentication', 'quoteDetailsModal', 'basket'],
function(Backbone, Marionette, BackboneRadio, staticModels, CurrentCustomer, Authentication, QuoteDetailsModal, Basket) {
 
    var orderChannel = BackboneRadio.channel('orderChannel');
    var promptChannel = BackboneRadio.channel('promptChannel');
    var quoteChannel = BackboneRadio.channel('quoteChannel');
   
   /*************
    * Handles the baskets in the header.
    */
   
   /* A basket */
   var BasketView = Basket;

    var QuoteSubHeaderView = Marionette.CompositeView.extend({
            template: "templates/quotes/quote-sub-header-view.hbs",
            className: function() {
                var className = 'basket-panel';
                if (this.options.justBaskets) {
                    className += ' just-baskets';
                }
                return className;
            },
            childView: BasketView,
            childViewContainer: "#list-of-baskets",
            events: {
              'click #left-basket-scroll':'scrollLeft',
              'click #right-basket-scroll':'scrollRight',
              'click #add-new-basket': 'addNewbasket',
              'click #remove-new-basket': 'removeAddNewBasket',
              'click #create-new-basket': 'createNewBasket',
              'click #update-basket': 'updateBasket'
            },
            collectionEvents: {
                "update": "collectionChange",
                "reset" : "collectionChange",
                'change': 'modelChange'
            },
            
            initialize: function(options) {
                this.basketIndex = 0; 
                if ((options !== undefined) && (options.quoteId !== undefined)) {
                    this.basketIndex = options.quoteIndex;
                }
            },
            
            templateHelpers: function() {
                var rangeTypes = staticModels.rangeTypes.toJSON();
                var pricingPeriods = staticModels.pricingPeriods.toJSON()
                if(staticModels.selectedQuote) {
                    var selectedPricingPeriod = staticModels.selectedQuote.pricingPeriodId
                }

                return {
                    rangeTypes: rangeTypes,
                    pricingPeriods: pricingPeriods,
                    selectedPricingPeriod: selectedPricingPeriod };
            },

            modelChange: function(model) {
                var view = this.children.findByModel(model);
                if (view) {  // works for selection of basket
                    view.render();
                }
            },

            /* Checks if a new basket has been created, if so then select it.
            */
            refreshActive: function() {
              var refreshed = false;
              if (this.basketToDisplay) {
                  var index;
                  for(var i = 0; i < this.collection.length; i++) {
                      var model = this.collection.at(i);
                      if (model.get("quoteId") === this.basketToDisplay) {
                          index = i;
                          break;
                      }

                  }
                if (index !== undefined) {
                   this._setModelSelection(index);
                   this.trigger("basket:selected", index); // Notify others of the basket selection.

                    setTimeout(function() {
                        quoteChannel.trigger('quote:addproducts');
                    }, 30);
                    
                    refreshed = true;
                }
                  
                delete this.basketToDisplay;
              }
              return refreshed;
            },

            collectionChange: function() {
                this.$el.parent().show(); // Make visible
                this.scrollerState();
            },
            
            onChildviewBasketSelected: function(view) {
                this._setModelSelection(view._index);
                this.trigger("basket:selected", view._index);
            },
            
            onChildviewBasketDetailsModal: function(view) {
                var QuoteDetailsModel = Backbone.Model.extend({
                });
                var quoteDetailsModel = new QuoteDetailsModel(view.model.toJSON());
                var modal = new QuoteDetailsModal({model: quoteDetailsModel});
                promptChannel.trigger('show:modal', modal);
            },
            
            _setModelSelection: function(newIndex) {
                if (newIndex >= this.collection.length) {
                    newIndex = this.collection.length - 1;
                }
                
                if (this.basketIndex < this.collection.length) {
                    var oldModel = this.collection.at(this.basketIndex);
                    oldModel.set('selected', false);
                }
                var newModel = this.collection.at(newIndex);
                newModel.set('selected', true);
                this.basketIndex = newIndex;
                if ((this._scrollIndex > this.basketIndex) || (this._scrollIndex + 3 < this.basketIndex)) {
                    var self = this;
                    setTimeout(function() {
                        self._scrollIndex = (self.basketIndex >= 3) ? self.basketIndex - 3 : 0;
                        self._doScroll();
                    }, 100);
                }
                staticModels.selectedQuote = newModel.attributes;
            },
            
            _scrollIndex: 0,
            _scrollAmount: 275,
            
            scrollLeft: function() {
                if (this._scrollIndex > 0) {
                    this._scrollIndex = this._scrollIndex - 1;
                    this._doScroll();
                }
            },
            scrollRight: function() {
               if (this._canScrollRight()) { 
                   this._scrollIndex = this._scrollIndex + 1;
                   this._doScroll();  
               }
            },
            _canScrollRight: function() {
                return this.collection.length - this._scrollIndex > 4;
            },
            _doScroll: function() {
                var self = this;
                this.$('#list-of-baskets').animate({'scrollLeft': this._scrollAmount * this._scrollIndex}, 500, 
                    function() {
                        self.scrollerState();
                    });
            },
            scrollerState: function() {
                this.$('.basket-scroll').removeClass('enabled');
                if (this._scrollIndex > 0) {
                    $('#left-basket-scroll').addClass('enabled');
                }
                if (this._canScrollRight()) {
                    $('#right-basket-scroll').addClass('enabled');
                }
            },
            
            
            onChildviewBasketEdit: function(view) {
                this._setModelSelection(view._index);
                var key = view.model.get('rangeType');
                var range = {
                    key: key,
                    value: CONFIG.ranges[key],
                    customerReference: view.model.get('customerReference')
                };
                this._showQuoteCreate('editing_quote', range);
            },
            addNewbasket: function() {
                this._showQuoteCreate('creating_quote');
            },
            _showQuoteCreate: function(classToAdd, range) {
                this.render();
                this.$('#basket-reference').val('');
                this.$('#over-basket-list').removeClass('editing_quote creating_quote').addClass(classToAdd).fadeIn(400);
                if (range) {
                    this.$('#quote-edit-type').val(range.value);
                    this._editRange = range;
                    $('#basket-reference').val(range.customerReference);
                }
            },
            removeAddNewBasket: function() {
                this.$('#over-basket-list').fadeOut(400); 
            },
            
            updateBasket: function() {
                var self = this;
                var data = this._commonBasketAjaxData();
                data.rangeType = this._editRange.key;
                data.quoteId = this.collection.at(this.basketIndex).get('quoteId');

                Authentication.makeAjaxCall({
                        type: 'POST',
                        url: CONFIG.rocs_url + "/quote-service/quote/edit",
                        data: JSON.stringify(data),
                        contentType : 'application/json',
                        success : function(result) {
                            staticModels.selectedQuote = result;
                            self.trigger("basket:updated");
                            orderChannel.trigger("reload");
                        }, 
                        error : Authentication.checkAjaxErrors
                    });
                this.removeAddNewBasket();
            },
            

            createNewBasket: function() {
                var self = this;
                var data = this._commonBasketAjaxData();
                data.rangeType = this.$('select.new-basket-quote-types option:selected').val();
                    
                Authentication.makeAjaxCall({
                        type: 'POST',
                        url: CONFIG.rocs_url + "/quote-service/quote/create",
                        data: JSON.stringify(data),
                        contentType : 'application/json',
                        success : function(result) {
                            self.basketToDisplay = result.quoteId;
                            orderChannel.trigger("reload");
                        }, 
                        error : Authentication.checkAjaxErrors
                    });
                this.removeAddNewBasket();
            },
            
            _commonBasketAjaxData: function() {

            	var selectedPricingPeriod = this.$('input.pricing-period-input:checked');
                var pricingPeriod = selectedPricingPeriod.val();
                var pricingPeriodId = selectedPricingPeriod.attr("data-pricing-period-id");
                var effectiveUntil = this.$('input[name=effective-until-' + pricingPeriodId + ']').val();
                var data = {
                    shipmentId: this.collection.at(0).get('shipmentId'),
                    deliveryDate: new Date(pricingPeriod),
                    effectiveUntil: new Date(effectiveUntil),
                    pricingPeriodId: this.$('input.pricing-period-input:checked').data('pricing-period-id'),
                    customerCode: CurrentCustomer.code,
                    customerReference: this.$('#basket-reference').val()
                };
                return data;
            },
            
            /* Populate the 'baskets' row from the order model.
             * Keeps the selection, unless a new basket has been created,.
            */
            updateFromOrder: function(orderModel, index) {
                var quotes = orderModel.get("quotes");
                var Basket = Backbone.Model.extend({
                });
                var basketModels = [];
                for(var i = 0; i < quotes.length; i++) {
                    basketModels.push(new Basket(quotes[i]));
                }
                basketModels.sort(function(a, b) {
                    return a.get('quoteId') .localeCompare(b.get('quoteId'));
                });
                this.collection.reset(basketModels);
                if (! this.refreshActive()) {
                    this._setModelSelection(index);                   
                    this.trigger("basket:selected", this.basketIndex);            
                }
            }
        });
    return QuoteSubHeaderView;
});

