define('imageHelper',[ 'marionette'],
function(Marionette) {
	var image = {
		findAltImage: function(evt) {
            var productImage = $(evt.currentTarget);
            var src = productImage.attr('src');
            if (~src.indexOf("ProductImagesBrandbank")) {
            	src = src.replace("ProductImagesBrandbank", "ProductImagesRamsden");
            } else {
            	src = '/img/no-image_66.jpg';
            }
            productImage.attr('src', src);
        }
	};
	
	return image;
});

