define('productDetailsView',[
    'backbone', 'marionette', 'imageHelper'
],
function(Backbone, Marionette, imageHelper) {
    var disableBanned = false;
    var ProductDetailsView = Marionette.ItemView.extend({
        template: "templates/products/list/product-details.hbs",
        templateHelpers: function() {
            var view = this;
             var data = {banned: []};
              _.each(this.model.get('attributes'), function(attrib) {
                if (attrib.restrictedForCustomer) {
                      data.banned.push(attrib);
                }
            });
            return data;
        },
        onRender: function() {
        	this.$el.find('img.product-image-large').on('error', function(evt) { imageHelper.findAltImage(evt); });
        },
        onShow: function() {
            var banned = _.where(this.model.get("attributes"), { restrictedForCustomer: true });
            
            if (banned.length === 0) {
                var bannedButton = $("#banBtn-" + this.model.get("productCode"));
                bannedButton.addClass("no-bans");
                bannedButton.addClass("disabled");
                bannedButton.removeAttr("href");
                bannedButton.removeClass("link");
                bannedButton.removeAttr("data-toggle");
            }
            
        }
    });
    
    return ProductDetailsView;
});
