define('quoteRepriceModal',['backbone', 'marionette', 'backboneradio', 'authentication'],
    function(Backbone, Marionette, BackboneRadio, Authentication) {
        var orderChannel = BackboneRadio.channel('orderChannel');
        var quoteChannel = BackboneRadio.channel('quoteChannel');

        var QuoteRepriceModal = Marionette.ItemView.extend({
            template: "templates/quotes/quote-reprice-modal.hbs",
            className: "modal fade",
            events: {
                'click #reprice_quote': 'repriceQuote',
                'click .cancel_modal_link': 'cancel'
            },

            initialize: function(options) {
                this.currentPricingPeriod = options.currentPricingPeriod;
            },

            templateHelpers: function() {
                var data = {};
                data.currentPricingPeriodId = this.currentPricingPeriod.get("id");

                return data;
            },

            repriceQuote: function() {
                var self = this;

                this.$el.find('.cancel_modal_link').addClass("disabled not_active");
                this.$el.find('#reprice_quote').addClass("disabled not_active").text('Repricing...');

                var doSuccess = function() {
                    orderChannel.trigger("reload:synchronously");
                    self.$el.modal('hide');
                };

                Authentication.makeAjaxCall({
                    cache : false,
                    type : 'POST',
                    url : CONFIG.rocs_url + "/quote-service/quote/" + self.model.get('quoteId') + "/reprice",
                    contentType : 'application/json',
                    success : doSuccess,
                    error : Authentication.checkAjaxErrors
                });
            },

            cancel: function() {

                this.$el.modal('hide');
            }
        });

        return QuoteRepriceModal;
    });
