define('quoteDetailsModal',['backbone', 'marionette', 'backboneradio', 'authentication'],
function(Backbone, Marionette, BackboneRadio, Authentication) {
    var orderChannel = BackboneRadio.channel('orderChannel');
  
     var QuoteDetailsModal = Marionette.ItemView.extend({
         template: "templates/quotes/quote-details-modal.hbs",
         className: "modal fade",
         events: {
             'click #delete_quote': 'promptDeleteQuote',
             'click #cancel_delete_quote': 'cancel',
             'click #confirm_delete_quote': 'confirmDeleteQuote',
             'shown.bs.modal': 'shownModal',
             "mouseenter #ttConfirmModal" : "showPopover",
            "mouseleave #ttConfirmModal" : "hidePopover"
         },

         onRender: function() {
            var percent = Math.max(0, this.getPercent() * 100.0);
            this.$('#quote-details-bar-fill').css('width', percent+ '%');
            var quoteStatus = this.model.get('quoteStatus');
            var quoteSynced = this.model.get('synced');
            if (quoteStatus !== 'OPEN' || quoteStatus !== 'EXPIRED' && quoteSynced) {
                this.$('#delete_quote').hide();
            }
         },
         shownModal: function() {
            if (this.options.forDelete) {
                this._showPrompt(200);
            } else {
                this.$('#delete_quote').focus();
            }
         },
         
         templateHelpers: function() {
            var values = {
                percent: Math.max(0, Math.floor(this.getPercent() * 100))
            };

             values.isQuote = this.model.get('orderId') == null;

             return values;
        },

         showPopover: function() {
                $('#ttConfirmModal').popover('show');
            },
            hidePopover: function() {
                $('#ttConfirmModal').popover('hide');
            },
        
        getPercent: function() {
            var minimumOrderQuantity = this.model.get('minimumOrderQuantity');
            var basket = this.model.get('basket');
            var percent = 0;
            if (minimumOrderQuantity) {
                if (minimumOrderQuantity.minimumQuantity) {
                    percent = basket.basketTotalCases / minimumOrderQuantity.minimumQuantity;
                } else if (minimumOrderQuantity.minimumValue) {
                    percent = basket.basketTotalPrice / minimumOrderQuantity.minimumValue;
                }
            }
            // Limit...
            percent = Math.max(0, percent);
            percent = Math.min(1, percent);
            return percent;
        },
        
        promptDeleteQuote: function() {
            this._showPrompt(400);
        },
        _showPrompt: function(speed) {
            var self = this;
            this.$('#delete_quote').addClass('disabled');
            this.$('#delete_quote_row').slideDown(speed, function() {
                self.$('#confirm_delete_quote').focus();
            });
        },
        cancel: function() {
            this.$el.modal('hide');
        },
        confirmDeleteQuote: function() {
            var self = this;
            var doSuccess = function(result) {
                self.$el.modal('hide');
                orderChannel.trigger("reload:delete", self.model.get('quoteId'), self.model.get('shipmentId'));
            };

            Authentication.makeAjaxCall({
                cache : false,
                type : 'DELETE',
                url : CONFIG.rocs_url + "/quote-service/quote/delete/" + self.model.get('quoteId'),
                contentType : 'application/json',
                success : doSuccess,
                error : Authentication.checkAjaxErrors
            });
        }
     });
     
     return QuoteDetailsModal;
});

