define('controlsView',[
        'backbone', 'marionette', 'backboneradio', 'customerSearchView', 'customerListView', 'quoteListView', 'authentication', 'currentCustomer',
        'staticModels', 'ordersModel', 'basket', 'issuesItemCollection'
    ],
    function (Backbone, Marionette, BackboneRadio, CustomerSearchView, CustomerListView, QuoteListView, Authentication, CurrentCustomer,
              staticModels, OrdersModel, Basket, IssuesItemCollection) {

        var customerChannel = BackboneRadio.channel('customerChannel');
        var quoteChannel = BackboneRadio.channel('quoteChannel');
        var orderChannel = BackboneRadio.channel('orderChannel');
        var promptChannel = BackboneRadio.channel('promptChannel');
        var issuesChannel = BackboneRadio.channel('issuesChannel');

        var CustomerSearchModal = Marionette.LayoutView.extend({
            initialize: function () {
                var self = this;
                this.listenTo(customerChannel, "customer:displaysearch", function () {
                    if (CurrentCustomer.code === "-1") {
                        self.$('.close').addClass('hide');
                    } else {
                        self.$('.close').removeClass('hide');
                    }

                    $('#customer-search-modal').modal({
                        show: true,
                        backdrop: 'static',
                        keyboard: false
                    });
                });
            },
            events: {
                'shown.bs.modal': function () {
                    $('#customer-input-search').focus();
                }
            },

            template: "templates/customers/search/customer-search-modal.hbs",
            regions: {
                customerSearch: "#customer-search-region",
                customerList: "#customer-list-region"
            },

            onBeforeShow: function () {
                this.getRegion('customerSearch').show(new CustomerSearchView());
                this.getRegion('customerList').show(new CustomerListView());
            }
        });

        var LoadingDataModal = Marionette.LayoutView.extend({
            initialize: function () {
                $('#loading-data-modal').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: false
                });
            },

            template: "templates/controls/loading-data-modal.hbs"
        });

        var SelectView = Marionette.ItemView.extend({
            template: "templates/controls/quote-options.hbs",
            collectionEvents: {
                'sync': 'render',
                'update': 'render'
            },
            events: {
                "mouseenter #ttRangeTypeModal": "showPopover",
                "mouseleave #ttRangeTypeModal": "hidePopover",
            },
            showPopover: function () {
                $('#ttRangeTypeModal').popover('show');
            },
            hidePopover: function () {
                $('#ttRangeTypeModal').popover('hide');
            },
        });

        var RadioView = Marionette.ItemView.extend({
            template: "templates/controls/quote-pricing-period.hbs",
            collectionEvents: {
                'sync': 'render'
            },
            events: {
                "mouseenter #ttPricingPeriodModal": "showPopover",
                "mouseleave #ttPricingPeriodModal": "hidePopover",
            },
            showPopover: function () {
                $('#ttPricingPeriodModal').popover("show");
            },
            hidePopover: function () {
                $('#ttPricingPeriodModal').popover("hide");
            }
        });

        /* This modal is used in two ways, from a product list view and from a menu.
         * The product list view keeps a count per product of how many are ordered, over all the baskets.
         */
        var QuoteOptionModal = Marionette.LayoutView.extend({
            initialize: function () {
                var self = this;
                /* sourceModel is optional parameter, for when adding a product to a new basket.*/
                this.listenTo(quoteChannel, "quote:createquote", function (sourceModel) {
                    if (sourceModel) {
                        $('select.quote-type-select').hide();
                        $('#quote-type-select-ro').show().val(sourceModel.get('range'));
                        $('#quoteOptionsQuantitySection').show();
                        $('#quoteOptionsQuantity').val('1');
                    } else {
                        $('select.quote-type-select').show();
                        $('#quote-type-select-ro').hide();
                        $('#quoteOptionsQuantitySection').hide();
                    }
                    $('select.quote-type-select').prop('selectedIndex', 0);
                    $('#quoteOptionsReference').val('');
                    $('#shipmentReference').val('');

                    $('#quote-options-modal').modal('show');
                    self.sourceModel = sourceModel;
                });
            },

            events: {
                'click #add_more_products': 'addMoreProducts',
                'mouseenter #ttOverallReferenceModal': 'showPopover',
                'mouseleave #ttOverallReferenceModal': 'hidePopover',
                'mouseenter #ttQuoteReferenceModal': 'showPopoverQR',
                'mouseleave #ttQuoteReferenceModal': 'hidePopoverQR',
            },

            template: "templates/controls/quote-options-modal.hbs",
            regions: {
                quoteOptions: "#quote-options-region",
                quotePricingOptions: "#quote-pricing-options-region"
            },

            showPopover: function () {
                $('#ttOverallReferenceModal').popover('show');
            },
            hidePopover: function () {
                $('#ttOverallReferenceModal').popover("hide");
            },
            showPopoverQR: function () {
                $('#ttQuoteReferenceModal').popover("show");
            },
            hidePopoverQR: function () {
                $('#ttQuoteReferenceModal').popover("hide");
            },

            addMoreProducts: function () {
                var self = this;
                var selectedPricingPeriod = this.$el.find("#pricing-periods input[type='radio']:checked");
                var pricingPeriod = selectedPricingPeriod.val();
                var pricingPeriodId = selectedPricingPeriod.attr("data-pricing-period-id");
                var effectiveUntil = this.$el.find('input[name=effective-until-' + pricingPeriodId + ']').val();
                var quantity;
                var rangeType;
                if (this.sourceModel) {
                    quantity = parseInt(this.$('#quoteOptionsQuantity').val(), 10);
                    rangeType = this.sourceModel.get('range');
                    if (isNaN(quantity)) {
                        return; //?? validation msg or effect
                    }
                } else {
                    rangeType = this.$el.find('.quote-type-select option:selected').val();
                }
                var quoteDetails = {
                    deliveryDate: new Date(pricingPeriod),
                    effectiveUntil: new Date(effectiveUntil),
                    pricingPeriodId: pricingPeriodId,
                    customerReference: this.$el.find('#quoteOptionsReference').val(),
                    customerCode: CurrentCustomer.code,
                    rangeType: rangeType
                };
                var orderDetails = {
                    customerCode: CurrentCustomer.code,
                    shipmentReference: this.$el.find('#shipmentReference').val(),
                    quotes: [quoteDetails]
                };

                /* Create the new order. */
                var ordersModel = new OrdersModel();
                ordersModel.createNew(orderDetails);
                this.listenToOnce(ordersModel, 'sync', function () {
                    var quote = ordersModel.get('quotes')[0];
                    /* Either view the quote. */
                    if (self.sourceModel) {
                        self._addProduct(quote, quantity);
                    } else {
                        quoteChannel.trigger("quote:view:quote", ordersModel.clone(), quote.quoteId, 0);
                    }
                });

                $('#quote-options-modal').modal('hide');
            },

            _addProduct: function (quote, quantity) {
                var self = this;
                Authentication.makeAjaxCall({
                    cache: false,
                    type: 'POST',
                    url: CONFIG.rocs_url + "/quote-service/quote/set/" + quote.quoteId + "/" + this.sourceModel.get('productCode') + "/" + quantity,
                    data: {},
                    success: function (value) {
                        /* sourceModel is from the list screen, where it displays the quantity on order. */
                        var onQuoteQuantity = self.sourceModel.get('onQuoteQuantity');
                        self.sourceModel.set('onQuoteQuantity', onQuoteQuantity + quantity);
                    }
                });
            },

            onBeforeShow: function () {
                this.getRegion('quoteOptions').show(new SelectView({collection: staticModels.rangeTypes}));
                this.getRegion('quotePricingOptions').show(new RadioView({collection: staticModels.pricingPeriods}));
            }
        });

        var basketList = new Backbone.Collection();
        var BasketItemView = Marionette.ItemView.extend({
            template: "templates/controls/basket-request-item.hbs",
            events: {
                'click .select': 'select',
                'click .termsBox': 'acceptTerms',
                'click .basket-request-cancel': 'cancel',
                'click .issues-text': 'showIssuesModal'
            },
            templateHelpers: function () {
                var data = {};
                data.hasIssues = this.model.get('issues') > 0;
                data.issuesProductString = this.model.get('issues') > 1 ? 'products' : 'product';
                return data;
            },
            select: function (event) {
                if ($(event.currentTarget).hasClass('disabled'))
                    return;
                this.updateButton(this.model.get("quoteId"), this.model.get("terms"));
            },
            acceptTerms: function (e) {
                var checkBox = $(e.target);
                var sId = "#selectBtn-" + this.model.get("quoteId");
                var selectBtn = this.$el.find(sId);
                if (checkBox.is(':checked')) {
                    selectBtn.removeClass("disabled");
                    this.model.set("terms", 1);
                } else {
                    selectBtn.addClass("disabled");
                    this.model.set("terms", 0);
                    this.updateButton(this.model.get("quoteId"), this.model.get("terms"));
                }
            },

            onRender: function () {
                var sId = "#selectBtn-" + this.model.get("quoteId");
                var selectBtn = this.$el.find(sId);
                if (this.model.get('issues') > 0) {
                    selectBtn.addClass("disabled");
                }
                if (this.model.get("state") === "READY_FOR_CONFIRMATION") {
                    selectBtn.addClass("disabled");
                } else {
                    var termsCheckbox = this.$el.find('.quote_submit_terms');
                    termsCheckbox.hide();
                }
            },

            updateButton: function (selected, terms) {
                var index = quoteIds.indexOf(selected);
                if (terms === undefined || terms === 1) {
                    if (index !== -1) {
                        quoteIds.splice(index, 1);
                        this.$el.find('.select-span').removeClass('glyphicon glyphicon-ok');
                        this.$el.find('.select-span-text').text('Select');
                    } else {
                        quoteIds.push(selected);
                        this.$el.find('.select-span').addClass('glyphicon glyphicon-ok');
                        this.$el.find('.select-span-text').text(' Selected');
                    }
                } else {
                    if (index !== -1) {
                        quoteIds.splice(index, 1);
                    }
                    this.$el.find('.select-span').removeClass('glyphicon glyphicon-ok');
                    this.$el.find('.select-span-text').text('Select');
                }

                this.trigger('quote:selected');
            },

            showIssuesModal: function (event) {
                quoteChannel.trigger('quote:get:issues', this.model.get('quoteId'), this.model.get('issues'), this.model);
            }
        });

        var BasketRequestModal = Marionette.LayoutView.extend({
            collection: basketList,
            _mappedCollection: {},
            _restrictedProducts: false,
            initialize: function() {
            var self = this;
            // this.listenTo(quoteChannel, "quote:askrequest", function(shipmentId) {
            //   var modelObj = {
            //     title: "Choose quotes to submit for Shipment",
            //     confirm: "Submit request for quotes",
            //     confirm_n: "Submit request for %1 quote%2",
            //     shipmentId: shipmentId,
            //     modalState: "OPEN"
            //   };
            //   var quoteMatcher = function(quote) {
            //     return (quote.quoteStatus === 'OPEN' || quote.quoteStatus === 'REOPENED' || quote.quoteStatus === 'CONFIRM');
            //   };
            //   self.displayModal(modelObj, quoteMatcher,"OPEN");
            // });
            this.listenTo(quoteChannel, "quote:ask:confirm", function(shipmentId) {
                    var modelObj = {
                        title: "Choose quotes to confirm",
                        confirm: "Confirm quotes",
                        confirm_n: "Confirm %1 quote%2",
                        shipmentId: shipmentId,
                        modalState: "READY_FOR_CONFIRMATION"
                    };
                    var quoteMatcher = function(quote) {
                        return (quote.quoteStatus === 'READY_FOR_CONFIRMATION');
                    };
                    self.displayModal(modelObj, quoteMatcher,"READY_FOR_CONFIRMATION");
                });
            },
            displayModal: function(modelObj, quoteMatcher, state) {
                quoteIds = [];
                this.model.set(modelObj);
                this.render();
                this._displayCount();
                this._displayRestricted();
                this.$('#basket-request-confirm').prop('disabled', true);
                this.$('#basket-request-confirm-confirm').prop('disabled', true);
                this.$('#basket-items-banned').hide();

                this.retrieveOrder(this.model.get('shipmentId'), quoteMatcher, state);
            },

            events: {
                'click #basket-request-confirm': 'confirm',
                'click #basket-request-confirm-confirm': 'confirm',
                'click #basket-request-cancel': 'cancel',
                'click #select-all': 'selectAll',
                'click #select-all-confirm' :'selectAll',
                'click #agree_submit_banned_products': 'agreeSubmitBanned',
                'mouseenter #ttConfirmModal' : 'showPopover',
                'mouseleave #ttConfirmModal' : 'hidePopover',
            },

            showPopover: function() {
                $('#ttConfirmModal').popover('show');
            },
            hidePopover: function() {
                $('#ttConfirmModal').popover('hide');
            },

            selectAll: function(){
                var self = this;
                $.each(this.collection.models, function(index, model){
                    var quoteId = model.get("quoteId");
                    if (quoteIds.indexOf(quoteId) === -1 && (model.get("state") === undefined || model.get("terms") === 1)) {
                        quoteIds.push(quoteId);
                        var sId = "#selectBtn-" + quoteId;
                        self.$(sId + ' .select-span').addClass('glyphicon glyphicon-ok');
                        self.$(sId + ' .select-span-text').text(' Selected');
                    }
                });
                this._displayCount();
                this._displayRestricted();
            },

            agreeSubmitBanned: function(event) {
                var checkbox = $(event.target);
                var checkboxChecked = checkbox.prop('checked');
                this.$('#basket-request-confirm').prop('disabled', ! checkboxChecked);
            },

            _displayCount: function() {
                var text;
                if (quoteIds.length > 0) {
                    text = this.model.get('confirm_n').replace("%1", quoteIds.length);
                    text = text.replace("%2", quoteIds.length > 1 ? "s" : "");
                } else {
                    text = this.model.get('confirm');
                }
                $('#basket-request-confirm').text(text);
                $('#basket-request-confirm-confirm').text(text);
            },

            _displayRestricted: function() {
                var self = this;
                /* Decide on showing the restricted quotes section.*/
                var quotesRestricted = [];
                _.each(quoteIds, function(quoteId) {
                    var quote = self._mappedCollection[quoteId];
                    var restricted = _.find(quote.basket.productLines, function(productLine) {
                        var restrictedQuoteProduct = _.find(productLine.attributes, function(attribute) {
                            return attribute.restrictedForCustomer === true;
                        });
                        return restrictedQuoteProduct !== undefined;
                    }) !== undefined;
                    if (restricted) {
                        quotesRestricted.push(quoteId);
                        self._restrictedProducts = true;
                    }
                });
                if (quotesRestricted.length > 0) {
                    $('#agree_submit_banned_products').prop('checked', false);
                    $('#basket-items-banned').show(200);
                    $('#banned_quotes').text(quotesRestricted.join(', '));
                } else {
                    $('#basket-items-banned').hide(200);
                    $('#basket-items-banned-confirm').hide(200);
                }
                var confirmingQuote = (self.model.get('modalState') == 'READY_FOR_CONFIRMATION');
                $('#basket-request-confirm').prop('disabled', (quoteIds.length === 0) || (quotesRestricted.length > 0 && !confirmingQuote));
                $('#basket-request-confirm-confirm').prop('disabled', (quoteIds.length === 0) || (quotesRestricted.length > 0 && !confirmingQuote));
            },

            cancel: function(){
                $('#basket-request-modal').modal('hide');
                $('#basket-confirm-modal').modal('hide');
            },
            confirm: function(){
                var self = this;
                this.$('#basket-request-confirm').prop('disabled', true);
                this.$('#basket-request-confirm-confirm').prop('disabled', true);

                var mapQuoteIds = {};
                _.each(quoteIds, function(id) {
                    mapQuoteIds[id] = id;
                });

                var ConfirmationModel = Backbone.Model.extend({ });
                var totalCases = 0;
                var totalPrice = 0.0;
                var quotes = [];
                $.each(this.collection.models, function(index, model){
                    if (mapQuoteIds[model.get('quoteId')]) {
                        var basket = model.get('basket');
                        totalCases += basket.basketTotalCases;
                        totalPrice += basket.basketTotalPrice;
                        quotes.push(model.get('quoteId'));
                    }
                });
                var confirmationModel = new ConfirmationModel({
                    totalCases: totalCases, totalPrice: totalPrice,
                    nQuotes: quoteIds.length, shipmentId: this.model.get('shipmentId'), quotes: quotes.join(', ')
                });

                /* hide the modal and wait till it is all gone before posting the confirm,
                   this is so the confirmation modal won't be displayed while the previous modal is being closed.
                   */
                var displayedModal = "#basket-request-modal";
                if (this.model.get('modalState') === "READY_FOR_CONFIRMATION") {
                    displayedModal = "#basket-confirm-modal";
                }
                $(displayedModal).one('hidden.bs.modal', function() {
                    Authentication.makeAjaxCall({
                        cache : false,
                        type : 'POST',
                        url : CONFIG.rocs_url + "/quote-service/order/quotes/confirm?includeRestrictedProducts=" + self._restrictedProducts,
                        contentType : 'application/json',
                        data: JSON.stringify(quoteIds),
                        success : doSuccess,
                        error : Authentication.checkAjaxErrors
                    });
                });
                $(displayedModal).modal('hide');

                var doSuccess = function(result) {
                    promptChannel.trigger('show', {template: "templates/quotes/quote-confirm-modal.hbs", model: confirmationModel});
                    orderChannel.trigger("reload");
                };
            },
            retrieveOrder: function(shipmentId, quoteMatcher,state){
                var self = this;
                self.shipmentId = shipmentId;
                var doSuccess = function(order) {
                    var quotesList = [];
                    var quotesInOrder = order.quotes;
                    if (quotesInOrder){
                        $.each(quotesInOrder, function(index, quote){
                            var totalCases = quote.basket.basketTotalCases;
                            var totalPrice = quote.basket.basketTotalPrice;
                            if (quoteMatcher(quote)){
                                if (totalCases > 0 || totalPrice > 0){
                                    var belowMoq;
                                    if (quote.minimumOrderQuantity.minimumQuantity > 0) {
                                        belowMoq = (totalCases < quote.minimumOrderQuantity.minimumQuantity);
                                    } else {
                                        belowMoq = (totalPrice < quote.minimumOrderQuantity.minimumValue);
                                    }

                                    var collectionValue = {
                                        shipmentId:self.shipmentId,
                                        deliveryDate:quote.deliveryDate,
                                        effectiveUntil:quote.effectiveUntil,
                                        quoteId:quote.quoteId,
                                        customerReference: quote.customerReference,
                                        basket:quote.basket,
                                        belowMoq: belowMoq};

                                    quotesList.push(collectionValue);
                                    self._mappedCollection[quote.quoteId] = collectionValue;
                                }
                            }
                          });
                    }

                    quotesList.sort(function(a, b) {
                        return a.quoteId.localeCompare(b.quoteId);
                    });

                    self.collection.reset(quotesList);

                    var quotesText = " quotes";
                    if (self.collection.length == 1) {
                        quotesText = " quote";
                    }
                    $('#numberOfBasket').text(self.collection.length + quotesText);
                    $('#numberOfBasketConfirm').text(self.collection.length + quotesText);

                    var BasketListView = Marionette.CollectionView.extend({
                        childView: BasketItemView
                    });

                    var basketListView = new BasketListView({collection:self.collection});
                    self.listenTo(basketListView, 'childview:quote:selected', function() {
                        self._displayCount();
                        self._displayRestricted();
                    });

                    if (state === "OPEN"){
                        self.getRegion('basketList').show(basketListView);
                        $('#basket-request-modal').modal('show');
                    } else {
                        for(var j = 0; j < self.collection.models.length; j++){
                            self.collection.models[j].set("state", state);
                        }
                        self.getRegion('basketListConfirm').show(basketListView);
                        $('#basket-confirm-modal').modal('show');
                    }
                };

                Authentication.makeAjaxCall({
                    cache : false,
                    type : 'GET',
                    url : CONFIG.rocs_url + "/quote-service/order/get/" + shipmentId,
                    contentType : 'application/json',
                    success : doSuccess,
                    error : Authentication.checkAjaxErrors
                });
            },
            template: "templates/controls/basket-request-modal.hbs",
            regions: {
                basketList: "#basket-item-list-region",
                basketListConfirm: "#basket-item-list-region-confirm"
            }
        });

        var IssuesModal = Marionette.LayoutView.extend({
            template: "templates/controls/issues-modal.hbs",
            regions: {
                basketOverview: "#basket-overview",
                alternativesRegion: "#alternatives-region"
            },
            ui: {
                basketTotalPrice: '#basket-total-price',
                basketTotalCases: '#basket-total-cases',
                basketTotalVolume: '#basket-total-volume',
                basketTotalWeight: '#basket-total-weight'
            },
            events: {
                'click #issues-confirm-changes': 'confirmChanges',
                'click #issues-cancel': 'closeModal'
            },
            confirmChanges: function () {
                var coll = this.collection;
                var payload = {
                    productsAdded: [],
                    productsRemovedFromQuote: [],
                    productsRemovedFromList: []
                };

                var addToPayload = function (product, disc, isSub) {
                    if (isSub === undefined) {
                        isSub = false;
                    }
                    if (product && product.selected && product.quantity > 0) {
                        var productCode = product.productCode;
                        var quantity = product.quantity;
                        payload.productsAdded.push({
                            productCode: productCode,
                            totalQuantity: quantity,
                            replacedProductCode: disc.productCode,
                            substitution: isSub,
                            alternative: !isSub
                        });
                        return true;
                    }
                    return false;
                };

                coll.each(function (element, index, list) {
                    var disc = element.get('discontinuedProduct');
                    var sub = element.get('substituteProduct');
                    var alt1 = element.get('alternativeProduct1');
                    var alt2 = element.get('alternativeProduct2');
                    var alt3 = element.get('alternativeProduct3');
                    var alt4 = element.get('alternativeProduct4');
                    var subSelected = addToPayload(sub, disc, true);
                    var altOneSelected = addToPayload(alt1, disc);
                    var altTwoSelected = addToPayload(alt2, disc);
                    var altThreeSelected = addToPayload(alt3, disc);
                    var altFourSelected = addToPayload(alt4, disc);

                    if (subSelected || altOneSelected || altTwoSelected || altThreeSelected || altFourSelected || element.get('deleteFromList')) {
                        payload.productsRemovedFromList.push(disc.productCode);
                    } else if (element.get('deleteFromQuote')) {
                        payload.productsRemovedFromQuote.push(disc.productCode);
                    }
                });

                var quoteId = this.model.get('quoteId');

                Authentication.makeAjaxCall({
                    type: 'POST',
                    url: CONFIG.rocs_url + "/quote-service/quote/update/" + quoteId,
                    data: JSON.stringify(payload),
                    contentType: 'application/json',
                    success: function (result) {
                        issuesChannel.trigger('quote:updated', quoteId, result);
                        $('#issues-modal').modal('hide');
                    },
                    error: Authentication.checkAjaxErrors
                });
            },
            initialize: function () {
                var self = this;

                this.listenTo(quoteChannel, "quote:get:issues", function (quoteId, issues, model) {
                    Authentication.makeAjaxCall({
                        cache: false,
                        type: 'GET',
                        url: CONFIG.rocs_url + "/quote-service/quote/issues/" + quoteId,
                        contentType: 'application/json',
                        success: function (result) {
                            var getSubCaseSize = function (element, name, discCaseSize, discQuantity) {
                                if (element[name]) {
                                    try {
                                        var banned = _.where(element[name].attributes, {restrictedForCustomer: true});
                                        element[name].isDisabled = (banned.length > 0 && !Authentication.canOrderBannedProducts()) || !(element[name].price);
                                        var caseSize = element[name].caseSize;
                                        var quantity = Math.ceil((discCaseSize / caseSize) * discQuantity);
                                        element[name].quantity = quantity;
                                        if (productCodesInQuote[element[name].productCode]) {
                                            element[name].inQuote = productCodesInQuote[element[name].productCode];
                                            element[name].totalWithQuote = quantity + productCodesInQuote[element[name].productCode]
                                        }
                                    } catch (exception) {
                                        console.error(exception);
                                    }
                                }
                            };

                            var toRemoveFromQuote = {
                                cases: 0,
                                weight: 0,
                                price: 0,
                                volume: 0
                            };

                            var productCodesInQuote = [];

                            _.each(result.quote.basket.productLines, function (element, index, list) {
                                productCodesInQuote[element.productCode] = element.quantity;
                            });

                            _.each(result.issues, function (element, idx, list) {
                                element.index = idx + 1;
                                var discQuantity = element.discontinuedProduct.quantity;
                                if (!discQuantity) {
                                    discQuantity = 0;
                                }
                                try {
                                    var discWeight = element.discontinuedProduct.grossWeight;
                                    var discPrice = element.discontinuedProduct.price;
                                    var discVolume = element.discontinuedProduct.volume;

                                    toRemoveFromQuote.cases += discQuantity;
                                    toRemoveFromQuote.weight += discWeight * discQuantity;
                                    toRemoveFromQuote.price += discPrice * discQuantity;
                                    toRemoveFromQuote.volume += discVolume * discQuantity;

                                    var discCaseSize = Number(element.discontinuedProduct.caseSize);
                                } catch (exception) {
                                    console.error(exception);
                                }

                                getSubCaseSize(element, 'substituteProduct', discCaseSize, discQuantity);
                                getSubCaseSize(element, 'alternativeProduct1', discCaseSize, discQuantity);
                                getSubCaseSize(element, 'alternativeProduct2', discCaseSize, discQuantity);
                                getSubCaseSize(element, 'alternativeProduct3', discCaseSize, discQuantity);
                                getSubCaseSize(element, 'alternativeProduct4', discCaseSize, discQuantity);
                            });

                            //Removing from quote so issues modal appears 'lighter'
                            result.quote.basket.basketTotalCases = result.quote.basket.basketTotalCases - toRemoveFromQuote.cases;
                            result.quote.basket.basketTotalGrossWeight = Number((result.quote.basket.basketTotalGrossWeight - toRemoveFromQuote.weight).toFixed(2));
                            result.quote.basket.basketTotalPrice = Number((result.quote.basket.basketTotalPrice - toRemoveFromQuote.price).toFixed(2));
                            result.quote.basket.basketTotalVolume = Number((result.quote.basket.basketTotalVolume - toRemoveFromQuote.volume).toFixed(2));

                            if (result.quote.basket.basketTotalVolume <= 0) {
                                result.quote.basket.basketTotalVolume = +0;
                            }

                            self.model.set('issues', result.issues);
                            self.model.set('minimumOrderQuantity', result.quote.minimumOrderQuantity);
                            self.model.set('operationValue', result.quote.operationValue);
                            self.model.set('effectiveUntil', result.quote.effectiveUntil);
                            self.model.set('basket', result.quote.basket);
                            self.displayModal();
                        },
                        error: Authentication.checkAjaxErrors
                    });

                    var getProjectString = function (issues) {
                        return issues > 1 ? " products" : " product";
                    };
                    var modelObj = {
                        title: issues + getProjectString(issues) + " not available on this quote",
                        confirm: "Confirm changes",
                        // confirm_n: "Submit request for %1 quote%2",
                        // quoteId: quoteId,
                        modalState: "OPEN",
                        basket: model.get('basket'),
                        quoteStatus: "OPEN",
                        quoteId: quoteId,
                        customerReference: model.get('customerReference'),
                        effectiveUntil: "2017-11-01",
                        // rangeType: "AMBIENT",
                        rangeType: model.get('basket').productLines[0].brand.toUpperCase(),
                        minimumOrderQuantity: {
                            brand: "Ambient",
                            minimumQuantity: 200,
                            minimumValue: null
                        },
                    };
                    this.model.set(modelObj);
                    // self.displayModal(modelObj, quoteMatcher, "OPEN");
                });
                this.listenTo(issuesChannel, "model:update", function (cases, weight, price, volume) {
                    var currentCases = this.model.get('basket').basketTotalCases;
                    var currentWeight = this.model.get('basket').basketTotalGrossWeight;
                    var currentPrice = this.model.get('basket').basketTotalPrice;
                    var currentVolume = this.model.get('basket').basketTotalVolume;

                    this.model.get('basket').basketTotalCases = currentCases + cases;
                    this.model.get('basket').basketTotalGrossWeight = currentWeight + weight;
                    this.model.get('basket').basketTotalPrice = currentPrice + price;
                    this.model.get('basket').basketTotalVolume = currentVolume + volume;

                    this.getRegion("basketOverview").show(new Basket({model: this.model}));
                    this.ui.basketTotalCases.text(this.model.get('basket').basketTotalCases);
                    this.ui.basketTotalWeight.text(Math.abs(this.model.get('basket').basketTotalGrossWeight.toFixed(2)));
                    this.ui.basketTotalPrice.text(Math.abs(this.model.get('basket').basketTotalPrice.toFixed(2)));
                    this.ui.basketTotalVolume.text(Math.abs(this.model.get('basket').basketTotalVolume.toFixed(2)));
                });
            },
            onRender: function () {
                this.collection = new Backbone.Collection(this.model.get('issues'));
                var issuesItemCollection = new IssuesItemCollection({collection: this.collection});
                this.getRegion("alternativesRegion").show(issuesItemCollection);
                this.model.set('selected', true);
                this.getRegion("basketOverview").show(new Basket({model: this.model}));
            },

            renderList: function (issues) {
                this.displayModal();
            },
            displayModal: function (modelObj, quoteMatcher, state) {
                // quoteIds = [];
                // this.model.set(modelObj);
                this.render();
                $('#issues-modal').modal({backdrop: 'static', keyboard: false});
            },
            closeModal: function () {
                $('#issues-modal').modal('hide');
            }
        });

        var ControlsView = Marionette.LayoutView.extend({
            regions: {
                customerSearchModal: "#customer-search-modal-region",
                quoteList: "#quote-search-results-region",
                quoteOptions: "#quote-options-modal-region",
                issuesModal: "#issues-modal-region",
                basketRequestModal: "#basket-request-modal-region",
                loadingDataModal: "#loading-data-modal-region"
            },

            template: "templates/controls/controls-layout.hbs",

            onBeforeShow: function () {
                var BasketRequestModel = Backbone.Model.extend({});

                this.getRegion('customerSearchModal').show(new CustomerSearchModal());
                this.getRegion('loadingDataModal').show(new LoadingDataModal());
                this.getRegion('quoteOptions').show(new QuoteOptionModal());
                this.getRegion('issuesModal').show(new IssuesModal({model: new BasketRequestModel()}));
                // This can probably be removed as handled separately now!!!
                this.getRegion('basketRequestModal').show(new BasketRequestModal({model: new BasketRequestModel()}));
            }

        });

        return ControlsView;

    }
);

