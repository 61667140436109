define('metricLayoutView',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer'
],
function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer) {

    var headerChannel = BackboneRadio.channel('headerChannel');

    var MetricList = Backbone.Collection.extend({
    });

    var metricList = new MetricList();

	var MetricItemView = Marionette.ItemView.extend({
        tagName: "tr",
        template: "templates/controls/metric-item.hbs"
    });

    var MetricListView = Marionette.CompositeView.extend({
        initialize: function() {
            this.loadMetrics();
        },
        collection: metricList,
        tagName: 'table',
        className: 'table table-hover',
        template: "templates/controls/metric-list.hbs",
        childView: MetricItemView,
        childViewContainer: "tbody",
        loadMetrics: function(){
            this.collection.url = CONFIG.rocs_url + "/customer-service/admin/metrics";
            Authentication.refreshAndFetch(this.collection, {
                cache : false,
                error: Authentication.checkErrors,
                type: 'POST',
                data: JSON.stringify({customerCode:CurrentCustomer.code,noOfResult:1000,pageNumber:0}),
                contentType: "application/json"
            });
        }
     });

     var MetricLayoutView = Marionette.LayoutView.extend({
        template: "templates/controls/metric-layout.hbs",
        className: 'container-height',
        regions: {
            metricListRegion: "#metric-list-region"
        },

        onBeforeShow: function() {
            var self = this;
            headerChannel.trigger('show:menu', 'showMetrics');
            self.getRegion('metricListRegion').show(new MetricListView());
        }
     });

     return MetricLayoutView;
});
