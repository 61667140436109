define('productsSearchTags',[
    'backbone', 'marionette'
],
function(Backbone, Marionette) {

    var TagView = Marionette.ItemView.extend({
        template: "templates/products/search/sub-view/filter-tag.hbs",
        events: {
            'click' : 'removeMe'
        },
        templateHelpers: function() {
            return {tagClass: (this.model.get('typeOfTag') === 'category') ? 'filter-tag-category' : ''};
        },
        removeMe: function() {
            this.trigger("delete");
        }
    });
    
    var TagModel = Backbone.Model.extend({
    });
    var TagCollection = Backbone.Collection.extend({
        comparator: 'typeOfTag',
        model: TagModel,

        addTag: function(typeOfTag, name, idOfType) {
            var modelData = {
                id: typeOfTag + ':' + idOfType,
                typeOfTag: typeOfTag,
                name: name,
                idOfType: idOfType
            };
            var tagModel = new TagModel(modelData);
            this.add(tagModel);
        },
        removeTag: function(typeOfTag, idOfType) {
            var model = this.get(typeOfTag + ':' + idOfType);
            if (model !== undefined) {
                this.remove(model);
            }
        },

        changeCategory: function(idOfType, cat) {
            if (cat.id === '-1') {
                this.removeTag("category", idOfType);
            } else {
                this.changeTag("category", cat.text, idOfType);
            }
        },
        
        removeTagsOfType: function(typeOfTag) {
            var models = this.where({typeOfTag: typeOfTag});
            this.remove(models);
        },
        
        changeTag: function(typeOfTag, name, idOfType) {
            var model = this.get(typeOfTag + ':' + idOfType);
            if (model !== undefined) {
                model.set('name', name);
            } else {
                this.addTag(typeOfTag, name, idOfType);
            }
        }
    });
    var TagCollectionView = Marionette.CollectionView.extend({
        childView: TagView,
        className: 'clearfix',
        model: TagModel,
        collectionEvents: {
            'update': 'render',
            'change': 'render'
        },
        
        onChildviewDelete: function(view) {
            var details = view.model.toJSON();
            this.collection.remove(view.model);
            this.render();
            this.trigger("tag:removed", details);
        }
    });
    
    return {
        TagCollection: TagCollection,
        TagCollectionView: TagCollectionView
    };

});
