define('quoteHeaderView',['backbone', 'marionette', 'backboneradio'],
function(Backbone, Marionette, BackboneRadio) {

        var quoteChannel = BackboneRadio.channel('quoteChannel');

        var QuoteHeaderModel = Backbone.Model.extend({
        });
	
        var QuoteHeaderView = Marionette.ItemView.extend({
            events: {
                'click #view-edit-basket': 'viewEditBasket',
                'click #quote_reprice-quote': 'repriceQuote',
                'click #quote_reopen-quote': 'reopenQuote'
            },

            modelEvents: {
                "change:totalCases": "modelChanged"
            },

            initialize: function(options) {
                this.selectedQuoteIndex = options.selectedQuoteIndex;
            },

            template: "templates/quotes/quote-header-view.hbs",

            onShow: function(){
                this.updateButtons();
            },

            templateHelpers: function() {
                return {
                    noOfBaskets: this.model.get('quotes').length - this.model.get('orders').length,
                    noOfOrders: this.model.get('orders').length
                };
            },

            repriceQuote: function() {
                quoteChannel.trigger('quote:reprice');
            },

            reopenQuote: function() {
                quoteChannel.trigger('quote:reopen');
            },

            viewEditBasket: function(event) {
                if (! $(event.target).hasClass('disabled')) {
                    this.trigger('showBasket');
                }
            },

            modelChanged: function() {
                this.render();
                this.updateButtons();
            },

            updateButtons: function() {
                var quote = this.model.get('quotes')[this.selectedQuoteIndex];
                var isExpired = (quote.quoteStatus === 'EXPIRED');
                var isReadyForConfirmation = (quote.quoteStatus === 'READY_FOR_CONFIRMATION');

                var totalCases = this.model.get("totalCases");

                this.showHide('#view-edit-basket', !isExpired);
                this.showHide('#quote_reprice-quote', isExpired);
                this.showHide('#quote_reopen-quote', isReadyForConfirmation);

                if(isExpired) {
                    return;
                }

                var noTotalCases = totalCases === 0 || totalCases == undefined;
                $('#view-edit-basket').prop('disabled', noTotalCases);
                if (noTotalCases) {
                    $('#view-edit-basket').addClass('disabled');
                } else {
                    $('#view-edit-basket').removeClass('disabled');
                }
            },

            updateModel: function(model, quoteIndex) {
                this.model.set(model.toJSON());
                this.selectedQuoteIndex = quoteIndex;
                this.modelChanged();
            },

            showHide: function(selector, show) {
                if (show) {
                    $(selector).show();
                } else {
                    $(selector).hide();
                }
            }
        });

        return {
            construct: function(modelData, quoteIndex) {
                return new QuoteHeaderView({model: new QuoteHeaderModel(modelData), selectedQuoteIndex: quoteIndex});
            }
        };

});
