require.config({
    baseUrl: "/app",
    shim : {
        jquery : {
            exports : 'jQuery'
        },
        jQueryUI : {
            deps : ['jquery']
        },
        bootstrap : {
            deps : [ 'jquery']
        }
    },
    paths: {
        'keycloak'                      : 'utils/keycloak',
        'jquery'                        : '../lib/jquery',
        'jQueryUI'                      : '../lib/jquery-ui',
        'json2'			        : '../lib/json2',
        'backbone'                      : '../lib/backbone',
        'backboneradio'                 : '../lib/backbone.radio',
        'marionette'                    : '../lib/backbone.marionette',
        'underscore'                    : '../lib/underscore',
        'bootstrap'		        : '../lib/bootstrap',
        'moment'                        : '../lib/moment.min',
        'fullcalendar'                  : '../lib/fullcalendar.min',
        
        'handlebars'                    : '../lib/handlebars.runtime-v4.0.5',
        'templating'                    : 'utils/templating',
        'templates'                     : '../build/templates',
        'i18n'                          : 'i18n/i18n',
        
        'imageHelper'			: 'utils/image',
		
        'format'                        : 'utils/format',
        'customerListView'		: 'customers/list/list_view',
        'customerSearchView'		: 'customers/search/search_view',
        'productHeaderView'             : 'products/product_header_view',
        'productListView'		: 'products/list/list_view',
        'productSearchHeadingView'      : 'products/list/search_heading_view',
        'scrollBehaviour'               : 'products/list/scroll_behaviour', 
        'productSearchView'		: 'products/search/search_view',
        'productDetailsView'            : 'products/details/details_view',
        'productSearchLayoutView'       : 'products/product_search_layout_view',
        'currentCustomer'		: 'entities/current_customer',
        'product'                       : 'entities/product',
        'quote'                         : 'entities/quote',
        'authentication'                : 'entities/authentication',
        'navbar'                        : 'navbar/navbar_view',
        'quoteLayoutView'               : 'quotes/quote_layout_view',
        'quoteDeliveryDateView'         : 'quotes/quote_delivery_date_view',
        'quoteBuildLayoutView'          : 'quotes/quote_build_layout_view',
        'productsSearchTags'            : 'products/search/search_tags',
        'productSearchFilters'          : 'products/search/search_filters',
        'productSearchFilterCategory'   : 'products/search/search_category',
        'productSearchStaticFilterModel': 'products/search/static_filter_model',
        'controlsView'                  : 'controls/controls_view',
        'quoteListView'			: 'quotes/quote_list_view',
        'quoteDetailsView'		: 'quotes/quote_details_view',
        'quoteHeaderView'		: 'quotes/quote_header_view',
        'quoteSubHeaderView'            : 'quotes/quote_sub_header_view',
        'basketView'                    : 'basket/basket_layout_view',
        'basketModel'                   : 'basket/basket_model',
        'basketContentsView'            : 'basket/basket_contents_view',
        'deleteProductModal'            : 'basket/delete_product_modal',
        'basketOverView'                : 'basket/basket_over_view',
        'quoteOptionModal'              : 'quotes/quote-options-modal-view',
        'staticModels'                  : 'static/static_models',
        'ordersModel'                   : 'orders/orders_model',
        'quoteDetailsModal'             : 'quotes/quote_details_modal',
        'menuView'                      : 'navbar/menu_view',
        'metricLayoutView'              : 'controls/metric_layout_view',
        'notificationView'              : 'quotes/notifications/notification_view',
        'quoteImportModal'              : 'controls/quote_import_modal',
        'quoteRepriceModal'             : 'quotes/quote_reprice_modal',
        'orderHistoryLayoutView'        : 'orders/order_history_layout_view',
        'quoteReopenModal'              : 'quotes/quote_reopen_modal',
        'quoteRequestView'              : 'quotes/quote_submit_view',
        'basket'                        : 'basket/basket',
        'productItemView'               : 'basket/product_item_view',
        'issuesItemView'                : 'basket/issues_item_view',
        'issuesItemCollection'          : 'basket/issues_item_collection',
        'routing'                       : 'utils/routing'
    }
});

require(['rocs-app', 'keycloak'], function(app, keycloak){
    // IE 11 fix!!!!!!!
    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function(obj) {
            var newArr = this.filter(function(el) {
              return el == obj;
            });
            return newArr.length > 0;
          }
      });
    }

    if (typeof Object.assign != 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                var to = Object(target);

                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];

                    if (nextSource != null) { // Skip over if undefined or null
                        for (var nextKey in nextSource) {
                            // Avoid bugs when hasOwnProperty is shadowed
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            writable: true,
            configurable: true
        });
    }

    keycloak.init({
//        onLoad: 'login-required'
    }).success(function (authenticated) {
        app.start();
    }).error(function () {
        alert('failed to initialize');
    });
});


define("main", function(){});

