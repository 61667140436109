define('notificationView',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer', 'format'
],
function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer, format) {
	
    var quoteChannel = BackboneRadio.channel('quoteChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');
          
    var Notification = Backbone.Model.extend({
    });
    
    var Notifications = Backbone.Collection.extend({
        model: Notification
    });
    var NotificationRowView  = Marionette.ItemView.extend({
        template: "templates/quotes/notification/notification-row.hbs",
        className: 'notification-row',
        events: {
            'click .notification-button': 'review'
        },
        review: function() {
            basketChannel.trigger('showBasket', this.model.get("shipmentId"), this.model.get("quoteId"));
        }
    });
    
    var NotificationView  = Marionette.CollectionView.extend({
        childView: NotificationRowView,
        initialize: function() {
            this.listenTo(quoteChannel, 'shipments:loaded', this.shipmentsLoaded);
            var self = this;
            this.listenTo(quoteChannel, 'show:notifications', function() {
                self.$el.parent().slideDown(500);
            });
            this.listenTo(quoteChannel, 'hide:notifications', function() {
                self.$el.parent().slideUp(500);
            });
        },
        
      shipmentsLoaded: function(collection) {
            var notificationModels = [];
            for(var i = 0; i < collection.length; i++) {
                var shipmentModel = collection.at(i);
                var quotes = shipmentModel.get('quotes');
                for(var j = 0; j < quotes.length; j++) {
                    var quote = quotes[j];
                    if (quote.quoteStatus === 'READY_FOR_CONFIRMATION') { 
                        var notificationModel = new Notification({
                            shipmentId: quote.shipmentId,
                            quoteId: quote.quoteId,
                            effectiveUntil: quote.effectiveUntil,
                            deliveryDate: quote.deliveryDate,
                            customerCode: quote.customerCode,
                            customerReference: quote.customerReference
                        });
                        notificationModels.push(notificationModel);
                    }
                }
            }

            quoteChannel.trigger('notification:count', notificationModels.length);
            this.collection.reset(notificationModels);
        }
        
    }); 
    
    return {
        create: function() {
            var notifications = new Notifications();
            var notificationView = new NotificationView({collection: notifications});
            return notificationView;
        }
    };
});

