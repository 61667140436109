define('product',[
    'backbone', 'marionette'
],
function(Backbone, Marionette) {

	var Product = Backbone.Model.extend({
        idAttribute: 'uniqueId',

		parse: function(response) {
			response.uniqueId = response.productCode+'_'+response.price;

			return response;
		}
	});

	return Product;
	
});
