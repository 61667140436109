define('productHeaderView',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'currentCustomer'
],
function(Backbone, Marionette, BackboneRadio, Authentication, CurrentCustomer) {
    var productChannel = BackboneRadio.channel('productChannel');

    
    var ProductHeaderView = Marionette.ItemView.extend({
        filters : {},
        template: "templates/products/product-header-view.hbs",

        events: {
            "click #product-export-search-data": "exportSearchDataClicked"
        },

        initialize: function() {
            this.listenTo(productChannel, "searchcomplete", this.searchComplete);
        },

        searchComplete: function(options) {
            var exportButton = this.$el.find('#product-export-search-data');
            if (options) {
                this.searchString = options.searchString;
                exportButton.prop('disabled',  options.numOfResults === 0);
                this.filters = options.filters;
            }
        },

        exportSearchDataClicked: function(e) {
            var self = this;
            var doSuccess = function(result) {
                var exportURL = CONFIG.rocs_url + "/product-service/product/export/download";
                var form = $('<form method="POST" action="' + exportURL + '">');
                form.append($('<input type="hidden" name="jwt" value="' + result + '">'));
                $('body').append(form);
                form.submit();
            };

            var doError = function(jqXHR, textStatus, errorThrown){
                alert("product export error.");
            };

            Authentication.makeAjaxCall({
                 cache : false,
                 type : 'POST',
                 url : CONFIG.rocs_url + "/product-service/product/export/v2" + "?totalHits=" + $('#product-search-result-count').text(),
                 contentType : 'application/json',
                 data : JSON.stringify(self.filters),
                 success : doSuccess,
                 error : doError
            });
        }
    });

    return ProductHeaderView;
});

