define('productSearchView',[
    'backbone', 'marionette', 'backboneradio'
],
function(Backbone, Marionette, BackboneRadio) {

    var productChannel = BackboneRadio.channel('productChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');

    var basket = {
         items: 'items',
         wish: 'wish',
         previous: 'previous',
         quote: 'quote',
         order: 'order'
     };

    var ProductSearchView = Marionette.ItemView.extend({
            template: "templates/products/search/product-search-quote-builder.hbs", 

            ui: {
                items: "#basket-all-items",
                wish:  "#basket-wish-items",
                previous: "#basket-previous-items",
                quote: "#basket-this-quote",
                order: "#basket-this-order"
            },

            events: {
                    "click @ui.items": "allItems",
                    "click @ui.wish": "wishList",
                    "click @ui.previous": "previousItems",
                    "click @ui.quote": "thisQuote",
                    "click @ui.order": "thisOrderClicked"
            },
            
            initialize: function(options) {
                var self = this;
                self.isEditable = options.productsEditable;

                this.listenTo(basketChannel, "do:select:all:items", function(isQuote, isEditable) {

                    self.isEditable = isEditable;
                    if(isQuote) {
                        this.$(this.ui.quote).css('display', 'inline-block');
                        this.$(this.ui.order).hide();
                    } else {
                        this.$(this.ui.order).css('display', 'inline-block');
                        this.$(this.ui.quote).hide();
                    }

                    var searchPageToBeShown;
                    if (isQuote && self.isEditable) {
                        searchPageToBeShown = basket.items;
                    } else if (isQuote) {
                        searchPageToBeShown = basket.quote;
                    } else {
                        searchPageToBeShown = basket.order;
                    }

                    this._updateBasketHeaders(self.isEditable);
                    this._showBasketMode(searchPageToBeShown, true, self.isEditable);
                });
            },

            _basketMode: basket.items,

            allItems: function() {
                $('#product-input-search').val("");
                this._basketSelected(basket.items);
            },
            wishList: function() {
                $('#product-input-search').val("");
                this._basketSelected(basket.wish);
            },
            previousItems: function() {
                $('#product-input-search').val("");
                this._basketSelected(basket.previous);
            },
            thisQuote: function() {
                $('#product-input-search').val("");
                this._basketSelected(basket.quote);
            },
            thisOrderClicked: function() {
                $('#product-input-search').val("");
                this._basketSelected(basket.order);
            },
            _basketSelected: function(mode) {
                if (this._basketMode !== mode) {
                    this._showBasketMode(mode, true, this.isEditable);
                }
            },
            
            /* Handle changing the basket mode, ie. All Items or priority.
             */
            _showBasketMode: function(newBasket, doSearch, productsEditable) {
                this._basketMode = newBasket;
                this._changeBasket();
                setTimeout(function() {
                    productChannel.trigger("remove:filters");
                    productChannel.trigger("show:items", newBasket, productsEditable);
                    if (doSearch) {
                        productChannel.trigger("product:do:search");
                    }
                }, 1);
            },
            
            _changeBasket: function() {
                this.$('.basket').removeClass('active_basket');
                this.$(this.ui[this._basketMode]).addClass('active_basket');
            },

            _updateBasketHeaders: function(isDocumentEditable) {
                if (isDocumentEditable) {
                    this.$(this.ui.items).removeClass("disabled not_active");
                    this.$(this.ui.wish).removeClass("disabled not_active");
                    this.$(this.ui.previous).removeClass("disabled not_active");
                } else {
                    this.$(this.ui.items).addClass("disabled not_active");
                    this.$(this.ui.wish).addClass("disabled not_active");
                    this.$(this.ui.previous).addClass("disabled not_active");
                }
            }
    });

    return ProductSearchView;

});
