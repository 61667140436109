define('productSearchHeadingView',[
    'backbone', 'marionette', 'backboneradio', 'currentCustomer'  
],
function(Backbone, Marionette, BackboneRadio, CurrentCustomer) {
  
    var productChannel = BackboneRadio.channel('productChannel');
          
    var SearchHeadingView = Marionette.ItemView.extend({ 
        tagName: "table",
        className: "table-heading",

        _templates: {
            items: "templates/products/list/product-list-quote-builder.hbs",
            wish: "templates/products/list/product-list-quote-builder-previous.hbs",
            previous: "templates/products/list/product-list-quote-builder-previous.hbs",
            quote: "templates/products/list/product-list-quote-builder-previous.hbs",  //??
            order: "templates/products/list/product-list-quote-builder-previous.hbs",
            products: "templates/products/list/product-list.hbs"
        },
        events:{
            "mouseenter #ttPrice" : "showPopoverPrice",
            "mouseleave #ttPrice" : "hidePopoverPrice",
            "mouseenter #ttPHeaderShelfLife" : "showPopoverShelfLife",
            "mouseleave #ttPHeaderShelfLife" : "hidePopoverShelfLife",

        },
         showPopoverPrice: function() {
                this.$('#ttPrice').popover('show');
            },
            hidePopoverPrice: function() {
                this.$('#ttPrice').popover('hide');
            },
            showPopoverShelfLife: function() {
                this.$('#ttPHeaderShelfLife').popover('show');
            },
            hidePopoverShelfLife: function() {
                this.$('#ttPHeaderShelfLife').popover('hide');
            },
        templateHelpers: function() {
            return {currencyCode: CurrentCustomer.currency};           
        },
        initialize: function() {
            var mode = 'items';
            if (this.options && this.options.mode) {
                mode = this.options.mode;
            }
            this.template = this._templates[mode];

            var self = this;
            this.listenTo(productChannel, "show:items", function(mode) {
                self.template = self._templates[mode];
                self.render();
            });

        }
    });
    return SearchHeadingView;
});
