define('format',['currentCustomer'],
function(CurrentCustomer) {

    var format = {
        
        /* Displays a number to the specified number of decimal places, rounding.
         * If value is not a number then returns blank.
         */
        fixedDecimal: function(value, dp) {
            if (!$.isNumeric(value)) {
                return "";
            }

            var result = value.toFixed(dp);
            return result;
        },
        
        /* convert "2013-02-20" to "20/02/2013". */
        isoToUkDate: function(isoDate) {
            if ((isoDate === undefined) || (isoDate === null) || (isoDate.length !== 10)) {
                return isoDate;
            }
            return isoDate.substr(8,2) + '/' + isoDate.substr(5,2) + '/' + isoDate.substr(0,4);
        },
        
        currency: function(value) {
            if ((value === null) || (value === undefined)) {
                return "";
            }
            return CurrentCustomer.currencySymbol + value;
        },
        
        fixedCurrency: function(value) {
            if ((value === null) || (value === undefined)) {
                return "";
            }
            return CurrentCustomer.currencySymbol + value.toFixed(2);
        },

        cleanupAttributeTag: function(attributeTag) {
            return attributeTag.replace(/ /g, '_');
        },
    };

    return format;
});

