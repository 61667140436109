define('deleteProductModal',[ 'marionette', 'backboneradio'],
function(Marionette, BackboneRadio) {
    
    var DeleteProductModal = Marionette.ItemView.extend({
        template: "templates/basket/product-delete-modal.hbs",
        className: "modal fade",
        events: {
                'shown.bs.modal': 'focusToDelete',
                'click #basket-product-delete-cancel' : 'cancel',
                'click #basket-product-delete-okay': 'deleteProduct'
        },
        focusToDelete: function() {
            $('#basket-product-delete-okay').focus();
        },
        cancel: function() {
            this.options.view.model.attributes.quantity = this.options.original;
            $("#itemAmount-" + this.options.view.model.get('productCode')).val(this.options.original);
            this.$el.modal('hide');
            
        },
        deleteProduct: function() {
            if (this.options.callback) {
                this.options.callback();
            }
            this.$el.modal('hide');
        }
    });
    
    return DeleteProductModal;
});

