define('productItemView',['backbone', 'marionette', 'backboneradio', 'authentication', 'imageHelper', 'currentCustomer', 'product', 'productDetailsView'],
    function (Backbone, Marionette, BackboneRadio, Authentication, imageHelper, CurrentCustomer, Product, ProductDetailsView) {

        var quoteChannel = BackboneRadio.channel('quoteChannel');
        var alertChannel = BackboneRadio.channel('alertChannel');
        var promptChannel = BackboneRadio.channel('promptChannel');
        var basketChannel = BackboneRadio.channel('basketChannel');

        var quote = {
            items: 'items',
            wish: 'wish',
            previous: 'previous',
            quote: 'quote'
        };

        var ProductItemView = Marionette.LayoutView.extend({
            regions: {
                productDetails: ".product-details"
            },
            tagName: "tbody",
            events: {
                "click button.add-quantity": "productAdd",
                "click .decrease": "productDecrease",
                "click .increase": "productIncrease",
                "click button.details-link": "showDetails",
                "click #restrictedProductLink": "showDetailsBanned",
                "click .product-image": "showDetails",
                "keydown .quantity": "enteredAdd",
                'focusout .quantity': 'productAdd',
                "click .starred-product.glyphicon": "starClicked",
                "click .add_to_quote": "addToQuote",
                "mouseenter #ttFavourite": "showPopover",
                "mouseleave #ttFavourite": "hidePopover",
                "mouseenter #ttPHeaderShelfLife": "showPopoverShelfLife",
                "mouseleave #ttPHeaderShelfLife": "hidePopoverShelfLife",
                "mouseenter .btn-popover": "showPopoverAvailability",
                "mouseleave .btn-popover": "hidePopoverAvailability"

            },
            showPopoverAvailability: function (e) {
                var tooltipType = $(e.currentTarget).data('tooltip-type');
                this.$('.btn-popover[data-tooltip-type="' + tooltipType + '"]').popover('show');
            },
            hidePopoverAvailability: function (e) {
                var tooltipType = $(e.currentTarget).data('tooltip-type');
                this.$('.btn-popover[data-tooltip-type="' + tooltipType + '"]').popover('hide');
            },

            showPopover: function () {
                this.$('#ttFavourite').popover('show');
            },
            hidePopover: function () {
                this.$('#ttFavourite').popover('hide');
            },
            modelEvents: {
                'change': 'updateQuantity',
                'change:starred': 'updateStarred'
            },
            getTemplate: function () {
                var quoteBuilding = this.options && this.options.quoteBuilding;
                if (quoteBuilding) {
                    if (this.options.quoteMode === quote.items) {
                        return "templates/products/list/product-list-item-quote-builder.hbs";
                    } else {
                        return "templates/products/list/product-list-item-quote-builder-previous.hbs";
                    }
                } else {
                    return "templates/products/list/product-list-item.hbs";
                }
            },
            onBeforeRender: function () {
                this.hasEnterBeenPressed = false;
                var priceAfterProfileDiscount = this.model.get('priceAfterProfileDiscount');
                var nonPromotionalPrice = this.model.get('nonPromotionalPrice');
                if (priceAfterProfileDiscount === null) {
                    this.model.set('was', nonPromotionalPrice);
                }
                else {
                    this.model.set('was', priceAfterProfileDiscount);
                }
            },
            onRender: function () {
                this.$el.find('img.product-image').on('error', function (evt) {
                    imageHelper.findAltImage(evt);
                });
            },
            onAttach: function () {
                var quantityColumn = $("#quote-product-search-results-region > table > tbody > tr:nth-child(1) > td.quote_search_col7.quantity-cell.centered-text");
                this.model.originalQuantity = this.model.quantity;
                var range = this.model.get('range');
                var filter = $('#search_filter_range').val();
                if (range !== filter) {
                    quantityColumn.html("<button class='btn btn-default btn-sm btn-popover' data-tooltip-type='availability' data-trigger='focus' title='' data-container='body'data-placement='left' data-toggle='tooltip' data-content='This item range is unavailable for this quote' data-original-title='Info'><span class='glyphicon glyphicon-info-sign'></span></button>");
                }
                var noSecondPrice = this.model.get('noSecondPrice');
                if (noSecondPrice) {
                    var priceColumn = this.$(".products_search_col8");
                    var quantity2Column = this.$(".products_search_col9");

                    priceColumn.html("<button class='btn btn-default btn-sm btn-popover noSecondPrice' data-tooltip-type='availability' data-trigger='focus' title='Info' data-container='body' data-placement='left' data-toggle='tooltip' data-content='Please contact your EAA for availability' data-original-title='Info'> <span class='glyphicon glyphicon-info-sign'></span> </button>");
                    priceColumn.addClass("align-right");

                    quantity2Column.html("");
                    quantity2Column.removeClass("products_search_col9");
                    quantity2Column.addClass("products_search_col9_empty");
                }

                if (this.model.get('onQuoteQuantity')) {
                    this.$el.find('.on_quote_quantity').css("display", "inline-block");
                }
            },
            className: function () {
                var restrictedQuoteProduct = _.find(this.model.get('attributes'), function (attribute) {
                    return attribute.restrictedForCustomer === true;
                });
                var restrictedForCustomer = restrictedQuoteProduct !== undefined;

                if (restrictedForCustomer) {
                    return 'on_banned';
                }
                if (this.model.get('promotional')) {
                    return 'on_promotion';
                }
                return '';
            },
            templateHelpers: function () {
                var data = {};
                data.isProductDisabled = !this.options.productsEditable || this.model.get('productCode').substring(0, 1) === "*";
                data.hasShelfLife = this.model.get('shelfLife') !== 0;
                data.isDiscontinuedAndSubstituted = ((this.model.get('discontinued') === 'Y' || this.model.get('discontinued') === 'true') && this.model.get('substituteProductCode'));
                data.canOrder = !((this.model.get('discontinued') === 'Y' || this.model.get('discontinued') === 'true') || (this.model.get('bannedCode')) || (this.model.get('excludeFromList') === 'Y'));
                data.starClass = this._getStarClass();
                data.priceDisabled = this.options.priceDisabled ? 'disabled' : '';

                if (this.model.get('promotional')) {
                    var price = this.model.get('price');
                    var nonPromotionalPrice = this.model.get('nonPromotionalPrice');
                    var priceAfterProfileDiscount = this.model.get('priceAfterProfileDiscount');


                    if (priceAfterProfileDiscount === null) {
                        this.model.set('was', nonPromotionalPrice);
                        data.percentSaving = ((1.0 - (price / nonPromotionalPrice)) * 100.0).toFixed(1);
                        this.model.set('bothDiscounts', false);
                    }
                    else {
                        this.model.set('was', priceAfterProfileDiscount);
                        data.percentSaving = ((1.0 - (price / priceAfterProfileDiscount)) * 100.0).toFixed(1);
                        this.model.set('bothDiscounts', true);
                    }

                }
                if (this.model.get('restricted')) {
                    var restricted = _.find(this.model.get('attributes'), function (attrib) {
                        return attrib.restrictedForCustomer;
                    }) !== undefined;
                    if (!Authentication.canOrderBannedProducts()) {
                        data.notOrderBannedProduct = restricted;
                    }
                    data.bannedProduct = restricted;
                }
                data.canViewProductDetail = Authentication.canViewProductDetail();
                return data;
            },
            _getValue: function () {
                var value = parseInt(this.$('.quantity').val(), 10);
                return value;
            },
            productDecrease: function () {
                // prevent an update while another is in flight
                if (this.model.locked){
                    return;
                }
                if(this.options && this.options.priceDisabled){
                    return;
                }
                var newQuantity = this._getValue();
                if (isNaN(newQuantity)) {
                    alertChannel.trigger('validation', 'value is invalid');
                }
                var currentValue = this.model.get('quantity');
                if (newQuantity === currentValue) {
                    newQuantity -= 1;
                }
                if (this.options && this.options.issuesView) {
                    this.triggerMethod("changeQuantity", this, newQuantity, currentValue);
                    this.model.set('quantity', newQuantity);
                } else {
                    quoteChannel.trigger("product:set", this, newQuantity, currentValue);
                }
                // this._animateQuantityChange(newQuantity);
            },
            productIncrease: function () {
                // prevent an update while another is in flight
                if (this.model.locked){
                    return;
                }
                if(this.options && this.options.priceDisabled){
                    return;
                }
                var newQuantity = this._getValue();

                if (isNaN(newQuantity)) {
                    alertChannel.trigger('validation', 'value is invalid');
                }
                var currentValue = this.model.get('quantity');
                if (newQuantity === currentValue) {
                    newQuantity += 1;
                }

                if (this.options && this.options.issuesView) {
                    this.triggerMethod("changeQuantity", this, newQuantity, currentValue);
                    this.model.set('quantity', newQuantity);
                } else {
                    quoteChannel.trigger("product:set", this, newQuantity, currentValue);
                }

                //this._animateQuantityChange(newQuantity);
            },
            _animateQuantityChange: function (quantity) {
                this.$('.basket_count').text(quantity);
                var self = this;
                var animateOut = function () {
                    self.$('.basket_count_slider').animate({height: '0px', paddingTop: '0px'}, 300);
                };
                var waitABit = function () {
                    setTimeout(animateOut, 800);
                };
                this.$('.basket_count_slider').animate({height: '54px', paddingTop: '6px'}, 300, waitABit);
            },

            updateQuantity: function () {
                if (this.model.get('onQuoteQuantity')) {
                    this.$el.find('.on_quote_quantity').css("display", "inline-block");
                } else {
                    this.$el.find('.on_quote_quantity').css("display", "none");
                }

                this.$('input.quantity').val(this.model.get('quantity'));
                this.$('.on_quote_quantity').text(this.model.get('onQuoteQuantity'));
            },

            updateStarred: function () {

                this.$('.starred-product').removeClass('glyphicon-star glyphicon-star-empty').addClass(this._getStarClass());
            },

            starClicked: function () {
                var newStarred = !this.model.get('starred');
                this.trigger('product:starred', this.model.get('productCode'), newStarred);
                this.model.set('starred', newStarred);
                var self = this;
                if (newStarred) {
                    Authentication.makeAjaxCall({
                        type: 'POST',
                        url: CONFIG.rocs_url + "/saveditems-service/saveditems/add",
                        data: JSON.stringify({
                            customerCode: CurrentCustomer.code,
                            productCode: this.model.get('productCode'),
                            product: this.model.toJSON()
                        }),
                        contentType: 'application/json',
                        success: function (result) {
                            self.$('.starred-product').removeClass('changing-star');
                            alertChannel.trigger('ok', "Added product to favourites list");
                        },
                        error: Authentication.checkAjaxErrors
                    });
                } else {
                    Authentication.makeAjaxCall({
                        type: 'DELETE',
                        url: CONFIG.rocs_url + "/saveditems-service/saveditems/remove/" + CurrentCustomer.code + "/" + this.model.get('productCode'),
                        contentType: 'application/json',
                        success: function (result) {
                            self.$('.starred-product').removeClass('changing-star');
                            alertChannel.trigger('ok', "Removed product from favourites list");
                        },
                        error: Authentication.checkAjaxErrors
                    });
                }

                this.$('.starred-product').removeClass('glyphicon-star glyphicon-star-empty').addClass(this._getStarClass() + ' changing-star');
            },
            _getStarClass: function () {
                return this.model.get('starred') ? 'glyphicon-star' : 'glyphicon-star-empty';
            },
            enteredAdd: function (event) {
                if (event.which === 13 || event.which === 9) {
                    this.hasEnterBeenPressed = true;
                    if (this._doAdd()) {
                        this.trigger('next:row', event.shiftKey);
                        return false;
                    }
                }
            },

            productAdd: function (e) {
                if(this.options && this.options.priceDisabled){
                    return;
                }
                if (e.type === 'focusout' && !this.hasEnterBeenPressed) {
                    this._doAdd();
                    this.hasEnterBeenPressed = false;
                    return false;
                }
                this.hasEnterBeenPressed = false;
            },

            _doAdd: function () {
                var value = this._getValue();
                var currentValue = this.model.get('quantity');
                if (isNaN(value) || (value < 0) || (value > 100000)) {
                    alertChannel.trigger('validation', 'value is invalid');
                    return false;
                } else {
                    if (value !== currentValue) {
                        if (this.options && this.options.issuesView) {
                            //If this view is inside an issues modal behave differently
                            this.model.set('quantity', value);
                            this.triggerMethod("changeQuantity", this, value, currentValue);
                        }
                        else {
                            quoteChannel.trigger("product:set", this, value, currentValue);
                            if (value > 0) {
                                this._animateQuantityChange(value);
                            }
                        }

                    }
                    return true;
                }
            },

            showQuantityUpdating: function () {
                this.$('.quantity').addClass('updating');
            },
            showQuantityUpdated: function () {
                this.$('.quantity').removeClass('updating');
            },
            selectInput: function () {
                this.$('.quantity').focus().select();
            },
            showDetails: function () {
                if(Authentication.canViewProductDetail()){
                    var doShow = this.$('.product-details:visible').length === 0;
                    var self = this;
                    if (doShow) {
                        this.getRegion("productDetails").show(new ProductDetailsView({model: this.model}));
                        this.$('.product-details').slideDown(300, function () {
                            self.$('.details-link .glyphicon').removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
                        });
                    } else {

                        this.$('.product-details').slideUp(300, function () {
                            self.getRegion("productDetails").reset();
                            self.$('.details-link .glyphicon').removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
                        });
                    }
                }
            },
            showDetailsBanned: function () {
                var doShow = this.$('.product-details:visible').length === 0;
                var self = this;
                if (doShow) {
                    this.getRegion("productDetails").show(new ProductDetailsView({model: this.model}));
                    var view = this;
                    this.$('.product-details').slideDown(300, function () {
                        self.$('.details-link .glyphicon').removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
                        $("#banBtn-" + view.model.get("productCode")).trigger("click");
                    });
                } else {

                    this.$('.product-details').slideUp(300, function () {
                        self.getRegion("productDetails").reset();
                        self.$('.details-link .glyphicon').removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
                    });
                }
            },
            addToQuote: function () {
                var model = new QuotesInBasketsModel();
                model.load(this.model);
                var modal = new AddToQuotesModal({model: model});
                promptChannel.trigger('show:modal', modal);
                return false;
            }
        });

        var QuotesInBasketsModel = Backbone.Model.extend({
            load:  function(searchRowModel) {
                this.sourceModel = searchRowModel;
                var range = searchRowModel.get('range');
                this.set({'range': range,
                    'longDescription' : searchRowModel.get('longDescription')});
                this.url = CONFIG.rocs_url + '/quote-service/quote/product/' + CurrentCustomer.code + '/' + range + '/' + searchRowModel.get('productCode');
                var options = {
                    cache : false,
                };
                Authentication.refreshAndFetch(this, options);
            }
        });
        var AddToQuotesModal = Marionette.ItemView.extend({
            template: "templates/products/list/product-list-add-to-quote.hbs",
            className: "modal fade",
            modelEvents: {
                'sync': 'render'
            },
            events: {
                'keydown .add_to_quote_quantity': 'keyPressed',
                'blur .add_to_quote_quantity': 'quantityBoxBlurred',
                'click .decrease': 'decreaseCount',
                'click .increase': 'increaseCount',
                'hidden.bs.modal': 'modalHidden',
                'click #add_to_new_shipment': 'addToNewShipment',
                'click .add_to_quotes_col1': 'gotoQuote',
                'click #confirmDelete': 'deleteItem',
                'click #cancelDelete': 'cancelDelete'

            },
            deleteItem: function() {

                $(".form-control.input-sm.add_to_quote_quantity.deleting").val(0);
                $(".form-control.input-sm.add_to_quote_quantity.deleting").removeClass("deleting");
                $(".form-control.input-sm.add_to_quote_quantity").removeAttr("disabled");
                $(".ram-button.ram-button-sml.disabled.decreaseTemp").removeClass("disabled");
                $(".ram-button.ram-button-sml.decreaseTemp").addClass("decrease");
                $(".ram-button.ram-button-sml.decreaseTemp.decrease").removeClass("decreaseTemp");

                $(".ram-button.ram-button-sml.disabled.increaseTemp").removeClass("disabled");
                $(".ram-button.ram-button-sml.increaseTemp").addClass("increase");
                $(".ram-button.ram-button-sml.increaseTemp.increase").removeClass("increaseTemp");
                $("#deleteItemLinkContainer").css("visibility", "hidden");
                $("#deleteConfirmText").text("");

                var quote = this.quoteToDeletedDetails.quote;
                var index = this.quoteToDeletedDetails.index;

                this.setOnServer(quote, index, 0);
            },

            cancelDelete: function(){

                this.quoteToDeletedDetails = {};
                $(".form-control.input-sm.add_to_quote_quantity.deleting").val(1);
                $(".form-control.input-sm.add_to_quote_quantity.deleting").removeClass("deleting");
                $(".form-control.input-sm.add_to_quote_quantity").removeAttr("disabled");
                $(".ram-button.ram-button-sml.disabled.decreaseTemp").removeClass("disabled");
                $(".ram-button.ram-button-sml.decreaseTemp").addClass("decrease");
                $(".ram-button.ram-button-sml.decreaseTemp.decrease").removeClass("decreaseTemp");

                $(".ram-button.ram-button-sml.disabled.increaseTemp").removeClass("disabled");
                $(".ram-button.ram-button-sml.increaseTemp").addClass("increase");
                $(".ram-button.ram-button-sml.increaseTemp.increase").removeClass("increaseTemp");
                $("#deleteItemLinkContainer").css("visibility", "hidden");
                $("#deleteConfirmText").text("");
            },
            onBeforeRender: function(){
            },

            templateHelpers: function() {
                if (this.model.get('quotes') !== undefined) {
                    return {loaded: true};
                }
                return {};
            },

            _getIndex: function(event) {
                var row = $(event.target).closest('tr');
                return parseInt(row.attr('data-index'));
            },

            quantityBoxBlurred: function(event) {

                var index = this._getIndex(event);
                var value = parseInt($(event.target).val());
                var row = this.model.get('quotes')[index];
                var currentValue = row.quantity;

                if(currentValue !== value) {
                    if ((value >= 0) && (value < 100000)) {
                        if(value === 0){
                            this.confirmDelete(index);
                        } else {
                            this.setOnServer(row, index, value);
                        }
                        this._animateQuantityChange(value, row.quoteId);
                    } else {
                        alertChannel.trigger('validation', 'Not a valid quantity');
                    }
                }

                return false;
            },

            keyPressed: function(event) {
                if (event.keyCode === 13) {
                    var index = this._getIndex(event);
                    var value = parseInt($(event.target).val());
                    var row = this.model.get('quotes')[index];

                    if ((value >= 0) && (value < 100000)) {
                        if(value === 0){
                            this.confirmDelete(index);
                        } else {
                            this.setOnServer(row, index, value);
                        }

                        this._animateQuantityChange(value, row.quoteId);
                    } else {
                        alertChannel.trigger('validation', 'Not a valid quantity');
                    }

                    return false;
                }
            },
            decreaseCount: function(event) {
                var index = this._getIndex(event);
                var row = this.model.get('quotes')[index];
                if (row.quantity > 0 && row.quantity !== 1) {
                    this._animateQuantityChange(row.quantity - 1, row.quoteId);
                    this.setOnServer(row, index, row.quantity - 1);
                }
                else if(row.quantity === 1){
                    this.confirmDelete(index);
                }
            },

            confirmDelete: function(index) {

                var quote = this.model.get('quotes')[index];
                this.quoteToDeletedDetails = {quote: quote, index: index};
                $("#deleteItemLinkContainer").css("visibility", "visible");
                $("#deleteConfirmText").text("Are you sure you wish to delete this item from quote " + quote.quoteId + "?");
                $(".ram-button.ram-button-sml.increase").addClass("disabled");
                $(".ram-button.ram-button-sml.increase").addClass("increaseTemp");
                $(".ram-button.ram-button-sml.increase").removeClass("increase");
                $(".ram-button.ram-button-sml.decrease").addClass("disabled");
                $(".ram-button.ram-button-sml.decrease").addClass("decreaseTemp");
                $(".ram-button.ram-button-sml.decrease").removeClass("decrease");
                $(".form-control.input-sm.add_to_quote_quantity").attr("disabled", "true");
                $("#quantityField-" + quote.quoteId).addClass("deleting");

            },
            increaseCount: function(event) {
                var index = this._getIndex(event);
                var row = this.model.get('quotes')[index];
                this._animateQuantityChange(row.quantity + 1, row.quoteId);
                this.setOnServer(row, index, row.quantity + 1);
            },
            gotoQuote: function(event){
                var index = this._getIndex(event);
                var row = this.model.get('quotes')[index];
                this.$el.modal('hide');
                basketChannel.trigger('showBasket', row.shipmentId, row.quoteId);
            },
            _animateQuantityChange: function(quantity, id) {
                this.$('#count-' + id).text(quantity);
                var self = this;
                var animateOut = function() {
                    self.$('#slider-' + id).animate({height: '0px', paddingTop: '0px'}, 300);
                };
                var waitABit = function() {
                    setTimeout(animateOut, 800);
                };
                this.$('#slider-' + id).animate({height: '54px', paddingTop: '6px'}, 300, waitABit);
            },

            setOnServer: function(quote, index, quantity) {
                var self = this;
                $(this.$('.add_to_quote_quantity')[index]).addClass('updating');
                return Authentication.makeAjaxCall({
                    cache : false,
                    type : 'POST',
                    url : CONFIG.rocs_url +  "/quote-service/quote/set/" + quote.quoteId + "/" + this.model.get('productCode') +"/"  + quantity,
                    data : {},
                    success: function(value) {
                        self._updateCounts(quote, index, quantity);
                        return true;
                    },
                    error: function(){
                        return false;
                    }
                });
            },
            _updateCounts: function(quote, index, quantity) {
                quote.quantity = quantity;
                var cell = $(this.$('.add_to_quote_quantity')[index]);
                cell.val(quantity);
                cell.removeClass('updating');

                /* Add all the quantities and update the count in the
                 * model of the search results.
                 */
                var allQuantities = 0;
                var quotes = this.model.get('quotes');
                _.each(quotes, function(element) {
                    allQuantities += element.quantity;
                });
                this.model.sourceModel.set('onQuoteQuantity', allQuantities);
            },
            modalHidden: function() {
                if (this._doAddToNewShipment) {
                    quoteChannel.trigger("quote:createquote", this.model.sourceModel);
                }
            },
            addToNewShipment: function() {
                this._doAddToNewShipment = true;
                this.$el.modal('hide');
            }
        });
        return ProductItemView;
    });
