define('customerSearchView',[
    'backbone', 'marionette', 'backboneradio'
],
function(Backbone, Marionette, BackboneRadio) {
    	
    var customerChannel = BackboneRadio.channel('customerChannel');
        
    var CustomerSearchView = Marionette.ItemView.extend({

        template: "templates/customers/search/customer-search.hbs",

        events: {
            "click .btn": "searchForCustomers",
            "keypress #customer-input-search": "checkForEnter",
            "keyup  #customer-input-search": "_doValidate"
        },
        
        _valid: false,

        checkForEnter: function(event) {
            if (event.which === 13) {
                this.searchForCustomers();
                $('#customer-input-search').select();
            }
        },

        _doValidate: function() {
            var text = $("#customer-input-search").val();
            this._valid = text !== "";
            this.$('.btn').prop('disabled', ! this._valid);
        },

        searchForCustomers: function() {
            var text = encodeURIComponent($("#customer-input-search").val());
            customerChannel.trigger("customer:search", text);
        }

    });
	
    return CustomerSearchView;

});
