define('quoteBuildLayoutView',[
    'backbone', 'marionette', 'backboneradio',  'productSearchFilters', 'productSearchView', 'productListView', 'authentication',
    'quoteHeaderView', 'quoteSubHeaderView', 'productSearchHeadingView', 'deleteProductModal', 'scrollBehaviour', 'staticModels',
    'quoteRepriceModal', 'quoteReopenModal'
],
function(Backbone, Marionette, BackboneRadio, productSearchFilters, ProductSearchView, ProductListView, Authentication, 
             quoteHeaderView, QuoteSubHeaderView, ProductSearchHeadingView, DeleteProductModal, ScrollBehaviour, StaticModels,
             QuoteRepriceModal, QuoteReopenModal) {
    	
    var quoteChannel = BackboneRadio.channel('quoteChannel');
    var alertChannel = BackboneRadio.channel('alertChannel');
    var headerChannel = BackboneRadio.channel('headerChannel');
    var orderChannel = BackboneRadio.channel('orderChannel');
    var basketChannel = BackboneRadio.channel('basketChannel');
    var productChannel = BackboneRadio.channel('productChannel');
    var promptChannel = BackboneRadio.channel('promptChannel');
    
	var QuoteBuildLayoutView = Marionette.LayoutView.extend({ 
            quoteId: -1,
            template: "templates/quotes/quote-build-layout-view.hbs",
            behaviors: {
                ScrollBehaviour: {
                    behaviorClass: ScrollBehaviour
                }
            },
            events:{
                "mouseenter #ttMyShipments" : "showPopover",
                "mouseleave #ttMyShipments" : "hidePopover" ,
            },
            showPopoverPrice: function() {
                this.$('#ttPrice').popover('show');
            },
            hidePopoverPrice: function() {
                this.$('#ttPrice').popover('hide');
            },
            showPopoverShelfLife: function() {
                this.$('#ttPHeaderShelfLife').popover('show');
            },
            hidePopoverShelfLife: function() {
                this.$('#ttPHeaderShelfLife').popover('hide');
            },

            regions: {
                productLinesSearchRegion         : "#quote-product-search-region",
                quoteFilterRegion                : "#quote-filter-region",
                productLinesSearchHeadingRegion  : "#quote-product-search-heading-region",
                productLinesSearchResultsRegion  : "#quote-product-search-results-region"
            },
            className: 'container-height',
            modelEvents: {
                'sync': 'modelChange'
            },
            initialize: function(options) {
                this.quoteId = options.quoteId;
                this.quoteIndex = options.quoteIndex;
                this.refreshProducts = false;

                this.listenTo(quoteChannel, 'quote:reprice', this._repriceQuote);
                this.listenTo(quoteChannel, 'quote:reopen', this._reopenQuote);
            },

            onShow: function() {
                headerChannel.trigger("hide:menu");
                var self = this;
                var quote =  this.model.get("quotes")[this.quoteIndex];
                var productsEditable = quote.orderId == null || this.model.get('quoteStatus') === 'OPEN' || this.model.get('quoteStatus') === 'REOPENED';
                this.basketName = quote.rangeType;
                this.getRegion("productLinesSearchRegion").show(new ProductSearchView({isQuote: quote.orderId == null, quoteBuilding: true, productsEditable: productsEditable}));
                var productSearchFilterModel = new productSearchFilters.SearchFilterModel({rangeName: this.basketName, tierOneFilters: FILTERSCONFIG.tierOneFilters, tierTwoFilters: FILTERSCONFIG.tierTwoFilters,
                    canViewPromotionalPricing: Authentication.canViewPromotionalPricing()});
                this.searchFilterView = new productSearchFilters.SearchFilterView({model: productSearchFilterModel});               
                this.getRegion("quoteFilterRegion").show(this.searchFilterView);
                this.searchFilterView.showSearchTitle('items');
                this.productListView = new ProductListView({quoteBuilding: true, quoteId: this.quoteId, productsEditable: productsEditable});
                this.getRegion("productLinesSearchResultsRegion").show(this.productListView);
                this.getRegion("productLinesSearchHeadingRegion").show(new ProductSearchHeadingView({mode: 'items'}));
             
                quoteChannel.reply("get:product:count", function(productCodes) {
                    var i;
                    var results = [];
                    var quoteMap = {};
                    var quotes = self.model.get("quotes")[self.quoteIndex].basket.productLines;
                    for(i in quotes) {
                        var quote = quotes[i];
                        quoteMap[quote.productCode] = quote.quantity;
                    }
                    for(i = 0; i < productCodes.length; i++) {
                        var mappedCount = quoteMap[productCodes[i]];
                        results.push((mappedCount === undefined) ? 0: mappedCount);
                    }
                    return results;
                });

                this.quoteHeaderView = quoteHeaderView.construct(this.model.toJSON(), this.quoteIndex);
                headerChannel.trigger('show', this.quoteHeaderView);
                this.listenTo(this.quoteHeaderView, 'showBasket', function() {
                    basketChannel.trigger('showBasket', self.model.get("shipmentId"), self.quoteId, self.quoteIndex);
                });
                //temp
                var Baskets = Backbone.Collection.extend({});
                var baskets = new Baskets();  
                this.quoteSubHeaderView = new QuoteSubHeaderView({collection:baskets, quoteId: this.quoteId, quoteIndex: this.quoteIndex});
                headerChannel.trigger('show-sub',this.quoteSubHeaderView);
                this.quoteSubHeaderView.updateFromOrder(this.model, this.quoteIndex);
                
                this.listenTo(this.quoteSubHeaderView, "basket:updated", function() {
                    self.refreshProducts = true;
                });

                this.listenTo(this.quoteSubHeaderView, "basket:selected", function(index) {            
                    var quote = this.model.get("quotes")[index];
                    self.isSameQuote = self.quoteId === quote.quoteId;
                    self.orderId = quote.orderId;
                    self.quoteIndex = index;
                    self.quoteId = quote.quoteId;
                    self.basketName = quote.rangeType;
                    self.searchFilterView.model.set('rangeName', this.basketName);
                    self.searchFilterView.model.set('tierOneFilters', FILTERSCONFIG.tierOneFilters);
                    self.searchFilterView.model.set('tierTwoFilters', FILTERSCONFIG.tierTwoFilters);
                    self.searchFilterView.model.set('canViewPromotionalPricing', Authentication.canViewPromotionalPricing());
                    self.searchFilterView.render();
                    self.quoteStatus = quote.quoteStatus;

                    // Is it an order or quote?
                    if(self.orderId) {
                        self.productListView.changeQuote();
                        self.productListView.changeOrder(self.orderId);
                    } else {
                        self.productListView.changeQuote(self.quoteId);
                        self.productListView.changeOrder();
                    }

                    // Allow the UI to refresh itself
                    if(!self.isSameQuote || self.refreshProducts) {
                        this.refreshProducts = false;
                        setTimeout(function() {
                            basketChannel.trigger("do:select:all:items", self.orderId == null, (self.quoteStatus === 'OPEN' || self.quoteStatus === 'REOPENED'));
                            var searchResultsScroll = self.getRegion("productLinesSearchResultsRegion").$el.parent();
                            if (searchResultsScroll.scrollTop() !== 0) {
                                searchResultsScroll.animate({scrollTop: 0}, 400);
                            }
                        }, 1);
                    }

                    this.quoteHeaderView.updateModel(this.model, this.quoteIndex);
                });
                
                this.listenTo(orderChannel, "reload", function() {
                    self.model.reloadSynchronously();
                });

                this.listenTo(orderChannel, "reload:synchronously", function() {
                    self.refreshProducts = true;
                    this.model.reloadSynchronously();
                });

                this.listenTo(orderChannel, "reload:delete", function() {
                    self.model.reload();
                });
                
                this.listenTo(productChannel, "searchcomplete", function() {
                    // Ensure search results are scrolled to top.
                    self.getRegion("productLinesSearchResultsRegion").$el.parent().scrollTop(0);
                });

                this.listenTo(quoteChannel, "product:set", function(view, quantity, original) {
                    if (quantity === 0) {
                        var callback = function() {
                            self._animateProductDelete(view);
                        };
                        var modal = new DeleteProductModal({callback: callback, original: original, view: view});
                        promptChannel.trigger('show:modal', modal);
                    } else {
                        self._updateQuantity(self.quoteId, view, quantity);
                    }
                });
            },
            
            _animateProductDelete: function(view) {
                var self = this;
                var rowModel = view.model;
                var rowCells = view.$('div.cell');
                var completed = false;
                var quoteMode = view.options.quoteMode;
                if (quoteMode !== "items"){
                    rowCells.animate({height: 0, paddingTop: 0, paddingBottom: 0}, 500, function() {
                        if (! completed) { // Called for every column, so ensure delete is only done once.
                            completed = true;
                            self._updateQuantity(self.quoteId, view, 0);
                            rowModel.collection.remove(rowModel);
                        }
                    });
                } else {
                    completed = true;
                    self._updateQuantity(self.quoteId, view, 0);
                }
            },

            modelDeliveryDateChange: function() {
                this.refreshProducts = true;
            },

            _repriceQuote: function() {
                var QuoteModel = Backbone.Model.extend({});
                var quoteModel = new QuoteModel(this.model.get('quotes')[this.quoteIndex]);
                var currentPricingPeriod  = StaticModels.pricingPeriods.first();
                var modal = new QuoteRepriceModal({model: quoteModel, currentPricingPeriod: currentPricingPeriod});
                promptChannel.trigger('show:modal', modal);
            },

            _reopenQuote: function() {
                var QuoteModel = Backbone.Model.extend({});
                var quoteModel = new QuoteModel(this.model.get('quotes')[this.quoteIndex]);
                var modal = new QuoteReopenModal({model: quoteModel});
                promptChannel.trigger('show:modal', modal);
            },
            
            modelChange: function() {
                /* If basket deleted, index may need adjusting */
                var quotes = this.model.get('quotes');
                if (this.quoteIndex >= quotes.length) {
                    this.quoteIndex = quotes.length - 1;
                }
                this.quoteId = quotes[this.quoteIndex].quoteId;
                this.productListView.changeQuote(this.quoteId);
                this.quoteSubHeaderView.updateFromOrder(this.model, this.quoteIndex);
                this.quoteHeaderView.updateModel(this.model, this.quoteIndex);
            },
            _updatePercentBar : function(quote){
                var current  = $(".basket-tab.basket-selected");
                var statusText = $("#status-" + quote.quoteId);
                var priceCases = $("#price_cases-" + quote.quoteId);
                var percent = this._getPercent(quote);
                if(percent < 0){
                    percent = 0;
                }
                statusText.text("Minimum order: " + Math.max(0, Math.floor((percent * 100))) +"% achieved");
                var symbol = priceCases.text().charAt(1); 
                if(!isNaN(symbol)){
                    symbol = priceCases.text().charAt(0);
                 }
                priceCases.text(symbol + quote.basket.basketTotalPrice.toFixed(2) + " | " + quote.basket.basketTotalCases + " cases");
                var offset =  -270 * (1 - percent);
                current.css('background-position', offset +'px 0px');
                current.append("<div class='basket-edit'><span class='glyphicon glyphicon-pencil' aria-hidden='true'></span></div>");
                current.append("<div class='basket-info'><span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span></div>");
            },   
            _getPercent : function(quote) {
                var minimumOrderQuantity = quote.minimumOrderQuantity;
                var basket = quote.basket;
                var percent = 0;
                if (minimumOrderQuantity) {
                    if (minimumOrderQuantity.minimumQuantity) {
                     percent = basket.basketTotalCases / minimumOrderQuantity.minimumQuantity;
                    } else if (minimumOrderQuantity.minimumValue) {
                        percent = basket.basketTotalPrice / minimumOrderQuantity.minimumValue;
                     }
                }
                // Limit to 
                if (percent > 1.0) {
                     percent = 1.0;
                }
                return percent;
            },
            _updateQuantity: function(quoteId, view, quantity) {
                var self = this;
                var productCode = view.model.get("productCode");
                var original = view.model.get('quantity');
                view.showQuantityUpdating();
                var url = CONFIG.rocs_url + "/quote-service/quote/set/" + quoteId + "/" + productCode + "/" + quantity;
                var restType = 'POST';
                if (quantity < 1) {
                    url =  CONFIG.rocs_url + "/quote-service/quote/delete/" + quoteId + "/" + productCode;
                    restType = 'DELETE';
                }
                view.model.locked=true;
                Authentication.makeAjaxCall({
                    cache : false,
                    url: url,
                    type: restType,
                    success: function(data) {
                       view.model.set("quantity", quantity);
                       var price = view.model.get('price');
                       var headerTotalCase = self.quoteHeaderView.model.get('totalCases') + (quantity - original);
                       var headerTotalPrice = self.quoteHeaderView.model.get('totalPrice') + ( (quantity - original) * price);
                       self.quoteHeaderView.model.set({ totalCases : headerTotalCase, totalPrice : headerTotalPrice});
                       view.showQuantityUpdated();
                       var productId = view.model.get('productCode');
                       for(var i = 0; i < self.model.get('quotes').length;i++){
                           if(self.model.get('quotes')[i].quoteId === quoteId && self.model.get('quotes')[i].orderId === undefined){
                               var quote = self.model.get('quotes')[i];
                               quote.basket.basketTotalCases += (quantity - original);
                               quote.basket.basketTotalPrice += (quantity - original) * price;

                               var originalVolume = Math.round(view.model.get('volume') * original * 1e4) / 1e4;
                               var originalGrossWeight = Math.round(view.model.get('grossWeight') * original * 1e4) / 1e4;

                               var newVolume = Math.round(view.model.get('volume') * quantity * 1e4) / 1e4;
                               var newGrossWeight = Math.round(view.model.get('grossWeight') * quantity * 1e4) / 1e4;

                               var newBasketTotalVolume = quote.basket.basketTotalVolume + (newVolume - originalVolume);
                               var newBasketTotalWeight = quote.basket.basketTotalGrossWeight + (newGrossWeight - originalGrossWeight);

                               quote.basket.basketTotalVolume = Math.round(newBasketTotalVolume * 1e4) / 1e4;
                               quote.basket.basketTotalGrossWeight = Math.round(newBasketTotalWeight * 1e4) / 1e4;

                               var itemModel = view.model.attributes;
                               quote.basket.productLines[productId] = itemModel;
                               self._updatePercentBar(quote);
                           }
                       }
                       view.model.locked=false;
                    },
                    error: function(error) {
                        view.model.locked=false;
                        view.showQuantityUpdated();
                        alertChannel.trigger('error', error);
                    }
                });
               
            },
            

    });
    
    return QuoteBuildLayoutView;

});
