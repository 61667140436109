define('issuesItemView',['backbone', 'marionette', 'backboneradio', 'staticModels', 'currentCustomer', 'authentication', 'quoteDetailsModal', 'basketContentsView', 'productItemView', 'basket'],
    function (Backbone, Marionette, BackboneRadio, staticModels, CurrentCustomer, Authentication, QuoteDetailsModal, BasketContentsView, ProductItemView) {
        var issuesChannel = BackboneRadio.channel('issuesChannel');

        var IssuesItemView = Marionette.LayoutView.extend({
            template: "templates/basket/issues-item-view.hbs",
            regions: {
                originalProductRegion: "#original-product-region",
                substituteProductRegion: "#substitute-product-region",
                alternativeProductRegion1: "#alternative-product-region1",
                alternativeProductRegion2: "#alternative-product-region2",
                alternativeProductRegion3: "#alternative-product-region3",
                alternativeProductRegion4: "#alternative-product-region4",
            },
            initialize: function () {
                this.model.set('deleteAfterOpen', false);
            },

            ui: {
                deleteButton: ".delete",
                deleteAfterButton: '.delete-after',
                deleteFromListButton: '#delete-list',
                deleteFromQuoteButton: '#delete-quote',
            },

            events: {
                'click .delete': 'deleteClicked',
                'click .delete-after-reset': 'deleteAfterResetClicked',
                'click #delete-list': 'deleteFromListClicked',
                'click #sub-btn': 'subSelected',
                'click .alt-btn': 'altSelected',
            },

            deleteClicked: function (event) {
                if (!$(event.currentTarget).hasClass('disabled')) {
                    this.model.set('deleteAfterOpen', true);
                    this.model.set('deleteFromQuote', true);
                    this.render();
                }
            },

            deleteAfterResetClicked: function () {
                this.model.set('deleteFromList', false);
                this.model.set('deleteFromQuote', false);
                this.model.set('deleteAfterOpen', false);
                this.render();
            },

            deleteFromListClicked: function () {
                this.model.set('deleteFromList', !this.model.get('deleteFromList'));
                this.render();
            },

            _onProductUpdate: function (name, deselected, quantityDifference) {
                var isSelected = this.model.get(name).selected;

                if (this.model.get(name).inQuote && (this.model.get(name).quantity >= 0)) {
                    this.model.get(name).totalWithQuote = this.model.get(name).inQuote + this.model.get(name).quantity;
                    this.render();
                }

                if (isSelected || deselected) {
                    var cases = quantityDifference;
                    var weight = this.model.get(name).grossWeight * quantityDifference;
                    var price = this.model.get(name).price * quantityDifference;
                    var volume = this.model.get(name).volume * quantityDifference;
                    issuesChannel.trigger('model:update', cases, weight, price, volume);
                }
            },

            subSelected: function () {
                if (this.model.get('substituteProduct').isDisabled) {
                    return;
                }
                var isSelected = this.model.get('substituteProduct').selected;
                isSelected ? this.model.get('substituteProduct').selected = false : this.model.get('substituteProduct').selected = true;

                var quantity = this.model.get('substituteProduct').quantity;
                if (quantity > 0) {
                    if (!isSelected) {
                        this._onProductUpdate('substituteProduct', false, quantity);
                    } else {
                        this._onProductUpdate('substituteProduct', true, -quantity);
                    }
                }
                this.defaultDelete(isSelected);
                this.render();
            },

            altSelected: function (event) {
                var altNum = event.currentTarget.getAttribute('alt');
                if (this.model.get('alternativeProduct' + altNum).isDisabled) {
                    return;
                }
                var isSelected = this.model.get('alternativeProduct' + altNum).selected;
                isSelected ? this.model.get('alternativeProduct' + altNum).selected = false : this.model.get('alternativeProduct' + altNum).selected = true;
                var quantity = this.model.get('alternativeProduct' + altNum).quantity;

                if (quantity > 0) {
                    if (!isSelected) {
                        this._onProductUpdate('alternativeProduct' + altNum, false, quantity);
                    } else {
                        this._onProductUpdate('alternativeProduct' + altNum, true, -quantity);
                    }
                }
                this.defaultDelete(isSelected);
                this.render();
            },

            defaultDelete: function () {
                var isSelected =
                    this.model.get('alternativeProduct1') && this.model.get('alternativeProduct1').selected ||
                    this.model.get('alternativeProduct2') && this.model.get('alternativeProduct2').selected ||
                    this.model.get('alternativeProduct3') && this.model.get('alternativeProduct3').selected ||
                    this.model.get('alternativeProduct4') && this.model.get('alternativeProduct4').selected ||
                    this.model.get('substituteProduct') && this.model.get('substituteProduct').selected;

                if (isSelected) {
                    this.model.set('deleteAfterOpen', false);
                    this.model.set('deleteFromList', false);
                    this.model.set('deleteDisabled', 'disabled');
                } else {
                    this.model.set('deleteDisabled', '');
                }

            },

            templateHelpers: function () {
                var data = {};
                data.issueTitle = "Issue " + this.model.get('index') + ": Product not available";
                data.hasSub = this.model.has('substituteProduct');
                data.hasAlt1 = this.model.has('alternativeProduct1');
                data.hasAlt2 = this.model.has('alternativeProduct2');
                data.hasAlt3 = this.model.has('alternativeProduct3');
                data.hasAlt4 = this.model.has('alternativeProduct4');

                data.hasAlts = data.hasAlt1 || data.hasAlt2 || data.hasAlt3 || data.hasAlt4;

                return data;
            },

            _updateOnQuantityChange: function (name, quantity) {
                var previousQuantity = this.model.get(name).quantity;
                if (quantity >= 0) {
                    this.model.get(name).quantity = quantity;
                } else {
                    this.render();
                }
                if (previousQuantity >= 0 && quantity >= 0) {
                    this._onProductUpdate(name, false, quantity - previousQuantity);
                } else if (previousQuantity >= 0) {
                    this._onProductUpdate(name, false, -previousQuantity);
                } else if (quantity >= 0) {
                    this._onProductUpdate(name, false, quantity);
                }
            },

            onRender: function () {
                var disc = new Backbone.Model(this.model.get('discontinuedProduct'));
                //Explicitly set the flag here so that they cannot order the discontinued product on the issues modal
                disc.set('discontinued', 'N');
                disc.set('substituteProductCode', null);
                var discView = new ProductItemView({
                    model: disc,
                    quoteBuilding: true,
                    quoteMode: 'items',
                    issuesView: true,
                    priceDisabled: true
                });
                this.getRegion('originalProductRegion').show(discView);


                if (this.model.has('substituteProduct')) {
                    var sub = new Backbone.Model(this.model.get('substituteProduct'));

                    var subView = new ProductItemView({
                        model: sub,
                        quoteBuilding: true,
                        quoteMode: 'items',
                        issuesView: true
                    });
                    this.listenTo(subView, 'changeQuantity', function (item, quantity) {
                        var name = 'substituteProduct';
                        this._updateOnQuantityChange(name, quantity);
                    });
                    this.getRegion('substituteProductRegion').show(subView);
                }


                if (this.model.has('alternativeProduct1')) {
                    var alt1 = new Backbone.Model(this.model.get('alternativeProduct1'));

                    var alt1View = new ProductItemView({
                        model: alt1,
                        quoteBuilding: true,
                        quoteMode: 'items',
                        issuesView: true
                    });
                    this.listenTo(alt1View, 'changeQuantity', function (item, quantity) {
                        var name = 'alternativeProduct1';
                        this._updateOnQuantityChange(name, quantity);
                    });
                    this.getRegion('alternativeProductRegion1').show(alt1View);
                }


                if (this.model.has('alternativeProduct2')) {
                    var alt2 = new Backbone.Model(this.model.get('alternativeProduct2'));

                    var alt2View = new ProductItemView({
                        model: alt2,
                        quoteBuilding: true,
                        quoteMode: 'items',
                        issuesView: true
                    });
                    this.listenTo(alt2View, 'changeQuantity', function (item, quantity) {
                        var name = 'alternativeProduct2';
                        this._updateOnQuantityChange(name, quantity);
                    });
                    this.getRegion('alternativeProductRegion2').show(alt2View);
                }


                if (this.model.has('alternativeProduct3')) {
                    var alt3 = new Backbone.Model(this.model.get('alternativeProduct3'));

                    var alt3View = new ProductItemView({
                        model: alt3,
                        quoteBuilding: true,
                        quoteMode: 'items',
                        issuesView: true
                    });
                    this.listenTo(alt3View, 'changeQuantity', function (item, quantity) {
                        var name = 'alternativeProduct3';
                        this._updateOnQuantityChange(name, quantity);
                    });
                    this.getRegion('alternativeProductRegion3').show(alt3View);
                }

                if (this.model.has('alternativeProduct4')) {
                    var alt4 = new Backbone.Model(this.model.get('alternativeProduct4'));

                    var alt4View = new ProductItemView({
                        model: alt4,
                        quoteBuilding: true,
                        quoteMode: 'items',
                        issuesView: true
                    });
                    this.listenTo(alt4View, 'changeQuantity', function (item, quantity) {
                        var name = 'alternativeProduct4';
                        this._updateOnQuantityChange(name, quantity);
                    });
                    this.getRegion('alternativeProductRegion4').show(alt4View);
                }
            }
        });

        return IssuesItemView;
    });
