define('productListView',[
    'backbone', 'marionette', 'backboneradio', 'authentication', 'imageHelper', 'currentCustomer', 'product', 'productDetailsView', 'productItemView', 'moment', 'staticModels'
],
function(Backbone, Marionette, BackboneRadio, Authentication, imageHelper, CurrentCustomer, Product, ProductDetailsView, ProductItemView, Moment, StaticModels) {
	
    var quote = {
        items: 'items',
        wish: 'wish',
        previous: 'previous',
        quote: 'quote'
    };

    var productChannel = BackboneRadio.channel('productChannel');
    var quoteChannel = BackboneRadio.channel('quoteChannel');

    var ProductList = Backbone.Collection.extend({
        model: Product,
        parse: function(data) {
            if (data.products.length > 0) {
                this.totalHits = data.totalHits; 
                
                // Massage the data.
                for(var i = 0; i < data.products.length; i++) {
                    var product = data.products[i];
                    if (product.nutritionTable) {
                        product.nutritionTable = product.nutritionTable.replace("%%txNutrition%%", "");
                        if (product.ingredients) {
                            product.ingredients = product.ingredients.replace(/&/g, '&amp;').replace(/<hl>/g, "<span class='ingredients-highlight '>").replace(/<\/hl>/g, "</span>");
                        }
                    }
                }
            } 
            var filterUpdate = {};
            if ((data.filter !== null) && (data.filter !== undefined) && (data.filter.brandNames !== undefined)) {
                filterUpdate = _.pick(data.filter, 'brandNames', 'ranges', 'rangeToMainGroupMap');
            }
            productChannel.trigger("search:filter:updates", filterUpdate);
            
            return data.products;
        }
	});
	
	var productCollection = new ProductList();

	var EmptyCollectionView = Marionette.ItemView.extend({
		tagName: "tr",
		template: "templates/products/list/product-list-empty.hbs",
        events: {
            'click #remove_filters': 'removeFilters'
        },
        ui: {
            text: ".empty-search-results"
        },
        removeFilters: function() {
            productChannel.trigger("remove:filters");
            return false;
        },
        onDomRefresh: function(){
            var filter = $("#search_filter_range").val();
            if(filter){
                this.ui.text.html("<span >Sorry, no results matching your search with filter " + filter + " were returned. Please try with a different search term or <a id='remove_filters' href='#'>click here</a> to remove filters.</span>");
                this.model.set("filter",filter);
            }
            else{
                this.ui.text.html("<span >Sorry, no results matching your search were returned. Please try with a different search term or <a id='remove_filters' href='#'>click here</a> to remove any filters that have been applied.</span>");
            }
            
            
        }
	});

    /*
     * This screen is used in quote building (three modes) and for just products.
     */
	var ProductListView = Marionette.CollectionView.extend({
            pageNumber: 1,
            noOfResults: 100,
            searchString: "",
            searched: false,
            collectionEvents: {
              'sync': 'synced' /*,
              'reset': 'collectionReset'*/
            },
            collection: productCollection,
            tagName: "table",
            className: 'products-table',
            childView: ProductItemView,
            emptyView: EmptyCollectionView,

            initialize: function(options) {
                var self = this;
                this.collection.reset();
                this.listenTo(productChannel, "product:search", this.startSearch);
                this.listenTo(productChannel, "nextpage", this.nextPage);
                this.listenTo(productChannel, "product:reset", this.productReset);
                this.listenTo(productChannel, "product:show:top", this.showTop);         
                this.listenTo(productChannel,"show:items", function(mode, productsEditable) {
                    self._changeHistoricalView(mode, productsEditable);
                });
                
                this.quoteBuilding = options.quoteBuilding;
                this.quoteMode = quote.items;
                this.childViewOptions = {
                    quoteBuilding: this.quoteBuilding,
                    quoteMode: this.quoteMode,
                    productsEditable: options.productsEditable
                };
                
                if (options.quoteId !== undefined) {
                    this.quoteId = options.quoteId;
                    this.childViewOptions.quoteId = options.quoteId;
                }
            },

            // Scroll to the top.
            showTop: function() {
                this.$el.closest('.scroller').animate({scrollTop: 0}, 400);
            },
            synced: function() {
                // this.$el.closest('.scroller').scrollTop(this.currentScrollTop); // Keep same scroll position as before the load.
                var self = this;
                if (this.collection.length === 0) {
                    // Force a redraw so it can change the template to be rendered.
                    this.render();
                } else {
                    var results = quoteChannel.request("get:product:count", this.collection.pluck('productCode'));
                    if (results && this.orderId === undefined) {
                        _.each(results, function(element, index) {
                            self.collection.at(index).set('quantity', element);
                        });
                    }
                }
            },

            isEmpty: function() {
                return this.searched && (this.collection.length === 0);
            },

            /* Changes for 'all items' / 'previously loaded items' */
            _changeHistoricalView: function(quoteMode, productsEditable) {
                this.quoteMode = quoteMode;
                this.childViewOptions.productsEditable = productsEditable;
                this.childViewOptions.quoteMode = quoteMode;
                this.productReset();
                this.render();
            },

            productReset: function() {
                this.searched = false;
                this.collection.reset();
            },
            
            _setupSearchUrl: function(base, get, search) {
                if (this.searchString === '') {
                    this.collection.url = CONFIG.rocs_url + base + "/" + get + "/" + this.noOfResults + "/" + this.pageNumber + "/" + this.quoteId;
                } else {
                    this.collection.url = CONFIG.rocs_url + base + "/" + search + "/" + this.noOfResults + "/" + this.pageNumber + "/" + this.quoteId + "/" + this.searchString;
                }
            },

            startSearch: function(searchString, filters) {
                this.filters = filters;
                var regexPattern = /[^a-zA-Z0-9À-ÿ \-\&']/g;
                var regex = new RegExp(regexPattern);
                this.searchString = $.trim(searchString).replace(regex, '');
                this.pageNumber = 1;
                this.searched = false;
                this.collection.reset();
                this.loadProducts();
            },
            
            loadProducts: function() {
                var self = this;
                productChannel.trigger("product:search:start");
                this.currentScrollTop = this.$el.closest('.scroller').scrollTop();
                var searchString = this.searchString;

                var requestData = {
                    noOfResults: 50,
                    pageNumber: this.pageNumber,
                    customerCode: CurrentCustomer.code
                };

                var ajaxOptions = {
                    type: 'POST', 
                    contentType: 'application/json',
                    error: function(collection, query) {
                        productChannel.trigger("searchcomplete", {numOfResults: 0, searchString: self.searchString, totalHits: self.collection.totalHits});
                        Authentication.checkErrors(collection, query);
                    },
                    success: function(results) {
                        var options = {numOfResults: results.length, searchString: self.searchString, filters:JSON.parse(self.convertToProductRequest(requestData, 'items')), totalHits: self.collection.totalHits};
                        productChannel.trigger("searchcomplete", options);
                    }
                };

                if (searchString !== undefined) {
                    requestData.searchString = searchString;
                }
                if (this.filters.selectedShelfLifeRange) {
                    requestData.shelfLife = {start: this.filters.selectedShelfLifeRange[0], end: this.filters.selectedShelfLifeRange[1]};
                }

                if (this.filters.productFilters) {
                    requestData.productFilters = this.filters.productFilters;
                }

                if (this.filters.attributeFilters) {
                    requestData.attributeFilters = this.filters.attributeFilters;
                }

                requestData.ranges = this.filters.selectedRange;
                requestData.brands = this.filters.selectedBrands;

                if (this.quoteId !== undefined) {
                    requestData.quoteId = this.quoteId;
                }

                if (this.orderId !== undefined) {
                    requestData.orderId = this.orderId;
                }

                if (this.filters.selectedCategoryId !== '-1') {
                    requestData.mainGroup = {groupDescription: this.filters.selectedCategory, groupId: this.filters.selectedCategoryId};
                    if (this.filters.selectedSubCategoryId !== '-1') {
                        requestData.subGroup = {groupDescription: this.filters.selectedSubCategory, groupId: this.filters.selectedSubCategoryId};
                        if(this.filters.selectedSubSubCategoryId !== '-1'){
                            requestData.subSubGroup = {groupDescription: this.filters.selectedSubSubCategory, groupId: this.filters.selectedSubSubCategoryId};
                        }
                    }
                }
                
                var uris = {
                    items: '/product-service/product/search/v2',
                    wish: '/product-service/product/search/v2',
                    previous: '/product-service/product/search/v2',
                    quote: '/product-service/product/search/v2',
                    order: '/product-service/product/search/v2',
                };
                
                var uri;
                if (this.quoteBuilding) {
                    uri = uris[this.quoteMode];
                } else {
                    uri = uris.items;
                }
                
                this.collection.url = CONFIG.rocs_url + uri;

                ajaxOptions.data = this.convertToProductRequest(requestData, this.quoteMode)

                this.searched = true;
                ajaxOptions.remove = false;
                ajaxOptions.add = true;
                Authentication.refreshAndFetch(this.collection, ajaxOptions);
            },

            generatePromotionAttribute: function(requestData) {
                let promoAttributeFilters = []
                if (requestData.attributeFilters.includes("PROMOTIONS")) {
                    const entries = this.objectEntries(CurrentCustomer.attributes)
                    for (let index = 0; index < entries.length; index++) {
                        const profileType = entries[index][0];
                        const profileValue = entries[index][1];
                        if (profileType.substring('Profile') != -1) {
                            promoAttributeFilters.push("__" 
                            + profileValue
                            + "-" 
                            + this.getSelectedPricingPeriodId(requestData));
                        }
                    }
                }
                return promoAttributeFilters;
            },

            objectEntries:function( obj ) {
                var ownProps = Object.keys( obj ),
                    i = ownProps.length,
                    resArray = new Array(i);
                while (i--)
                  resArray[i] = [ownProps[i], obj[ownProps[i]]];
                return resArray;
            },

            cleanupAttributeFilters: function(requestData) {
                let attributeFilters = requestData.attributeFilters.slice();

                attributeFilters = requestData.attributeFilters.filter(function(value, index, arr){
                    return value !== 'PROMOTIONS';
                });

                for (let index = 0; index < requestData.productFilters.length; index++) {
                    if(requestData.productFilters[index] === 'FAVOURITE') {
                        attributeFilters.push(['__FAVOURITE-' + requestData.customerCode]);
                    }
                }

                for (let index = 0; index < requestData.productFilters.length; index++) {
                    if(requestData.productFilters[index] === 'PREVIOUSLY_ORDERED') {
                        attributeFilters.push(['__ORDERED-' + requestData.customerCode]);
                    }
                }

                return attributeFilters;
            },

            getSelectedDeliveryDate: function(requestData) {
                if (requestData.quoteId && StaticModels.selectedQuote) {
                    return StaticModels.selectedQuote.deliveryDate
                } else {
                    return StaticModels.pricingPeriods.first().attributes.priceDate
                }
            },

            getSelectedPricingPeriodId: function(requestData) {
                if (requestData.quoteId && StaticModels.selectedQuote) {
                    return Moment(new Date()).format('YYYY') + '-' + StaticModels.selectedQuote.pricingPeriodId
                } else {
                    return StaticModels.pricingPeriods.first().id
                }
            },

            // convert RequestData to ProductRequest
            convertToProductRequest: function(requestData, quoteMode) {
                let productRequest = {
                    "customerCode":requestData.customerCode,
                    "deliveryDate":this.getSelectedDeliveryDate(requestData),
                    "shelfLife":requestData.shelfLife,
                    "searchString":requestData.searchString,
                    "rangeTypes":requestData.ranges,
                    "brands":requestData.brands,
                    "quoteId":requestData.quoteId,
                    "orderId":requestData.orderId,
                    "mainGroup":requestData.mainGroup,
                    "subGroup":requestData.subGroup,
                    "subSubGroup":requestData.subSubGroup,
                    "attributeFilters": this.cleanupAttributeFilters(requestData),
                    // "attributeTags": this.cleanupAttributeFilters(requestData),
                    "promoFilters": this.generatePromotionAttribute(requestData),
                    "page":{
                        "pageNumber":requestData.pageNumber,
                        "recordCount":requestData.noOfResults
                    },
                    "includeDiscontinued":false
                };

                productRequest.productFilters = [];

                // "productFilters":["FAVOURITE","PREVIOUSLY_ORDERED","QUOTE","ORDER"],
                if (quoteMode === 'wish') {
                    productRequest.attributeFilters.push(['__FAVOURITE-' + requestData.customerCode]);
                    productRequest.includeDiscontinued = true;
                } else if (quoteMode === 'previous') {
                    productRequest.attributeFilters.push(['__ORDERED-' + requestData.customerCode]);
                } else if (quoteMode === 'quote') {
                    productRequest.productFilters.push(['QUOTE']);
                } else if (quoteMode === 'order') {
                    productRequest.productFilters.push(['ORDER']);
                }

                return JSON.stringify(productRequest);
            },

            nextPage: function() {
                this.pageNumber += 1;
                this.loadProducts();
            },

            onChildviewProductStarred: function(view, productCode, isStarred) {
                var matchedProducts = this.collection.where(function(model) {
                    return model.get('productCode') === productCode && model.get('starred') !== isStarred;
                });

                for (let index = 0; index < matchedProducts.length; index++) {
                    matchedProducts[index].set('starred', isStarred)
                }

            },
            
            onChildviewNextRow: function(view, backwards) {
                var newRowIndex = view._index + (backwards?-1:1);
                if (newRowIndex < 0){
                    newRowIndex = this.children.length - 1;
                }
                if (newRowIndex >= this.children.length){
                    newRowIndex = 0 ;
                }
                var hasInput = [];
                $.each(this.$('.quote_previous_search_col9,.quote_search_col7'), function(index, column){
                    hasInput.push($('input.quantity', column).length > 0);
                });
                if (backwards){
                    newRowIndex = _.lastIndexOf(hasInput, true, newRowIndex);
                } else {
                    newRowIndex = _.indexOf(hasInput, true, newRowIndex);                            
                }
                var model = this.collection.at(newRowIndex);
                var newView =  this.children.findByModel(model);
                newView.selectInput();
            },

            changeOrder: function(orderId) {
                this.orderId = orderId;
            },

            changeQuote: function(quoteId) {
                this.quoteId = quoteId;
            }
	});
	
	return ProductListView;
	
});

