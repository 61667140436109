define('productSearchStaticFilterModel',[
    'backbone', 'marionette', 'authentication', 'currentCustomer'
],
function(Backbone, Marionette, Authentication, CurrentCustomer) {

/*
 * Note: returns a single instance. 
 */
    var StaticFilterModel = Backbone.Model.extend({
       parse: function(data) {
           var i;
           // remove padding, eventually will be done on backend. ie. " Melting Pot"
           for(i = 0; i < data.brandNames.length; i++) {
               data.brandNames[i] = $.trim(data.brandNames[i]);
           }
           data.brandNames.sort(function(a, b) {
               return a.toUpperCase().localeCompare(b.toUpperCase());
           });
           
           data.rangesWithGroups = _.clone(data.ranges);
           
           /* Handle range grouping */
           var groupedRanges = {};
           var justGroupedRangesMap = {};
           _.each(data.ranges, function(elem) {
               var parent = CONFIG.range_groupings[elem];
               if (parent) {
                   if (groupedRanges[parent] === undefined) {
                       groupedRanges[parent] = [];
                       data.rangesWithGroups.push(parent);
                       justGroupedRangesMap[parent] = [];
                   }
                   groupedRanges[parent].push(elem);
                   justGroupedRangesMap[parent].push(elem);
                   
               } else{
                   groupedRanges[elem] = elem;
               }
           });

           var groupedRangesList = [];
           _.each(groupedRanges, function(value, key) {
               groupedRangesList.push({key: key, value: value});
           });

           data.groupedRanges = groupedRangesList;
           data.groupedRangesMap = groupedRanges;
           data.rangesWithGroups.sort();
           data.justGroupedRangesMap = justGroupedRangesMap;

           delete data.topBrands; // Populated in another call

           return data;
       },
       load: function() {
            // Requests filters and top products, returns a promise that completes when both have returned.
            var promise = Authentication.createPromise();
            var self = this;
            this.url = CONFIG.rocs_url + "/product-service/product/filters";
            var topUrl = CONFIG.rocs_url + "/orderline-service/history/product/top/" + CurrentCustomer.territory + "/" + 10;

            var promises = [
                    Authentication.refreshAndFetch(this, {
                        cache : false
                    }),
                    Authentication.makeAjaxCall({
                        cache : false,
                        type : 'GET',
                        url : topUrl,
                        contentType : 'application/json'
                    })];

            Authentication.awaitPromises(promises)
                   .success(function() {
                       self.set({topBrands: promises[1].getResponse()});
                       $('#loading-data-modal').modal('hide');
                       promise.setSuccess(promises);
                   })
                   .error(function() {
                       Authentication.checkErrors();
                       promise.setError(promises);
                   });
            return promise.promise;
        }
    });

    return new StaticFilterModel();
});
